import Hn from "../../../../../components/common/Hn";
import "../../../../admin/forms/face-training/crud/createFaceTraining.css";
import "../../../../admin/forms/location/ListLocationTraining.css";
import Datatable from "../../participant/Datatable";

function Listapprenant() {
  return (
    <div className="container-fluid">
      <div className="row pe-4">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations E-learning"
              level="h1"
              className="pt-5"
              plus={true}
            />
            <Hn title="Apprenants" level="h3" className="pt-5" plus={true}/>
          </div>
          <div className="mt-20">
              <Datatable
                from="elearnings"
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
export default Listapprenant;
