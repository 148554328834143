import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../../../components/common/Hn";
import ListProvidersDataGrid from "./ListProvidersDataGrid";
import "./provider.css";

function Prestataires() {
  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row pe-4">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="Prestataires" level="h2" className="pt-4" />
          </div>

          <div className="mt-5">
              <ListProvidersDataGrid />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Prestataires;
