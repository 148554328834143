import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import PopupFiltreActionformationElear from "../../../../components/popup/PopupFiltreActionformationElear";
import { Button, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Papa from "papaparse";
import AxioServices from "../../../../services/AxioServices";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import { useDatatable } from "../../../../components/hook/useDatatable";
import { Spinner } from "../../../../components/common/Spinner";

export default function ListElearningsDataGrid() {
  let history = useHistory();
  let toastId = null;
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  const [id, setId] = useState();
  const [initraining, setInitraining] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const {selectRow} = useDatatable();
  const [elearnings, setElearnings] = useState([]);
  const uri = process.env.REACT_APP_BASE_URL;

  // Récupérer la liste des formations elearning
  const getElearnings = () => {
    return axios
        .get(uri + "/elearning?adminList=true")
        .then((result) => {
          setElearnings(result?.data);
        })
        .catch((err) => {
          console.log("err elearning", err);
        });
  }

  useEffect(() => {
    getElearnings();
  }, []);

  const handleCloseConfirmationModal = () => {
    setAction(null);
    setShowConfirmationModal(false);
    setId(null);
  }

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/show-elearnig/" + row.id);
    },
  };

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  const handleClosefiltre = () => {
    setShowfiltre(false);
  };

  function searchwithfiltre(val) {
    setInitraining(elearnings);
    setEachEntry(val);

    if (
      val.intitule === "" &&
      val.theme === "" &&
      val.status === "" &&
      val.duree === ""
    ) {


      setElearnings(initraining);
    } else {

      setElearnings([]);
      let elearningsFilter = elearnings.filter(el => {
        let ch = 0; let lf; let i = 0;
        if (val.intitule) {
          i = i + 1
          lf = el?.intitule?.toLowerCase().includes(val?.intitule?.toLowerCase())
          if (lf) ch = ch + 1;
        }
        if (val.theme) {
          i = i + 1
          lf = el?.theme?.id?.includes(val?.theme)
          if (lf) ch = ch + 1;
        }
        if (val.duree) {
          i = i + 1
          if (parseInt(el?.duree) >= parseInt(val.duree)) ch = ch + 1;
          if (lf) ch = ch + 1;
        }
        if (val?.theme) {
          i = i + 1
          lf = el?.theme?.id?.includes(val?.theme)
          if (lf) ch = ch + 1;
        }
        return ch === i;
      })
      setElearnings(elearningsFilter);
    }

  }

  function updateStatus(id, checked) {
    let data = {
      status: checked,
    };

    axios
      .put(`${uri}/elearning/edit/status/` + id, data, headers)
      .catch((error) => {
        console.log(error);
      });
  }

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";

        AxioServices.create("elearning/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");
            setTimeout(() => {
              history.go(0);
            }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };

  const manageAction = () => {
    setShowLoader(true);
    let promise;
    if(action === 'delete'){
      promise = axios.delete(`${uri}/elearning/${id}`);
    }
    if(action === 'clone'){
      promise = axios.post(`${uri}/elearning/duplicate/${id}`, headers);
    }
    promise
      .then((response) => {
        getElearnings().then(() =>{
          displayToast("Csuccess", action === 'delete' ? "Suppression effectuée avec success..." : "Duplication effectuée avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch(() => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      if(action === 'delete'){
        return axios.delete(`${uri}/elearning/${row}`);
      }
      if(action === 'clone'){
        return axios.post(`${uri}/elearning/duplicate/${row}`, headers);
      }
      return null;
    });
    setShowLoader(true);
    Promise.all(promises).then(() => {
      setSelectedRows([]);
      getElearnings().then(() => {
          displayToast("Csuccess", action === 'delete' ? "Suppression(s) effectuée(s) avec success..." : "Duplication(s) effectuée(s) avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
    });
  }

  const columns = [
    {
      dataField: "intitule",
      text: "Action de formation",
      csvText: "Action de formation",
      sort: true,
    },
    {
      dataField: "theme.name",
      text: "Thème",
      csvText: "thème",
      sort: true,
    },
    {
      dataField: "duree",
      text: "Durée",
      csvText: "nombre d'heures",
      sort: true,
    },
    {
      dataField: "actif",
      text: "actif",
      csvText: "actif",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="ps-3">
            <Form.Check
              type="switch"
              id={row.id}
              onChange={(e) => {
                updateStatus(row.id, e.target.checked ? 1: 0);

              }}
              defaultChecked={row.actif}
            />
          </div>
        );
      },
    },
    {
      dataField: "nbApprenants",
      text: "Apprenants en cours",
      sort: true,
      csvText: "Apprenants en cours",
    },
    {
      dataField: "follow",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, learning, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            {localStorage.getItem("role") !== "Formateur" ? (<>
              <Link
                to={`/admin/update-formation-e-learning/${row.id}`}
                className="btn btn-link"
              >
                <img src={require("../../../../assets/icons/edit.png")} />
              </Link>

              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  setAction('clone');
                  setShowConfirmationModal(true);
                }}
              >
                <img src={require("../../../../assets/icons/duplicate.png")} />
              </button>
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  setAction('delete');
                  setShowConfirmationModal(true);
                }}
              >
                <img src={require("../../../../assets/icons/delete.png")} />
              </button>
            </>) : ''}
          </div>

        );
      },
      sort: true,
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  let rows = elearnings.map((item, i) => item);

  const bulkDelete = () => {
    setAction('delete');
    setShowConfirmationModal(true);
  }

  const bulkClone = () => {
    setAction('clone');
    setShowConfirmationModal(true);
  }

  return (
    <ToolkitProvider
      keyField="id"
      data={rows}
      columns={columns}
      exportCSV={{ onlyExportSelection: true, exportAll: false }}
      search
    >
      {(props) => (
        <div>
          <MyExportCSV {...props.csvProps} />
          <div className="lists-filter py-5">
            <div className="row">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={(e => props.searchProps.onSearch(e.target.value))}
                      />
                    </div>
                  </div>
                  <div className=" px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => setShowfiltre(true)}
                        >
                          <img
                            src={require("../../../../assets/icons/filter.png")}
                          />
                        </button>
                      </div>
                      <div className="square">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept={".csv"}
                          className="btn btn-link"
                          onChange={handleOnChange}
                          hidden
                        />
                        <label htmlFor="file" type="button">
                          <img
                            src={require("../../../../assets/icons/Import.png")}
                          />
                        </label>
                      </div>
                      <PopupFiltreActionformationElear
                        showfiltre={showfiltre}
                        closefiltre={handleClosefiltre}
                        searchwithfiltre={searchwithfiltre}
                        filterEntry={eachEntry}
                      />
                      <div className="square">
                        <button
                          onClick={() => props.csvProps.onExport()}
                          type="button"
                          className="btn btn-link"
                          htmlFor="export"
                        >
                          <img
                            src={require("../../../../assets/icons/Export.png")}
                          />
                        </button>
                      </div>
                      <div className="square">
                        <button
                          onClick={bulkClone}
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src={require("../../../../assets/icons/duplicate.png")}
                          />
                        </button>
                      </div>
                      <div className="square">
                        <button
                          onClick={bulkDelete}
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src={require("../../../../assets/icons/delete.png")}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {localStorage.getItem("role") !== "Formateur" ? (
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <a
                          href="/admin/creer-formation-e-learning"
                          className="btn btn-primary btn-gy height43"
                          type="button"
                        >
                          Créer une action de formation
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            classes="table-dark"
            bordered={false}
            hover
            selectRow={selectRow(selectedRows, setSelectedRows)}
            pagination={paginationFactory(options)}
            rowEvents={tableRowEvents}
          />

          {showLoader && <Spinner />}

          <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
            <Modal.Header closeButton>
              <Modal.Title className="modaltitle">
                {action === 'delete' ? (id != null ? 'Etes-vous sûr(e) de vouloir supprimer l\'actions de formation' : 'Etes-vous sûr(e) de vouloir supprimer la selection') :
                  id != null ? 'Etes-vous sûr(e) de vouloir dupliquer l\'actions de formation' : 'Etes-vous sûr(e) de vouloir dupliquer la selection'}
              </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
                Annuler
              </Button>
              <Button
                type="button"
                variant="btn btn-primary"
                onClick={() => {
                    id ? manageAction() : manageBulk()
                }}
              >
                Valider
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </ToolkitProvider>
  );
}
