import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PopupFiltre from "../../../../components/popup/PopupFiltre";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import AxioServices from "../../../../services/AxioServices";
import Papa from "papaparse";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { createBrowserHistory } from "history";
import { Spinner } from "../../../../components/common/Spinner";
import { useDatatable } from "../../../../components/hook/useDatatable";
import { Link } from "react-router-dom";

export default function ListLocationTrainingDataGrid() {
  const [id, setId] = useState();
  const history = createBrowserHistory({ forceRefresh: true });
  const uri = process.env.REACT_APP_BASE_URL;
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const [lieuxFormation, setLieuxFormation] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const {selectRow} = useDatatable();

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const handleCloseConfirmationModal = () => {
    setAction(null);
    setShowConfirmationModal(false);
    setId(null);
  }

  useEffect(() => {
    getLocation();
  }, []);

  const handleOnChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {

        AxioServices.create("location/import", JSON.stringify(results.data))
          .then((result) => {
            console.log(result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  };

  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  };

  //récupérer tous les lieux des formations présentielles
  function getLocation() {
    return axios
      .get(uri + "/location?fields=address")
      .then((result) => {
        setLieuxFormation(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }

  function searchwithfiltre(val) {
    setEachEntry(val);
    if (val.name === ""
      && val.ville === ""
      && val.zip === ""
      && val.minvalue === 0
      && val.maxvalue === 10000
    ) {

      getLocation();
    } else {
      setLieuxFormation([]);

      let locationFilter = lieuxFormation.filter(el => {
          let ch = 0; let lf;let i = 0;
          if(val.name){
            i = i + 1
            lf = el?.name?.toLowerCase().includes(val?.name?.toLowerCase())
            if(lf) ch = ch +1;
          }
          if(val.ville){
            i = i + 1
            lf = el?.town?.toLowerCase().includes(val?.ville?.toLowerCase())
            if(lf) ch = ch +1;
          }
          if(val.zip){
            i = i + 1
            lf = el?.zip?.toLowerCase().includes(val?.zip?.toLowerCase())
            if(lf) ch = ch +1;
          }
          if(val.minvalue){
            i = i + 1
            if(el?.capacity >= val.minvalue) ch = ch +1;
          }
          if(val.maxvalue){
            i = i + 1
            if(el?.capacity <= val.maxvalue) ch = ch +1;
          }

          return ch === i;
      }
      )
      setLieuxFormation(locationFilter)
    }
  }

  const manageAction = () => {
    setShowLoader(true);
    let promise;
    if(action === 'delete'){
      promise = axios.delete(`${uri}/location/${id}`);
    }
    if(action === 'clone'){
      promise = axios.post(`${uri}/location/duplicate/${id}`);
    }
    promise
      .then((response) => {
        getLocation().then(() =>{
          toast.success( action === 'delete' ? "Suppression effectuée avec success..." : "Duplication effectuée avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch(() => {
        toast.error( "Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      if(action === 'delete'){
        return axios.delete(`${uri}/location/${row}`);
      }
      if(action === 'clone'){
        return axios.post(`${uri}/location/duplicate/${row}`, headers);
      }
      return null;
    });
    setShowLoader(true);
    Promise.all(promises).then(() => {
        setSelectedRows([]);
      getLocation().then(() => {
          toast.success(action === 'delete' ? "Suppression(s) effectuée(s) avec success..." : "Duplication(s) effectuée(s) avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      toast.error("Quelque chose s'est mal passé, Réessayer");
    });
  }

  const columns = [
    {
      dataField: "name",
      text: "Nom",
      csvText: "name",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "town",
      text: "Ville",
      csvText: "town",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "zip",
      text: "Code Postal",
      csvText: "zip",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "address",
      text: "Adresse",
      csvText: "address",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "rating",
      text: "Note",
      csvText: "rating",
      sort: true,
    },
    {
      dataField: "capacity",
      text: "Capacité",
      csvText: "capacity",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "ref",
      text: "Ref",
      csvText: "ref",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "company_name",
      text: "company_name",
      csvText: "company_name",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "country",
      text: "country",
      csvText: "country",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "cost",
      text: "cost",
      csvText: "cost",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "interlocutor_name",
      text: "interlocutor_name",
      csvText: "interlocutor_name",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "tel",
      text: "tel",
      csvText: "tel",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "email",
      text: "email",
      csvText: "email",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "comment",
      text: "comment",
      csvText: "comment",
      hidden: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // get the x,y coordinates of the mouse click
          history.push(`/admin/show-location/${row?.id}`);
        },
      },
    },
    {
      dataField: "follow",
      text: "Actions",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Link to={`/admin/update-location-training/${row.id}`} className="btn btn-link">
              <img src={require("../../../../assets/icons/edit.png")} />
            </Link>
            <button
              type="button"
              className="btn"
              onClick={() => {

                setId(row.id);
                setAction('clone');
                setShowConfirmationModal(true);
              }}
            >
              <img src={require("../../../../assets/icons/duplicate.png")} />
            </button>
            {localStorage.getItem("role") === "Super-admin" && (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  setAction('delete');
                  setShowConfirmationModal(true);
                }}
              >
                <img src={require("../../../../assets/icons/delete.png")} />
              </button>
            )}
          </div>
        );
      },
      sort: true,
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });

    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  const bulkDelete = () => {
    setAction('delete');
    setShowConfirmationModal(true);
  }

  const bulkClone = () => {
    setAction('clone');
    setShowConfirmationModal(true);
  }

  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={lieuxFormation}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter py-3">
              <div className="row">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e => props.searchProps.onSearch(e.target.value))}
                        />

                      </div>
                    </div>
                    <div className=" px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltre
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                        />
                        <div className="square">
                          <button className="btn btn-link" onClick={() => props.csvProps.onExport()}>
                            <img
                              src={require("../../../../assets/icons/Export.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label htmlFor="file" type="button">
                            <img src={require("../../../../assets/icons/Import.png")} />
                          </label>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkClone}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../../../assets/icons/duplicate.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkDelete}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../../../assets/icons/delete.png")}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <Link to="/admin/create-location-training" className="btn btn-primary btn-gy height43">
                          Ajouter un lieu de formation
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow(selectedRows, setSelectedRows)}
              pagination={paginationFactory(options)}
            />
          </div>
        )}
      </ToolkitProvider>

      {showLoader && <Spinner />}

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            {action === 'delete' ? (id != null ? 'Etes-vous sûr(e) de vouloir supprimer le lieu de formation' : 'Etes-vous sûr(e) de vouloir supprimer la selection') :
              id != null ? 'Etes-vous sûr(e) de vouloir dupliquer le lieu de formation' : 'Etes-vous sûr(e) de vouloir dupliquer la selection'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={() => {
              id ? manageAction() : manageBulk()
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
