import React, { useEffect, useState, useRef, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/lazy";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import "./popupVideo.css";
import Iframe from "react-iframe";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Spinner } from "../common/Spinner";

export default function Popupvideo({ play, run = null, handleClose = null }) {
  const nextRef = useRef();
  const videoRef = useRef();
  const audioRef = useRef();
  const uri = process.env.REACT_APP_BASE_URL;
  const unite_id = play?.unite?.id;
  const user = localStorage.getItem("email");
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState(1);
  const [total_document, setTotalDocument] = useState(1);
  const [unite, setUnite] = useState(play?.unite);
  const [elearning, setElearning] = useState(play?.elearning);
  const [chapitre, setChapitre] = useState(play?.chapitre);
  const [scormdirectoryname, setScormdirectoryname] = useState();
  const [scorm_url, setScormUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [playerCurrentTime, setPlayerCurrentTime] = useState(null);
  const [label, setLabel] = useState("Commencer");
  const [start, setDateStart] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  const scormTempsRef = useRef(0);
  const scormScore = useRef(0);
  const scormNbQuestions = useRef(0);

  let xtoast = 1;
  let ytoast = 1;
  let history = useHistory();

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  useEffect(() => {
    setLabel(play?.label);
    setUnite(play?.unite);
    setElearning(play?.elearning);
    setChapitre(play?.chapitre);
    setTotalDocument(play?.total_document);
    setPosition(play?.position);
    setScormdirectoryname(play?.unite?.scormdirectoryname);
    setVideoUrl(
      process.env.REACT_APP_PIC_ELEARNING_FILE + play?.unite?.document
    );
    setScormUrl(
      process.env.REACT_APP_PIC_ELEARNING_SCORM +
      play?.unite?.scormdirectoryname +
      "/story.html"
    );

    scormScore.current = 0;
    scormTempsRef.current = 0;
    scormNbQuestions.current = 0;

    if (run && run === "run") {
      setShow(true);
      setDateStart(new Date());
      run = "done";
    }
  }, [play]);


  useEffect(() => {
    const handleMessage = (event) => {
      if(show){
        scormScore.current = event.data?.detail?.score;
        scormNbQuestions.current = event.data?.detail?.nb_questions;

        if (event.data?.detail?.exit === "oui" && typeof unite !== "undefined") {
          scormTempsRef.current = 1;
          nextRef.current.click();
        }
      }
    }

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    }
  }, [show]);

  const onVideoStart = useCallback(
    (timeToStart) => {
      videoRef.current.seekTo(timeToStart, "seconds");
    },
    [videoRef.current]
  );

  const onAudioStart = useCallback(
    (timeToStart) => {
      audioRef.current.seekTo(timeToStart, "seconds");
    },
    [audioRef.current]
  );

  const saveTimer = async () => {
    const now = new Date();
    const trackingDuration = now.getTime() - start.getTime();
    const passedTime = playerCurrentTime ?? Math.round(trackingDuration / 1000);
    const data = new FormData();
    data.append("email", user);
    data.append("unite", unite?.id);
    data.append("duree", passedTime);

    return await axios
      .post(uri + "/player_resume/new", data, headers)
      .catch((err) => {
        console.log("err", err);
      })
  }

  const saveScore = async () => {
    let data = new FormData();
    if (scormdirectoryname !== null) {
      if (scormTempsRef.current === 0) {
        return;
      }
      //validation scorm
      data.append("scorm_score", scormScore.current);
      data.append("scorm_nb_questions", scormNbQuestions.current);
    }

    let verb = "checkin";

    data.append("user_email", user);
    data.append("unite", unite?.id);
    data.append("unite_passed", true);
    data.append("verb", verb);

    return await axios.post(uri + "/watershed/new", data, headers)
  }

  const initWatershed = () => {
    let data = new FormData();
    let verb = "start";

    data.append("verb", verb);
    data.append("user_email", user);
    data.append("unite", unite_id);

    trackPromise(
      axios
        .post(uri + "/watershed/new", data, headers)
        .then((result) => {
          setShow(true);
          setDateStart(new Date());
        })
        .catch((err) => {
          console.log("err data", err);
        })
    );
  }

  const handleModalClose = async () => {
    setShowLoader(true);
    // Display loader
    await Promise.all([
      saveTimer(),
      saveScore(),
    ]).then(() => {
      setShowLoader(false);
      setShow(false);
      if(handleClose) {
        handleClose();
      }
      if(run){
        history.push(`/detailformations/${elearning.id} `)
        }
    })
  };

  const handleShow = () => {
    if (play?.label === "Commencer") {
      initWatershed();
    } else if (play?.label === "Reprendre") {
      setDateStart(new Date());
      setShow(true);
    }
  };

  const handlePrevClicked = async () => {
    setShowLoader(true);
    await Promise.all([
      saveTimer(),
      saveScore(),
    ]).then(() => {
      loadPrev();
      setShowLoader(false);
    })
  };

  const loadPrev = () =>{
    let data = new FormData();

    data.append("user_email", user);
    data.append("unite", unite?.id);

    axios
      .post(uri + "/watershed/get_back", data, headers)
      .then((result) => {
        if (result?.data?.first === true) {
          toast.error("Désolé Oups! Vous ne pouvez aller plus loin.");
        } else {
          setPosition(result?.data?.position);
          setTotalDocument(result?.data?.total_document);
          setUnite(result?.data?.unite);
          setChapitre(result?.data?.chapitre);
          setScormdirectoryname(result?.data?.unite?.scormdirectoryname);
          setVideoUrl(`${process.env.REACT_APP_PIC_ELEARNING_FILE}${result?.data?.unite?.document}`);
          setScormUrl(`${process.env.REACT_APP_PIC_ELEARNING_SCORM}${result?.data?.unite?.scormdirectoryname}/story.html`);
          setPlayerCurrentTime(null);
        }
      })
      .catch((err) => {
        console.log("err data", err);
      })
  }

  const handleNextClicked = async () => {
    setShowLoader(true);
    await Promise.all([
      saveTimer(),
      saveScore(),
    ]).then(() => {
      loadNext();
      setShowLoader(false);
    })
  }

  const loadNext = () => {
    let data = new FormData();
    if (scormdirectoryname !== null) {
      if (scormTempsRef.current === 0) {
        toast.error("Désolé vous devez finir le cours avant!");
        return;
      }
    }

    let verb = "checkin";

    data.append("user_email", user);
    data.append("unite", unite?.id);
    data.append("unite_passed", true);
    data.append("verb", verb);

    axios
      .post(uri + "/watershed/new", data, headers)
      .then((result) => {
        if (result?.data?.elearning_passed === true) {
          // WTF ????
          console.log(result?.data?.score);
          if(xtoast === 1 && result?.data?.score >= 100){
            toast.success("Vous avez terminé cette formation!", {
              id: 'formation',
            });
            xtoast = xtoast + 1
          }
          handleModalClose();
        }

        setPosition(result?.data?.position);
        setTotalDocument(result?.data?.total_document);
        setUnite(result?.data?.unite);
        setChapitre(result?.data?.chapitre);
        setScormdirectoryname(result?.data?.unite?.scormdirectoryname);
        setVideoUrl(
          process.env.REACT_APP_PIC_ELEARNING_FILE +
          result?.data?.unite?.document
        );
        setScormUrl(
          process.env.REACT_APP_PIC_ELEARNING_SCORM +
          result?.data?.unite?.scormdirectoryname +
          "/story.html"
        );
        setPlayerCurrentTime(null);

        if (result?.data?.chapitre_passed === true) {
          // WTF ????
          if(ytoast === 1){
            toast.success("Vous avez terminé ce chapitre!", {
              id: 'chapitre',
            });
            ytoast = ytoast + 1
          }
        }
      })
      .catch((err) => {
        console.log("err data", err);
      })
  }

  return (
    <>
      <div className="col-md-3 text-end">
        <button className="btn btn-primary" onClick={handleShow}>
          {label}
        </button>
      </div>
      <Modal
        show={show}
        onHide={() => handleModalClose()}
        className="videomodal"
        backdrop="static"
        fullscreen={true}
      >
        {showLoader && <Spinner />}
        <Modal.Header closeButton>
          <div id="unite_title">
            <p className="elearning">{elearning?.intitule}</p>{" "}
            <p className="chapitre">
              {chapitre?.titre} | {unite?.titre}
            </p>
          </div>

          <div className="float-end" id="unite_footer">
            <p className="text-white" style={{ lineHeight: "3" }}>
              Document {position}/{total_document}
            </p>
            <div className="square">
              <button
                id="prev"
                type="button"
                className="btn btn-link"
                onClick={handlePrevClicked}
              >
                <img src={require("../../assets/chevronleft.png")} />
              </button>
            </div>
            <div className="square">
              <button
                ref={nextRef}
                id="next"
                type="button"
                className="btn btn-link"
                onClick={handleNextClicked}
              >
                <img src={require("../../assets/chevronright.png")} />
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div id="content_player">
            {unite?.extension == "zip" && (
              <Iframe
                url={scorm_url}
                id="scorm_content"
                display="initial"
                position="relative"
                className="vidIframe"
              />
            )}
            {unite?.extension == "mp4" && (
              <ReactPlayer
                ref={videoRef}
                url={videoUrl}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                onProgress={(progress) => {
                  const timer = progress.playedSeconds !== 0 ? progress.playedSeconds : (parseInt(unite?.resume_time, 10) || 0);
                  setPlayerCurrentTime(timer);
                }}
                onStart={() => {
                  onVideoStart(unite?.resume_time);
                }}
              />
            )}
            {unite?.extension == "pdf" && (
              <object data={videoUrl} type="application/pdf">
                <p>
                  Erreur de chargement, vous pouvez télécharger le PDF{" "}
                  <a href={videoUrl}>ici</a>
                </p>
              </object>
            )}
            {unite?.extension == "mp3" && (
              <ReactPlayer
                ref={audioRef}
                url={videoUrl}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                onProgress={(progress) => {
                  setPlayerCurrentTime(progress.playedSeconds);
                }}
                onStart={() => {
                  onAudioStart(unite?.resume_time);
                }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
