import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { number, object, ref, string } from "yup";
import Hn from "../../../../../components/common/Hn";
import "./createFaceTraining.css";
import Participant from "./participantSession";
import UniteApprentissage from "./UniteApprentissage";
import dateFormat from "dateformat";

function CreateFaceSession() {

  const [showUnit, setShowUnit] = useState(false);
  const [lieuFormation, setLieuForamtion] = useState([]);
  const [formateurs, setForamteurs] = useState([]);
  const [training, setTraining] = useState([]);
  const [newSession, setNewSession] = useState([]);
  const [participe, setParticipe] = useState([]);
  const [changeListFormer, setChangeListFormer] = useState(true);
  const history = useHistory();

  const dte = new Date();
  const uri = process.env.REACT_APP_BASE_URL;
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  let toastId = null;

  const validationSchema = object().shape({
    ref: string().required("Champ obligatoire."),
    training: string().required("Champ obligatoire."),
    dateStart: string().required("Champ obligatoire."),
    dateEnd: string().required("Champ obligatoire."),
    location: string().required("Champ obligatoire."),
    seat_capacity_min: number(),
    seat_capacity_max: number()
      .min(
        ref("seat_capacity_min"),
        "Must be more than Capacité d'acccueil minimale  "
      )
      .required("Champ obligatoire."),
    former: string().required("Champ obligatoire."),
  });


  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return "danger";
    } else if (value) {
      return "info";
    } else {
      return "warning";
    }
  };

  //récupérer le formateur interieur/ou extérieur
  function getformer(type) {
    let url = "/former";
    if (type) {
      url = "/former/organize/" + type;
    }
    axios
      .get(uri + url, headers)
      .then((result) => {
        setForamteurs(result?.data);
      })
      .catch((err) => {
        console.log("err formateurs", err);
      });
  }
//récupérer tous les formations présentielles
  function getTraining() {
    axios
      .get(uri + "/training", headers)
      .then((result) => {
        setTraining(result?.data);
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
  //si on choisit un type bien déterminé ca me donne sa liste de tous les noms et les prénoms des formateurs à choisir
  function changeList(value) {
    setChangeListFormer(false);
    getformer(value);
  }

  useEffect(() => {
    getTraining();
    localStorage.setItem("u", "");
    getformer("");
    axios
      .get(uri + "/location") //récupérer tous les lieux de formations
      .then((result) => {
        setLieuForamtion(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }, []);

  const handleSubmit = (values) => {
    if (!showUnit) {
      setNewSession([]);
      axios
        .post(`${uri}/session/new`, values, headers) //créer une nouvelle session
        .then((response) => {
          setNewSession(response.data);
          localStorage.setItem("idsession", response.data.id);
          setShowUnit(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  function getSessionById(id) {
    let url = `/session/${id}`;
    axios
      .get(uri + url, headers)
      .then((result) => {
        setNewSession(result?.data); //récupérer une session par son id
      })
      .catch((err) => {
        console.log("err session by id", err);
      });
  }
  const saveall = () => {
    displayToast("Csuccess", "Session ajouté avec success, Redirection");
    setTimeout(() => {
      history.push(0);
    }, 3000);
    history.push("/admin/Session/show/" + newSession.id);
  };

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const createLesson = () => {
    let total = newSession?.lessons ? newSession.lessons.length : 0;

    const data = {
      capacityMin: newSession?.seat_capacity_min || '0',
      capacityMax: 20,
      hourStartMorning: '09:30',
      hourEndMorning: '12:00',
      hourStartmidi: '14:00',
      hourEndMidi: '18:00',
      former: newSession?.former?.id,
      name: `Unité d’apprentissage ${total}`,
      organize: newSession?.former?.organize,
      location: newSession?.location?.id,
      date: dateFormat(addDays(newSession.dateStart, total), "yyyy-mm-dd") || '',
      session: newSession.id,
    }

    axios
      .post(`${uri}/lesson/new`, data, headers)
      .then((response) => {
        // on refresh la liste des lessons
        // (on est obligé de refresh les sessions, il n'y a pas de endpoints sur les unités de formation)
        getSessionById(newSession.id)
      })
      .catch((error) => {
        console.log("error");
      });
  }

  return (
    <div className="container-fluid session1 ">
      <div className="row pe-3">
        <div className="container lists pe-4">
          <nav aria-label="breadcrumb pb-4">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/admin/Sessions"}>
                  Formations présentielles - session de formation
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer une session de formation
              </li>
            </ol>
          </nav>
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <ToastContainer />
            <div className="form-elements mt-2">
              <div className="mt-30">
                <Formik
                  initialValues={{
                    ref: "",
                    training: "",
                    dateStart: new Date(dte).toISOString().split("T")[0],
                    dateEnd: "",
                    location: "",
                    seat_capacity_min: "",
                    seat_capacity_max: "",
                    former: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    /* and other goodies */
                  }) => (
                    <form className="row g-3" onSubmit={handleSubmit}>
                      <div className="col-md-12">
                        <label className="form-label">
                          Action de formation
                        </label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          id="training"
                          value={values.training}
                          onChange={handleChange("training")}
                          onBlur={handleBlur("training")}
                          errors={errors.training}
                          required={true}
                        >
                          <option defaultValue>
                            Lier à une action de formation
                          </option>
                          {training.map((train, i) => {
                            return (
                              <option value={train.id} key={i}>{train?.title}</option>
                            );
                          })}
                        </select>
                        <ErrorMessage name="training">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Référence*</label>
                        <input
                          status={onHandelStatus(
                            touched.ref,
                            errors.ref,
                            values.ref
                          )}
                          type="text"
                          className="form-control"
                          id="ref"
                          placeholder="Référence"
                          onChange={handleChange("ref")}
                          value={values.ref}
                          onBlur={handleBlur("ref")}
                          errors={errors.ref}
                        />
                        <ErrorMessage name="ref">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Date de début</label>
                        <input
                          status={onHandelStatus(
                            touched.dateStart,
                            errors.dateStart,
                            values.dateStart
                          )}
                          type="date"
                          className="form-control"
                          id="dateStart"
                          placeholder="Date de début"
                          max={values.dateEnd}
                          onChange={handleChange("dateStart")}
                          value={values.dateStart}
                          onBlur={handleBlur("dateStart")}
                          errors={errors.dateStart}
                        />

                        <ErrorMessage name="dateStart">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Date de fin</label>
                        <input
                          status={onHandelStatus(
                            touched.dateEnd,
                            errors.dateEnd,
                            values.dateEnd
                          )}
                          type="date"
                          className="form-control"
                          id="dateEnd"
                          placeholder="Date de fin"
                          onChange={handleChange("dateEnd")}
                          value={values.dateEnd}
                          min={values.dateStart}
                          onBlur={handleBlur("dateEnd")}
                          errors={errors.dateEnd}
                        />
                        <ErrorMessage name="dateEnd">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="col-md-4">
                        <label className="form-label">Lieu de formation</label>
                        <select
                          status={onHandelStatus(
                            touched.location,
                            errors.location,
                            values.location
                          )}
                          className="form-select"
                          aria-label="Default select example"
                          id="actif"
                          defaultValue={"sélectinner dans la liste"}
                          onChange={handleChange("location")}
                          value={values.location}
                          onBlur={handleBlur("location")}
                          errors={errors.location}
                        >
                          <option>sélectionner dans la liste</option>
                          {lieuFormation.map((lieu, i) => {
                            return (
                              <option value={lieu.id} key={i}>{lieu?.address}</option>
                            );
                          })}
                        </select>
                        <ErrorMessage name="location">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          Capacité d'acccueil minimale
                        </label>
                        <input
                          status={onHandelStatus(
                            touched.seat_capacity_min,
                            errors.seat_capacity_min,
                            values.seat_capacity_min
                          )}
                          type="number"
                          className="form-control"
                          id="seat_capacity_min"
                          // max={values.seat_capacity_max}
                          placeholder="00"
                          onChange={handleChange("seat_capacity_min")}
                          value={values.seat_capacity_min}
                          onBlur={handleBlur("seat_capacity_min")}
                          errors={errors.seat_capacity_min}
                        />
                        <ErrorMessage name="seat_capacity_min">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">
                          Capacité d'acccueil maximale*
                        </label>
                        <input
                          status={onHandelStatus(
                            touched.seat_capacity_max,
                            errors.seat_capacity_max,
                            values.seat_capacity_max
                          )}
                          type="number"
                          className="form-control"
                          id="seat_capacity_max"
                          placeholder="00"
                          //min={values.seat_capacity_min}
                          onChange={handleChange("seat_capacity_max")}
                          value={values.seat_capacity_max}
                          onBlur={handleBlur("seat_capacity_max")}
                          errors={errors.seat_capacity_max}
                        />
                        <ErrorMessage name="seat_capacity_max">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="col-md-4">
                        <label className="form-label">Formateur</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          id="actif"
                          defaultValue={"sélectinner dans la liste"}
                          onChange={(event) => changeList(event.target.value)}
                        >
                          <option value="">sélectionner dans la liste</option>
                          <option value="interne">Interne</option>
                          <option value="externe">Externe</option>
                        </select>
                        <ErrorMessage name="former">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">&nbsp;</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          id="former"
                          value={values.former}
                          onChange={handleChange("former")}
                          onBlur={handleBlur("former")}
                          errors={errors.former}
                          disabled={changeListFormer}
                        >
                          <option defaultValue>
                            sélectionner dans la liste
                          </option>
                          {formateurs.map((former, i) => {
                            return (
                              <option value={former.id} key={i}>
                                {former?.lastName}
                              </option>
                            );
                          })}
                        </select>
                        <ErrorMessage name="former">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="col-12 mt-5">
                        {!showUnit && (
                          <>
                            <a href="/admin/Sessions"
                               className="btn btn-link text-decoration-none resetBtn"
                            >
                              Annuler
                            </a>
                            <button
                              //href="/admin/create-session-formation-apprendre"
                              type="submit"
                              className="btn btn-outline-primary float-end"
                            >
                              Suivant
                            </button>
                          </>
                        )}
                      </div>
                    </form>
                  )}
                </Formik>
                {showUnit && (
                  <div className="pt-5">
                    <div>
                      <div className="d-flex pb-5 pt-5 align-items-center">
                        <Hn
                          title="Unités de formation"
                          level="h4"
                          className="h4-size"
                          plus={false}
                        />
                        <span className="ms-3 look-like-link fw-bold" onClick={createLesson}>
                          Ajouter une unitée
                        </span>
                      </div>
                      <div className="row">
                        {newSession?.lessons?.map((item, index) => (
                            <UniteApprentissage
                              session={newSession}
                              nbUnite={index}
                              lieuFormation={lieuFormation}
                              lesson={item}
                              key={item.id}
                              handleClose={() => getSessionById(newSession.id)}
                            />
                        ))}
                      </div>
                    </div>
                    <Participant
                      setParticipe={setParticipe}
                      participe={participe}
                      session={newSession.id}
                    />
                  </div>
                )}
              </div>
              {showUnit && (
                <div className="mt-5">
                  <Link to={"/admin/create-formation-presentielle"} className="btn btn-link text-decoration-none resetBtn">
                    Annuler
                  </Link>
                  <button type="button"
                    onClick={saveall}
                    className="btn btn-primary float-end"
                  >
                    Suivant
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFaceSession;
