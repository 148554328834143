import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import * as Yup from "yup";
import "./createUnite.css";
import { trackPromise } from 'react-promise-tracker';
import toast, { Toaster } from 'react-hot-toast';
import Delete from "../../../../../assets/icons/delete.png";


function DeleteChapitre({ chapitreToBeDeleted }) {
    const [showModal, setShowModal] = useState(false);
    const uri = process.env.REACT_APP_BASE_URL;

    const deleteChapitre = (chapitre) => {
        trackPromise(
            axios.post(uri + "/chapitre/" + chapitre?.id)
                .then((result) => {
                    hideModal();
                    toast.success("Ce chapitre a bien été supprimé!");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                })
                .catch((err) => {
                    hideModal();
                    toast.error("Désolé vous ne pouvez pas supprimer ce chapitre");
                    console.log("err sessions", err);
                }));
    }

    const showModalFunction = (chapitre) => {
        setShowModal(chapitre);
        console.log('setShowModal', showModal);
    }

    const hideModal = () => {
        setShowModal(false);
    }

    useEffect(() => {

    }, [chapitreToBeDeleted]);


    return (
        <>

            <li>
                <span className="dropdown-item cursor-pointer" onClick={(e) => showModalFunction(chapitreToBeDeleted)}>
                    <img src={Delete} className="pe-1" />
                    Supprimer
                </span>
            </li>
            <Modal show={showModal} onHide={(e) => hideModal()} className="deleteModal">
                <Modal.Header closeButton>
                    <Modal.Title className="modaltitle">
                        Etes-vous sûr(e) de vouloir supprimer "{chapitreToBeDeleted?.titre}" ?
                    </Modal.Title>
                </Modal.Header>

                <Modal.Footer>
                    <Button variant="" onClick={(e) => hideModal()} style={{ color: "#006FFF" }}>
                        Annuler
                    </Button>
                    <Button
                        type="button"
                        variant="btn btn-danger"
                        onClick={(e) => deleteChapitre(chapitreToBeDeleted)}
                    >
                        Supprimer
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default DeleteChapitre;
