import * as Loader from "react-loader-spinner";
import React from "react";

import "./Spinner.css";

export const Spinner = () => {
  return (
    <div className="spinner-overlay">
      <div id="spinner">
        <Loader.TailSpin
          type="ThreeDots"
          color="#2BAD60"
          height="100"
          width="100"
        />
      </div>
    </div>
  )
}
