import { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Hn from "../../components/common/Hn";
import "./DetailFormation.css";
import axios from "axios";
import Placeholder from "../../assets/placeholder.png";
import moment from "moment";
import "moment/locale/fr";
import Popupinscrire from "../../components/popup/Popupinscrire";
import { trackPromise } from 'react-promise-tracker';
import { Interweave } from "interweave";

export default function DetailFormation({ title, disable, disabled, switchs }) {

  const [nbplace, setnbPlace] = useState(0);
  const [session, setSession] = useState([]);
  const [training, setTraining] = useState([]);
  const [options, setOptions] = useState([]);
  const params = useParams();
  const [showInscriptionModal, setShowInscriptionModal] = useState(false);
  const [dataformation, setDataformation] = useState([]);
  const [collaborateur, setCollaborateur] = useState([]);

  const docTraining = process.env.REACT_APP_PIC_FACETRAINING_FILE;

  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  let history = useHistory();
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  moment.locale("fr");
  const uri = process.env.REACT_APP_BASE_URL;

  //récupérer les formations présentielles et afficher leurs détails
  const role = ["Admin", "Responsable", "Super-admin"];
  function getTrainingDetails() {
    axios
      .get(uri + "/training/" + params.id, headers)
      .then((result) => {

        setSession(result?.data.sessions);
        setTraining(result?.data);
        let arrayContact = []
        result?.data?.sessions.forEach(el => {
          arrayContact = arrayContact.concat(el.contactSessions)
        })
        getCollaborateurs(arrayContact.map(el => el.contacts?.ContactID));

        let info = {
          title: result?.data.title,
          cat: result?.data?.type?.name,
        };
        setDataformation(info);
        let totplace = 0
        if (result?.data.sessions?.length > 0) {
          result?.data.sessions?.map((item) => {

            if (parseInt(item?.seat_capacity_max) - parseInt(item?.contactSessions.length) > 0) {

              let rest = parseInt(item?.seat_capacity_max) - parseInt(item?.contactSessions.length);
              totplace = parseInt(totplace) + parseInt(rest);

              setOptions((options) => [
                ...options,
                {
                  value: item.id,
                  label: item?.location?.town + " - " + (new Date(item?.dateStart))?.toLocaleDateString('fr', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                },
              ]);
            }
          });
          setnbPlace(totplace)
        }
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }

  //afficher les détails d'une seule agence par id
  function getCollaborateurs(arrayContact) {

    trackPromise(
      axios({
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/agence/" + localStorage.getItem("AgenceID"),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {

          //  setCollaborateur(response.data);

          if (response?.data?.length > 0) {
            setCollaborateur(response.data?.filter((item) =>
              (!arrayContact.includes(item.ContactID))

            ).map(item => {
              return {
                value: item.ContactID,
                label: item.contactNom + " " + item.contactPrenom,
              }
            }))

          }
        })
        .catch(function (error) {
          console.log("error get collaborateur", error)
        })
    );


  }

  const handleCloseInscriptionModal = () => {
    setShowInscriptionModal(false);
    setOptions([]);
    getTrainingDetails();
  };

  const img = training?.image ? IMG_URL + training.image : Placeholder;

  useEffect(() => {
    getTrainingDetails();
  }, [params.id]);

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="details pe-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link rel="opener" to={"/formation-presentielle"}>
                  Formations présentielle
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {training.title}
              </li>
            </ol>

            <span className="look-like-link resetBtn" onClick={()=>history.goBack()} >Retour</span>
          </nav>
          <div className="head-details">
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title={training.title}
                  level="h1"
                  dispaly
                  className="pt-4"
                  plus={false}
                />
                {training?.associationRoute?.id && (
                  <p className="text text-white">
                    {`Parcours associé : `}
                    <Link to={`/${training.associationRoute.type === 'elearning' ? 'detailformations' : 'detailformation'}/${training.associationRoute.id}`}>
                      {training.associationRoute.title}
                    </Link>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="body-details p-3">
            <div className="row pt-3">
              <div className="col-lg-3 col-md-5 col-xs-12 pt-3">
                <div className="bg-details offset-md-2" style={{ background: `url(${img})` }} />
              </div>
              <div className="col-lg-9 col-md-7 col-xs-6">
                <div className="row pt-3">
                  <div className="col-md-12">
                    <Hn
                      title="Prérequis et public visé"
                      level="h4"
                      plus={false}
                      className="pb-3"
                    />

                    {training?.audience && (
                      <div className="simpletext py-1 text-white">
                        <Interweave content={training.audience} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row pt-4 pe-2">
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Durée</h5>
                        <p className="card-text">{training?.duration} h</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Tarif HT</h5>
                        <p className="card-text">{training?.cost} € <br/> {training.nonAgencyCost? '(Hors agence 2025)' : ""}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Sessions</h5>
                        <p className="card-text">
                          {training?.sessions?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Places restantes</h5>
                        <p className="card-text">{nbplace}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 mb-5 m-1">
            <div className="col">
              <div className="row detailsSession">
                <div className="col-md-9 ">
                  <div className=" box-details">
                    {training?.objective && (
                      <>
                        <Hn
                          title="Objectifs de la formation"
                          level="h5"
                          className=""
                          plus={false}
                        />
                        <div className="simpletext py-1 text-white">
                          <Interweave content={training.objective} />
                        </div>
                      </>
                    )}

                    {(training?.programFile || training?.training_program) && (
                      <>
                        <div
                          className="flex justify-content-between"
                        >
                          <Hn
                            title="Programme"
                            level="h5"
                            className="pt-5"
                            plus={false}
                          />
                          {(training?.programFile) && (
                            <>
                              <a
                                href={docTraining + training.programFile}
                                className="pt-5"
                                target="_blank"
                              >
                                Télécharger le programme
                              </a>
                            </>
                            )
                          }
                        </div>
                        {training?.training_program && (
                          <div className="simpletext py-1 text-white">
                            <Interweave content={training.training_program} />
                          </div>
                        )}
                      </>
                    )}
                    {training?.comment && (
                      <>
                        <Hn
                          title="Commentaire"
                          level="h5"
                          className="pt-5"
                          plus={false}
                        />
                        <div className="simpletext py-1 text-white">
                          <Interweave content={training.comment} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box-details ">
                    <div>
                    {role.includes(localStorage.getItem("role")) && (
                      <button
                        className="btn btn-primary bottomRight"
                        onClick={(e) => setShowInscriptionModal(true)}
                      >
                        Inscription
                      </button>
                    )}
                    </div>
                    <div className="pt-5">
                      <Hn title="Sessions" level="h5" plus={false} />
                      {
                        session.map((sess, key) => {
                          let place = parseInt(sess?.seat_capacity_max) - parseInt(sess?.contactSessions.length);
                          //there is no participants in the data
                          return (
                            <Fragment key={sess.id}>
                              <p className="townSession mt-4">
                                {sess?.location?.town}
                              </p>

                              <Hn
                                title={(new Date(sess?.dateStart))?.toLocaleDateString('fr', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                })}
                                className="text-white textBox"
                                plus={true}
                              />
                              {place <= 0 ? (
                                <p className="text-danger ps-5">Complet</p>
                              ) : (
                                <p className="text-success ps-5">
                                  {place} places disponibles
                                </p>
                              )}
                            </Fragment>
                          );
                        })
                      }
                    </div>

                    <Popupinscrire
                      handleModalShow={showInscriptionModal}
                      handleModalClose={handleCloseInscriptionModal}
                      data={dataformation}
                      options={options}
                      collaborateur={collaborateur}
                      headers={headers}
                      uri={uri}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
