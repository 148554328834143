import "./styles.css";

export default function ProgressBar({bgColor,width,title}) {

   
    

  return (
    <div id="ProgressBar">
  <div id="innerBar"  style={{backgroundColor:bgColor,width:width, title:title}}></div>
  <div id="innerBar"  style={{backgroundColor:bgColor,width:width}}></div>
</div>
  );
}
