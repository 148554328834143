import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import "./popup.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Popupinscrire({
  data,
  handleModalShow,
  handleModalClose,
  options,
  collaborateur,
  headers,
  uri,
}) {

  let toastId = null;
  const [selectedOption, setSelectedOption] = useState(null);
  const [recap,setRecap] = useState(false);
  const [selectedCollaborateur, setSelectedCollaborateur] = useState(null);
  const [inscriptionCollaborateur, setInscriptionCollaborateur] = useState([]);
  const [inscriptionSession, setInscriptionSession] = useState([]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: "16px",
      fontWeight: state.isSelected ? "600" : "400",
      backgroundColor: "#3A4048",
      color: "white",
      border: "none",
      borderRadius: "none",
    }),
    dropdownIndicator: (base) => ({
      color: "#006FFF",
      marginRight: "10px",
    }),
    menu: (base) => ({
      backgroundColor: "#212730",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#1E2E45" : "#212730",
      fontWeight: state.isSelected ? "600" : "400",
      borderBottom: "1px solid #3A4048",
      color: "white",
      margin: "5px 14px 5px 10px",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "white",
      backgroundColor: "#1E2E45",
      fontWeight: state.isSelected ? "600" : "400",
      margin: "6px 10px 5px 10px",
      padding: "5px 14px 5px 10px",
    }),
    multiValue: (base, state) => ({
      ...base,
      color: "white",
      backgroundColor: "#1E2E45",
      fontWeight: state.isSelected ? "600" : "400",
      margin: "6px 10px 5px 10px",
      padding: "5px 14px 5px 10px",
    }),
    multiValueLabel: (base) => ({
      color: "white",
    }),
    multiValueRemove: (base) => ({
      color: "#006FFF",
      fontSize: "16px",
      height: "20px",
      width: "20px",
    }),
  };

  const handleSubmit = () => {
    const opsessions = [];
    const opCollaborateur = [];
    opsessions.push(selectedOption.value);
    selectedCollaborateur.map((opc) => {
      opCollaborateur.push(opc.value);
    });

    let datasend = [{
      participant: opCollaborateur,
      session: opsessions
    }]

    axios
    .post(`${uri}/session/inscription`, datasend, headers)
    .then((result) => {
      setRecap(true)
      setInscriptionCollaborateur(collaborateur.filter((el) => result.data[0].participant.includes(el.value)))
      setInscriptionSession(options.filter((el) => result.data[0].session.includes(el.value)))
    })
    .catch((err) => {
      displayToast("Cerror", "Une erreur se produite");
    });
  };

  const handleClose = () => {
    resetModal()
    handleModalClose();
  }

  const resetModal = () => {
    setSelectedOption(null)
    setRecap(false)
    setSelectedCollaborateur(null)
    setInscriptionCollaborateur([])
    setInscriptionSession([])
  }

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  return (
    <Modal
      show={handleModalShow}
      onHide={handleClose}
      size="lg"
      className="special-width-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title className="px-3 nopad">
          {!recap ?
            <>
              <Hn title={data.title} level="h3" plus={false}/> {data.cat}
            </> :
            <Hn title="Félicitations!" level="h3" plus={false}/>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ToastContainer />
        <div className="row px-3 nopad">
          {!recap ?
           <>
            <div className="col-md">
              <Hn title="Qui ?" level="h5" plus={false} className="label-popup" />
              <Select
                styles={customStyles}
                onChange={setSelectedCollaborateur}
                className="themeSelect"
                options={collaborateur}
                isMulti={true}
                placeholder="Sélectionner un ou des apprenant(s)"
                noOptionsMessage={() => 'Aucun résultat'}
              />
            </div>
            <div className="col-md">
              <Hn
                title="Quand ?"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <Select
                styles={customStyles}
               // onChange={handleChange}
                onChange={setSelectedOption}
                className="themeSelect"
                options={options}
                placeholder="Sélectionner une date"
                noOptionsMessage={() => 'Aucun résultat'}
              />
            </div>
          </>
            :
          <>
            <div className="col-md-7">
              <Hn
                title="Nous vous confirmons l'inscription de :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              {inscriptionCollaborateur.map((inscrip, i)=>(
                <p className="text-white textBox" key={i}> {inscrip.label}</p>
              ))}
            </div>
            <div className="col-md-5">
              <Hn
                title="Formation :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <p className="text-white textBox"> {data.title}</p>
            </div>
            <div className="col-md-12">
              <Hn
                title="Session(s) :"
                level="h5"
                plus={false}
                className="label-popup"
              />
              {inscriptionSession.map((sess, i)=>(
                <p className="text-white textBox" key={i}> {sess.label}</p>
              ))}
            </div>
          </>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!recap ?
          <Button
            variant="primary"
            onClick={handleSubmit}
          >
            Valider
          </Button>
        :
         <Button
           variant="primary"
           onClick={handleClose}
         >
           Fermer
         </Button>
        }
      </Modal.Footer>
    </Modal>
  );
}
