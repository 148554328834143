import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import Papa from "papaparse";
import AxioServices from "../../../../services/AxioServices";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopupFiltreActionformation from "../../../../components/popup/PopupFiltreActionformation";
import { Spinner } from "../../../../components/common/Spinner";
import { useDatatable } from "../../../../components/hook/useDatatable";

export default function Datatable() {
  const [id, setId] = useState();
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [training, setTraining] = useState([]);
  const [minCost, setMinCost] = useState(0);
  const [maxCost, setMaxCost] = useState(10000);
  const [minDuration, setMinDuration] = useState(0);
  const [maxDuration, setMaxDuration] = useState(10000);

  const {selectRow} = useDatatable();

  let history = createBrowserHistory({ forceRefresh: true });

  let toastId = null;
  const uri = process.env.REACT_APP_BASE_URL;
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  //récupérer toutes les formations présentielles
  function getTraining() {
    return axios
      .get(uri + "/training")
      .then((result) => {
        setTraining(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }

  useEffect(() => {
    getTraining();
  }, []);

  useEffect(() => {
    setMinCost(training.length === 0 ? 0 : Math.min(...training?.map((item) => item?.cost)));
    setMaxCost(training.length === 0 ? 10000 : Math.max(...training?.map((item) => item?.cost)));
    setMinDuration(training.length === 0 ? 0 : Math.min(...training?.map((item) => item?.duration)));
    setMaxDuration(training.length === 0 ? 10000 : Math.max(...training?.map((item) => item?.duration)));
  }, [training]);

  const handleCloseConfirmationModal = () => {
    setAction(null);
    setShowConfirmationModal(false);
    setId(null);
  }

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  };

  function searchwithfiltre(val) {
    setEachEntry(val);
    if (val) {
      if (val.minvalue.trim  === "Infinity") {
        val.minvalue = minDuration;
      }
      if (val.maxvalue.trim  === "-Infinity") {
        val.maxvalue = maxDuration;
      }
      if (val.minCout.trim  === "Infinity") {
        val.minCout = minCost;
      }
      if (val.maxCout.trim  === "-Infinity") {
        val.maxCout = maxCost;
      }
    }

    const searchParams = new URLSearchParams();
    if(val.name !== ""){
      searchParams.append("title", val.name);
    }
    if(val.status){
      searchParams.append("status", val.status);
    }
    if(!isNaN(val.minCout)){
      searchParams.append("costmin", val.minCout);
    }
    if(!isNaN(val.maxCout)){
      searchParams.append("costmax", val.maxCout);
    }
    if(!isNaN(val.maxvalue)){
      searchParams.append("durationmax", val.maxvalue);
    }
    if(!isNaN(val.minvalue)){
      searchParams.append("durationmin", val.minvalue);
    }

    let req = uri + "/training/filter?" + searchParams.toString();
    axios
      .get(req)
      .then((result) => {
          setTraining(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });

  }

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/showActionFormationPres/" + row.id);
    },
  };

  function updateStatus(id, checked) {
    let data = {
      status: checked,
    };

    axios
      .put(`${uri}/training/edit/status/` + id, data, headers)
      .then((response) => {

      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";

        AxioServices.create("training/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");

            // setTimeout(() => {
            //   history.go(0);
            // }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };

  const manageAction = () => {
    setShowLoader(true);
    let promise;
    if(action === 'delete'){
      promise = axios.delete(`${uri}/training/${id}`);
    }
    if(action === 'clone'){
      promise = axios.post(`${uri}/training/duplicate/${id}`, headers);
    }
    promise
      .then((response) => {
        getTraining().then(() =>{
          displayToast("Csuccess", action === 'delete' ? "Suppression effectuée avec success..." : "Duplication effectuée avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch(() => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      if(action === 'delete'){
        return axios.delete(`${uri}/training/${row}`);
      }
      if(action === 'clone'){
        return axios.post(`${uri}/training/duplicate/${row}`, headers);
      }
      return null;
    });
    setShowLoader(true);
    Promise.all(promises).then(() => {
        setSelectedRows([]);
      getTraining().then(() => {
          displayToast("Csuccess", action === 'delete' ? "Suppression(s) effectuée(s) avec success..." : "Duplication(s) effectuée(s) avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
    });
  }

  const columns = [
    {
      dataField: "title",
      text: "Action de formation",
      csvText: "title",
      sort: true,
    },
    {
      dataField: "ref",
      text: "ref",
      csvText: "ref",
      sort: true,
      hidden: true,
    },
    {
      dataField: "theme.name",
      text: "Thème",
      csvText: "theme",
      sort: true,
    },
    {
      dataField: "duration",
      text: "Nombre d'heures",
      csvText: "duration",
      sort: true,
    },
    {
      dataField: "statuts",
      text: "Actif",
      csvText: "status",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="ps-3">
            <Form.Check
              type="switch"
              id={row.id}
              onChange={(e) => {
                updateStatus(row.id, e.target.checked);
              }}
              defaultChecked={row.status}
            />
          </div>
        );
      },
    },
    {
      dataField: "sessions",
      text: "Sessions",
      csvText: "sessions",
      formatter: (row) => {
        return <>{row?.length}</>;
      },
    },
    {
      dataField: "cost",
      text: "Cout total",
      csvText: "cost",
      sort: true,
    },
    {
      dataField: "audience",
      text: "audience",
      csvText: "audience",
      sort: true,
      hidden: true,
    },
    {
      dataField: "image",
      text: "image",
      csvText: "image",
      sort: true,
      hidden: true,
    },
    {
      dataField: "programFile",
      text: "programFile",
      csvText: "programFile",
      sort: true,
      hidden: true,
    },
    {
      dataField: "objective",
      text: "objective",
      csvText: "objective",
      sort: true,
      hidden: true,
    },
    {
      dataField: "assoc_cours",
      text: "assoc_cours",
      csvText: "assoc_cours",
      sort: true,
      hidden: true,
    },
    {
      dataField: "training_program",
      text: "training_program",
      csvText: "training_program",
      sort: true,
      hidden: true,
    },
    {
      dataField: "forward_catalog",
      text: "forward_catalog",
      csvText: "forward_catalog",
      sort: true,
      hidden: true,
    },
    {
      dataField: "manager_spec",
      text: "manager_spec",
      csvText: "manager_spec",
      sort: true,
      hidden: true,
    },
    {
      dataField: "manager_spec",
      text: "manager_spec",
      csvText: "manager_spec",
      sort: true,
      hidden: true,
    },
    {
      dataField: "obligation",
      text: "obligation",
      csvText: "obligation",
      sort: true,
      hidden: true,
    },
    {
      dataField: "createdAt",
      text: "createdAt",
      csvText: "createdAt",
      sort: true,
      hidden: true,
    },
    {
      dataField: "comment",
      text: "comment",
      csvText: "comment",
      sort: true,
      hidden: true,
    },
    {
      dataField: "actions",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            <a
              href={"/admin/update-formation-presentielle/" + row.id}
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../../../assets/icons/edit.png")} />
            </a>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setId(row.id);
                setAction('clone');
                setShowConfirmationModal(true);
              }}
            >
              <img src={require("../../../../assets/icons/duplicate.png")} />
            </button>
            {localStorage.getItem("role") === "Super-admin" ? (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  setAction('delete');
                  setShowConfirmationModal(true);
                }}
              >
                <img src={require("../../../../assets/icons/delete.png")} />
              </button>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  const bulkDelete = () => {
    setAction('delete');
    setShowConfirmationModal(true);
  }

  const bulkClone = () => {
    setAction('clone');
    setShowConfirmationModal(true);
  }

  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={training}
        columns={columns}
        exportCSV={{
          onlyExportSelection: true,
          exportAll: false,
          fileName: "FormationPrésentielles.csv",
        }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-5">
              <div className="row">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e) =>
                            props.searchProps.onSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className=" px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => setShowfiltre(true)}
                          >
                            <img
                              src={require("../../../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltreActionformation
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                          minCost={minCost}
                          maxCost={maxCost}
                          minDuration={minDuration}
                          maxDuration={maxDuration}
                        />
                        <div className="square">
                          <button
                            className="btn btn-link"
                            htmlFor="export"
                            onClick={() => props.csvProps.onExport()}
                          >
                            <img
                              src={require("../../../../assets/icons/Export.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label htmlFor="file" type="button">
                            <img
                              src={require("../../../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkClone}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../../../assets/icons/duplicate.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkDelete}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../../../assets/icons/delete.png")}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <a
                          href="/admin/create-formation-presentielle"
                          className="btn btn-primary  btn-gy height43"
                          type="button"
                        >
                          Créer une action de formation
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow(selectedRows, setSelectedRows)}
              pagination={paginationFactory(options)}
              sizePerPageList={[100]}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      {showLoader && <Spinner />}

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            {action === 'delete' ? (id != null ? 'Etes-vous sûr(e) de vouloir supprimer l\'actions de formation' : 'Etes-vous sûr(e) de vouloir supprimer la selection') :
              id != null ? 'Etes-vous sûr(e) de vouloir dupliquer l\'actions de formation' : 'Etes-vous sûr(e) de vouloir dupliquer la selection'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={() => {
              id ? manageAction() : manageBulk()
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
