import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Hn from "../../../../../components/common/Hn";
import "./createUnite.css";
import Option from "../../../../../assets/icons/options.svg";
import Play from "../../../../../assets/icons/Play.png";
import DeleteUniteDeFormation from "./DeleteUniteDeFormation";
import Add from "../../../../../assets/icons/add.png";
import DeleteChapitre from "./DeleteChapitre";
import EditChapitre from "./EditChapitre";
import EditUniteDeFormation from "./EditUniteDeFormation";

const validationSchema = Yup.object().shape({
  titre: Yup.string().required("Le titre est obligatoire"),
});

function CreateChapitreElearning() {
  const [chapitres, setChapitres] = useState([]);

  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return "danger";
    } else if (value) {
      return "info";
    } else {
      return "warning";
    }
  };
  const { elearning_id } = useParams();
  const uri = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getChapitres();
  }, []);

  // Récupérer le détail d'une formation elearning par (id) et ses chapitres
  function getChapitres() {
    trackPromise(
      axios
        .get(uri + "/elearning/" + elearning_id + "/chapitres")
        .then((result) => {
          setChapitres(result?.data);

        })
        .catch((err) => {
          console.log("err sessions", err);
        })
    );
  }

  let history = useHistory();

  // Créer un chapitre
  // Continuez avec l'exécution du gestionnaire de soumission

  const handleSubmit = (values) => {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let data = new FormData();
    data.append("titre", values.titre);
    data.append("elearning", elearning_id);

    trackPromise(
      axios
        .post(uri + "/chapitre/new", data, headersFiles)
        .then((result) => {
          history.push("/admin/create-unite-de-formation/" + result?.data?.id);
        })
        .catch((err) => {
          console.log("err training", err);
        })
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/admin/formation-e-learning"}>
                  Formations Elearning - Actions de formation
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer une action de formation
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Etape 2 : Ajout de contenu"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />

            <div className="form-elements mt-2">
              <div className="form-aria p-4">
                <Formik
                  initialValues={{
                    titre: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form className="row g-3" onSubmit={handleSubmit}>
                      <Hn
                        title="Chapitre"
                        level="p"
                        className="py-2 fw-bold"
                        plus={false}
                      />
                      <div className="col-md-10">
                        <label className="form-label">Titre</label>
                        <input
                          status={onHandelStatus(
                            touched.ref,
                            errors.ref,
                            values.ref
                          )}
                          type="text"
                          className="form-control"
                          id="titre"
                          name="titre"
                          placeholder="Ajouter un titre"
                          onChange={handleChange("titre")}
                          value={values.titre}
                          onBlur={handleBlur("titre")}
                        />
                        <ErrorMessage name="titre">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="col-md-2 pt-4">
                        <button
                          type="submit"
                          className="btn btn-outline-primary float-end"
                        >
                          Ajouter
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>

              {chapitres.map((chap, i) => {
                return (
                  <Fragment key={chap.id}>
                    <div className="form-aria p-4">
                      <div className="accordion" id={`accordion${i}`}>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={`heading${i}`}>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${i}`}
                              aria-expanded="true"
                              aria-controls={`collapse${i}`}
                            >
                              <span className="header-text">{chap.titre}</span>
                            </button>
                            <div className="dropright">
                              <div className="dropdown">
                                <button
                                  className="nostyle "
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src={Option}></img>
                                </button>
                                <ul
                                  className="dropdown-menu dropdown-menu-right ps-0"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <a className="dropdown-item" href={"/admin/create-unite-de-formation/" + chap?.id}>
                                      <img src={Add} className="pe-1" />
                                      Unité
                                    </a>
                                  </li>
                                  <EditChapitre chapitreToBeEdited={chap} />
                                  <DeleteChapitre chapitreToBeDeleted={chap} />
                                </ul>
                              </div>
                            </div>
                          </h2>
                          <div
                            id={`collapse${i}`}
                            className="accordion-collapse collapse show"
                            aria-labelledby={`heading${i}`}
                            data-bs-parent={`#accordion${i}`}
                          >
                            <div className="accordion-body">
                              {chap?.unites?.map((unit) => {
                                return (
                                  <Fragment key={unit.id}>
                                    <div className="row g-3">
                                      <div className="col-md-11">
                                        <Hn
                                          title={unit.titre}
                                          level="p"
                                          className="py-2 fw-bold"
                                          plus={false}
                                        />
                                        <p>
                                          <img src={Play} className="pe-1" />
                                          {unit.document}
                                        </p>
                                      </div>
                                      <div className="col-md-1 d-flex justify-content-around align-bottom align-self-end mb-3">
                                        <EditUniteDeFormation uniteToBeEdited={unit} />
                                        <DeleteUniteDeFormation uniteToBeDeleted={unit} />
                                      </div>
                                    </div>
                                  </Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Fragment>
                );
              })}

              <div className="col-12 mt-5">
                {/* <a
                  href="/admin/create-formation-presentielle"
                  className="btn btn-link text-decoration-none"
                >
                  Retour
                </a> */}
                <a
                  href={"/admin/add-skills-elearning/" + elearning_id}
                  className="btn btn-primary float-end"
                >
                  Suivant
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateChapitreElearning;
