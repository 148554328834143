export function useDatatable() {

  const selectRow = (selectedRows, setSelectedRows) => {
    return {
      mode: "checkbox",
      clickToSelect: false,
      selected: selectedRows,
      onSelect: (row, isSelect) => {
        // Selected file
        if (isSelect) {
          setSelectedRows([...selectedRows, row.id])
        }else{
          setSelectedRows(selectedRows.filter((x) => x !== row.id));
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          const ids = rows.map(r => r.id);
          setSelectedRows(ids)
        }else{
          setSelectedRows([])
        }
      }
    }
  };

  return {
    selectRow,
  }
}
