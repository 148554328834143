import { useRef } from "react";

import Hn from '../../../../../components/common/Hn';
import './createFaceTraining.css';

function CreateSkills() {

    const fileRef = useRef();

    const handleChange = (e) => {
        const [file] = e.target.files;
       
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='container lists p-5'>
                    <nav aria-label="breadcrumb pb-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Formations présentielles - Actiond de formation</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Créer une action de formation</li>
                        </ol>
                    </nav>
                    <div className="h-100 p-5 text-white bg-dark rounded-3">
                        <Hn title="Créer une action de formation" level="h4" className="h4-size" plus={false} />
                        <Hn title="Etap 3 : Compétences associées" level="p" className="py-2 fw-bold" plus={false} />

                        <div className='form-elements mt-2'>
                            <div className="p-4">
                                <form className="row g-3">
                                    <div className="col-md-4">
                                        <label  className="form-label">Référentiel de compétences</label>
                                        <select className="form-select" aria-label="Default select example" id='actif'>
                                            <option selected>sélectinner un réferentiel</option>
                                        </select>
                                    </div>

                                    <div className="col-12 mt-5">
                                        <a href="/admin/create-session-formation-apprendre" className="btn btn-link resetBtn">
                                        Annuler
                                        </a>
                                        <a href="#" type="submit" className="btn btn-outline-primary float-end">
                                            Valider
                                        </a>
                                    </div>
                                </form>
                            </div>
                            
                        </div>
                    </div>

                </div>{/* lists */}
            </div>
        </div>
    )
}

export default CreateSkills