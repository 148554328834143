import axios from "axios";
import { useState } from "react";
import Hn from "../../../../components/common/Hn";
import "./elearningForm.css";
import Accordion from "react-bootstrap/Accordion";
import Add from "../../../../assets/icons/add.png";
import { useHistory } from "react-router-dom";
import Business from "../../../../assets/business.png";
import Chart2 from "../../../../components/chartjs/Chart2";
import ProgressBar from "../../../../components/progressBar/ProgressBar";
function ElearningShow3() {
  let history = useHistory();
  const redirect = () => {
    history.push("/admin/show-elearning");
  };
  const redirect2 = () => {
    history.push("/admin/show-elearning2");
  };
  return (
    <div className="container-fluid">
    <div className="row">
        <div className="container lists p-5">
            <nav aria-label="breadcrumb pb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="#">Formation e-learning - Actions de formation</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Nom de l'action de formation
                    </li>
                </ol>
                <a type="submit" className="btn btn-link resetBtn">Retour</a>
                <br></br>
                <div className="row">
                <div className="col-md-6"><Hn
                  title="Déontologie"
                  level="h4"
                  className="h4-size pb-5"
                  plus={true}
                />
               </div>
               <div className="col-md-6"> 
              
                    <button type="button" className="btn btn-primary float-end">modifier</button>
                    </div>
                
                    </div>
            <div className="row">
              <div className="col-md-3">
                <div className= "card-body text-white text-center"  style={{backgroundColor:'#3A4048'}}>
                   
                  <h5 className="card-title">Taux de complétion</h5>
                  <p className="card-text">13%</p>
                </div>
              </div>
              <div className="col-md-3">
              <div className="card-body text-white text-center"  style={{backgroundColor:'#3A4048'}} onClick={redirect}>
                  <h5 className="card-title">Score</h5>
                  <p className="card-text">87%</p>
                </div>
              </div>
              <div className="col-md-3">
              <div className="elearning-square elearning-bottom card-body text-white text-center" onClick={redirect2}>
                  <h5 className="card-title">Temps passé</h5>
                  <p className="card-text">24h15min</p>
                </div>
              </div>
              <div className="col-md-3">
              <div className="card-body text-white text-center" style={{backgroundColor:'#3A4048'}}>
                  <h5 className="card-title">Apprenants</h5>
                  <p className="card-text">4156</p>
                </div>
              </div>
             <div className="elearn pt-5">
              <Accordion>
                <Accordion.Item>
                  <div className="row">
                    <div className="col-md-6">
                  <Accordion.Header>
                    <span className="header-text">Temps moyen sur le parcours</span>
                  </Accordion.Header>
                  </div>
                 <div className="col-md-6 pt-4">
                 <div className="square float-end" >
                        <input
                          className="btn btn-link"
                          hidden
                        />
                        <label htmlFor="file" type="button" >
                          <img
                          style={{width:'30px'}}
                            src={require("../../../../assets/icons/Export2.png")}
                          />
                        </label>
                        </div>
                  </div> 
                  </div>
                  <Accordion.Body>
                    <div className="row g-3">
                    <div className="col-md-6">
                      <p className="text-white ">
                      Calculé sur les apprenants ayant commencé ou terminé
                      </p>
                      </div>
                      <div className="col-md-6">
                      <p className="text-white float-end">
                     1h 29 min
                      </p>
                      </div>
                      <hr></hr>
                    </div>
                  </Accordion.Body>
                  <Accordion.Header>
                    <span className="header-text">Temps minimum passé en formation</span>
                  </Accordion.Header>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <p className="text-white ">
                      Temps minimum passé par un apprenant pour terminer le parcours
                      </p>
                      </div>
                      <div className="col-md-6">
                      <p className="text-white float-end">
                      0 h 00 min
                      </p>
                      </div>
                      <hr></hr>
                    </div>
                    <Accordion.Header>
                    <span className="header-text">Temps maximum passé en formation</span>
                  </Accordion.Header>
                  <div className="row g-3">
                  <div className="col-md-6">
                      <p className="text-white ">
                      Temps de l’apprenant ayant passé le plus de temps sur le parcours
                      </p>
                      </div>
                      <div className="col-md-6">
                      <p className="text-white float-end">
                      89 h 32 min
                      </p>
                      </div>
                      </div>
                </Accordion.Item>
              </Accordion>
              </div> 
            </div>
          </nav>
          </div>
          <div className="p-5" >
          <div className=" p-5 text-white bg-dark rounded-3">
            <Hn
              title="Informations générales"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />
            <div className="form-elements">
              <form className="row g-3">
                <div className="col-md-4">
                  <label htmlFor="ref" className="form-label">
                    Référence
                  </label>
                  <p> Lorem ipsum</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="nom" className="form-label">
                    Intitulé*
                  </label>
                  <p>Lorem ipsum</p>
                </div>
                <div className="col-md-4"></div>

                <div className="col-md-4">
                  <label htmlFor="denomi" className="form-label">
                    Image
                  </label>
                  <p>lorem_ipsum.pdf</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="adre" className="form-label">
                    Thème
                  </label>
                  <p>Lorem ipsum dolor sit amet</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="codepos" className="form-label">
                    Parcours associé
                  </label>
                  <p>Lorem ipsum dolor sit amet</p>
                </div>

                <div className="col-md-12">
                  <label htmlFor="ville" className="form-label">
                    Pré-requis et public visé
                  </label>
                  <p>
                    Lorem ipsum soloLorem ipsum soloLorem ipsum soloLorem ipsum
                    soloLorem ipsum solo
                  </p>
                </div>
                <div className="col-md-12">
                  <label htmlFor="pays" className="form-label">
                    Objectifs
                  </label>
                  <p>
                    Lorem ipsum soloLorem ipsum soloLoremLorem ipsum soloLorem
                    ipsum soloLorem
                  </p>
                </div>
                <div className="col-12">
                  <label htmlFor="comm" className="form-label">
                    Programme de formation
                  </label>
                  <p>
                    Lorem ipsum dolor sit amet,consecteteur adipiscing elit,seed
                    do eiusmod tempar incidunt ut labore et dolore magna
                    aliqua.Ut enim and minim veniam.
                  </p>
                </div>

                <div className="col-md-4">
                  <label htmlFor="coutj" className="form-label">
                    Programme de formation
                  </label>
                  <p>Lorem ipsum soloLorem ipsumL 2Mo</p>
                </div>

                <div className="col-md-4">
                  <label htmlFor="interloc" className="form-label">
                    Durée thèorique
                  </label>
                  <p>22:30</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="phone" className="form-label">
                    Actif
                  </label>
                  <p>Oui</p>
                </div>
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    Validation
                  </label>
                  <p>80%</p>
                </div>

                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Mise en avant dans le catalogue
                  </label>
                  <p>Non</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Spécifique au manager
                  </label>
                  <p>Non</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Participe à l'obligation de formation
                  </label>
                  <p>Oui</p>
                </div>
                <Hn
                  title="Compétences associées"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    Participe à l'obligation de formation
                  </label>
                </div>
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    déontologie
                  </label>
                </div>
                
              </form>
            </div>
          </div>
          </div>
        </div>
        {/* lists */}
      <div className="row">
        <div className="container lists p-5 pb-0">
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title="Chapitres de la formation"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
              </div>
              <div className="col-md-6 ">
                <div className="float-end">
                  <img src={Add} className="pe-1" />{" "}
                  <a href="">Ajouter un chapitre</a>
                </div>
              </div>
            </div>
            <div className="form-elements">
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>
                    <span className="header-text">Déontologie</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row g-3">
                      <p>
                        8 min | 4 unités d'apprentissage | Derniére
                        modification:02/02/2022 à 14h59 par John Doe
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>
                    <span className="header-text">Validation des acquis</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row g-3">
                      <p>
                        8 min | 4 unités d'apprentissage | Derniére
                        modification:02/02/2022 à 14h59 par John Doe
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default ElearningShow3;
