import axios from "axios";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import Hn from "../../components/common/Hn";
import "./webinar.css";

const validationSchema = Yup.object().shape({
  titre: Yup.string().required("Le titre est obligatoire"),
});

function CreateWebinair2() {
  const editor = useRef(null)
  const [content, setContent] = useState('')
  const [IdEditor, setIdEditor] = useState('')
  const [title, setTitle] = useState('')
  const location = useLocation();
  const config = {
    // all options from https://xdsoft.net/jodit/doc/,
    readonly: false,
    placeholder: '...',
    theme: 'dark',
    hidePoweredByJodit: true,
    height: 400,
    defaultActionOnPaste: "INSERT_AS_HTML",
    processPasteHTML: true,
    editHTMLDocumentMode: true

  }

  const params = useParams();
  const { elearning_id } = useParams();
  const uri = process.env.REACT_APP_BASE_URL;
  //récupérer l'éditeur du webinair ajouté
  function getEditor() {
    trackPromise(
      axios
        .get(uri + "/webinars/" + params.id)
        .then((result) => {

          setTitle(result?.data?.editor?.title)
          setIdEditor(result?.data?.editor?.id)
          setContent(result?.data?.editor?.source);

        })
        .catch((err) => {
          console.log("err sessions", err);
        })
    );
  }
  useEffect(() => {

    getEditor()


  }, []);

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  let dataSend = {}
  const saveEditor = () => {

    dataSend =
    {
      "title": title,
      "source": content
    }
    axios
      .put(uri + "/webinars/editor/edit/" + IdEditor, dataSend, headers)
      .then((result) => {


        history.push("/update-webinar-etape3/" + params.id)
      })
      .catch((err) => {
        console.log("err edit editor", err);
      })
  }
  let history = useHistory();



  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Webinar</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer un webinaire
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title={"Modifier Webinaire " + title}
              level="h4"
              className="h4-size"
              plus={false}
            />
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title="Etape 2 : Editeur"
                  level="p"
                  className="py-2 fw-bold"
                  plus={false}
                />
              </div>
              <div className="col-md-12 my-3">
                <input

                  type="text"
                  className="form-control"
                  id="titre"
                  name="titre"
                  placeholder="Intitulé"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              {/* begin editeur html */}
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              // onChange={newContent => { setContent(newContent) }}
              />
              {/* end editeur html */}
              {/* convert HTML CODE */}
              {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}

              <div className="row">
                <div className="col-10 mt-5">
                  <a type="submit" className=" btn-link resetBtn" href={"/update-webinar/" + params.id} >
                    Retour{" "}
                  </a>
                </div>
                <div className="col-2 mt-5">
                  <button
                    onClick={() => saveEditor()}
                    // href={"/update-webinar-etape3/"+params.id}
                    className="btn btn-primary float-end"
                  >
                    Suivant
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateWebinair2;
