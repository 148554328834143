import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import profil from "../../../../assets/profil.png";
import Hn from "../../../../components/common/Hn";
import "../face-training/crud/createFaceTraining.css";
import "../formateur/ListFormers.css";
import "../location/ListLocationTraining.css";

function ReadFormers() {
  const params = useParams();
  const [former, setFormer] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const uri = process.env.REACT_APP_BASE_URL;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {

    getFormer(params.id);
  }, []);
  const valider = () => {
    history.push("/admin/liste-formateurs");
  };
  let history = useHistory();
  //récupérer un former par son id
  function getFormer(id) {

    axios
      .get(uri+`/former/${params.id}`)
      .then((result) => {

        setFormer(result?.data);

      })
      .catch((err) => {
        console.log("err location", err);
      });
  }
//supprimer un lieu de formation
  function deleteLocation(id) {

    axios
      .delete(uri+"/location/" + id)
      .then(() => {
        getFormer();
      });
    setShow(false);
  }
  const IMG_URL = process.env.REACT_APP_PIC_AVATAR;
  return (
    <div className="banner-former container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
          <div className="row">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <div className="col-md-6">
              <Hn title={former?.firstName+' '+former?.firstName} level="h2" className="pt-4" />
            </div>
            <div className="col-md-6">
              <a
                href={"/admin/update-former/"+params.id}
                className="btn btn-primary float-end mt-4"
                type="button"
              >
                Modifier
              </a>
            </div>
          </div>

          {/* lists-filter */}
          <div className="row">
            <div className="col-3">
              <div>
                <div className="card-body mb-3 text-center p-5">

                  {former?.avatar ?
                  <img
                    src={IMG_URL+former?.avatar}
                    alt="profil"
                    className="rounded-circle image-profil img-fluid"
                    width="100"
                  />
                  :
                  <img
                    src={profil}
                    alt="profil"
                    className="rounded-circle image-profil img-fluid"
                    width="100"
                  />
                  }


                  <div className="pt-4">
                    <img src={require("../../../../assets/gh_white.png")} />
                  </div>
                </div>
                <div className="card-body mb-3">
                  <h5 className="card-title">Unités de formations</h5>
                  <p className="card-text">2</p>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Cout</h5>
                  <p className="card-text">340 € HT</p>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="h-100 p-5 text-white bg-dark rounded-3">
                <Hn
                  title="Nom du formateur"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />

                <div className="form-elements">

                  <form className="row g-3">
                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label">
                        Formateur
                      </label>
                      <p>{former?.organize}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="nom" className="form-label">
                        Nom
                      </label>
                      <p>{former?.lastName}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="capa" className="form-label">
                        Prénom
                      </label>
                      <p>{former?.firstName}</p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="denomi" className="form-label">
                        Référence
                      </label>
                      <p>{former?.ref}</p>
                    </div>
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-4">
                      <label htmlFor="codepos" className="form-label">
                      Photo
                      </label>
                      <p>{former?.avatar}</p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                        Téléphone
                      </label>
                      <p>{former?.tel}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="pays" className="form-label">
                        Email
                      </label>
                      <p>{former?.email}</p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="coutj" className="form-label">
                        Actif
                      </label>
                      <p>{former?.status ? "oui" : "non"}</p>
                    </div>

                    <div className="col-12">
                      <label htmlFor="comm" className="form-label">
                        Commentaire
                      </label>
                      <p>
                      {former?.comment}
                      </p>
                    </div>

                    <div className="col-12 pt-5">
                      <button
                        type="button"
                        className="btn btn-primary float-end"
                         onClick={valider}
                      >
                        Valider
                      </button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReadFormers;
