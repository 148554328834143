export function useEditor() {

  const editorConfiguration = {
    toolbar: [ 'bold', 'italic', 'bulletedList' ]
  };

  return {
    editorConfiguration,
  }
}
