import Hn from "../../../../components/common/Hn";
import "../face-training/crud/createFaceTraining.css";
import "../location/ListLocationTraining.css";
import ListLocationTrainingDataGrid from "./ListLocationTrainingDataGrid";

function ListLocationTraining() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="Lieux de formation" level="h2" className="pt-4" />
          </div>
          <div className="mt-20">
              <ListLocationTrainingDataGrid />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListLocationTraining;
