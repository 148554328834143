import axios from "axios";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { trackPromise } from "react-promise-tracker";
import Hn from "../../../../../components/common/Hn";
import "./competences.css";
const uri = process.env.REACT_APP_BASE_URL;
let headers = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
var dte = new Date();

const validationSchema = Yup.object().shape({
  //competences: Yup.string().required("Veuillez choisir des comptétences SVP!"),
});

function CompetencesElearning() {
  const fileRef = useRef();
  const [competences, setCompetences] = useState([]);
  const [elearningSkils, setSkils] = useState([]);
  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return "danger";
    } else if (value) {
      return "info";
    } else {
      return "warning";
    }
  };

  const uri = process.env.REACT_APP_BASE_URL;

  const { elearning_id } = useParams();
  const [selected, setSelected] = useState([]);

  // Reprendre la liste des compétences

  function getCompetences(type) {
    trackPromise(
      axios
        .get(uri + "/skills")
        .then((result) => {
          result.data.forEach((element) => {
            competences.push({ label: element.title, value: element.id });

            if (elearningSkils.indexOf(element.id) != -1) {
              selected.push({ label: element.title, value: element.id });
            }
          });
        })
        .catch((err) => {
          console.log("err formateurs", err);
        })
    );
  }
    // récupérer le détail d'une formation elearning et ses compétences
  function getSkils() {
    trackPromise(
      axios
        .get(uri + "/elearning/" + elearning_id + "/get_skills")
        .then((result) => {
          setSelected(result?.data);
        })
        .catch((err) => {
          console.log("err formateurs", err);
        })
    );
  }

  useEffect(() => {
    getSkils();
    getCompetences();
  }, []);

  const handleLieuchange = (e) => {

  };
  let history = useHistory();
    // Ajouter une compétence à la formation elearning qui est récupérée par (id)
  // Continuez avec l'exécution du gestionnaire de soumission

  const handleSubmit = (values) => {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    var data = new FormData();

    selected.forEach((item) => {
      data.append("competences[]", item.value);
    });

    trackPromise(
      axios
        .post(
          uri + "/elearning/" + elearning_id + "/set_skills",
          data,
          headersFiles
        )
        .then((result) => {
          history.push("/admin/formation-e-learning");
        })
        .catch((err) => {
          console.log("err training", err);
        })
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Formations présentielles - Actions de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer une action de formation
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Etape 3 : Compétences associées"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />
            <div className="form-elements mt-2">
              <div className="form-aria p-4">
                <Formik
                  initialValues={{
                    competences: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form className="row g-3" onSubmit={handleSubmit}>
                      <div className="col-md-10">
                        <label className="form-label">
                          Référentiel de compétences
                        </label>
                        <MultiSelect
                          options={competences}
                          value={selected}
                          name="competences"
                          onChange={setSelected}
                          labelledBy="Choisir"
                          disableSearch
                          className="themeSelect"
                        />
                      </div>

                      <div className="col-12 mt-5">
                        <a
                          href={
                            "/admin/creer-chapitre-e-learning/" + elearning_id
                          }
                          className="btn btn-link text-decoration-none"
                        >
                          Retour
                        </a>
                        <button
                          type="submit"
                          className="btn btn-primary float-end"
                        >
                          Valider
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompetencesElearning;
