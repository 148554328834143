import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ParticipantDatatable from "./participantDatatable";

function Participant({ type = "session"}) {

  const params = useParams();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
          <div className="pt-5">
            <ParticipantDatatable
              type={type}
              id_session={params.id}
            />
          </div>

        </div>
      </div>
    </div>
  );
}
export default Participant;
