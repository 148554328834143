import axios from "axios";
import Hn from "../../components/common/Hn";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export default function Info() {
  const params = useParams();
  const [thematique, setThematique] = useState([]);
  const uri = process.env.REACT_APP_BASE_URL;

    //récupérer une thématique par son id
  function getTheme(id) {
    axios.get(uri + `/thematic/${params.id}`)
      .then((result) => {
        setThematique(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }

  useEffect(() => {
    getTheme(params.id);
  }, []);

  return (
      <div className="container-fluid">
          <div className="row">
              <div className="container lists p-5">
                  <nav aria-label="breadcrumb pb-3">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                              <a href="/admin/thematique">Thématique</a>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                              Ajouter une thématique
                          </li>
                      </ol>
                  </nav>
                  <div className="headupdate"><Hn title={
                              thematique.name
                          }
                          level="h1"
                          className="py-4"/>
                      <a href={"/admin/updatethematique/" + thematique.id} className="btn btn-primary float-end me-3">Modifier</a>
                  </div>
                  <div className="h-100 p-5 text-white bg-dark rounded-3 me-3">
                      <Hn title="Informations générales" level="h4" className="h4-size pb-5"
                          plus={false}/>

                      <div className="form-elements">
                          <div className="row">
                              <div className="col-4">
                                  <div className="mb-3">
                                      <label htmlFor="" className="form-label">
                                          Référence
                                      </label>
                                      <br></br>
                                      <span>{
                                          thematique.ref
                                      }</span>
                                  </div>
                              </div>
                              <div className="col-8">
                                  <div className="mb-3">
                                      <label htmlFor="" className="form-label">
                                          Nom de la thématique
                                      </label>
                                      <br></br>
                                      <span>{
                                          thematique.name
                                      }</span>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div className="row">
                                  <div className="col-8">
                                      <div className="mb-3">
                                          <label htmlFor="" className="form-label">
                                              Sous-thème
                                          </label>
                                          <br></br>
                                          {
                                          thematique ?. subtheme ?. map((val, index) => (
                                              <span key={index}>{
                                                  val.name
                                              }<br></br>
                                              </span>
                                          ))
                                      } </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}
