import Hn from "../../../../components/common/Hn";
import "./elearning.css";
import ListElearningsDataGrid from "./ListElearningsDataGrid";
import { ToastContainer } from "react-toastify";

function Elearning() {
  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations E-learning"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="Actions de formation" level="h2" className="pt-4" />
          </div>
          {/* lists-filter */}
          <div className="pt-5 trainingTable">
              <ListElearningsDataGrid />
          </div>

        </div>
      </div>
    </div>
  );
}

export default Elearning;
