import Icon from "../../assets/icons/Icon.svg";
import IconE from "../../assets/icons/Elearning.svg";
import Placeholder from "../../assets/placeholder.png";
import "./cadreItems.css";
import React, { useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import PopupSubscribe from "../popup/PopupSubscribe";
import { trackPromise } from 'react-promise-tracker';
import axios from "axios";
import { useHistory } from "react-router-dom";
export default function CadreItems({
  title,
  base_url,
  image,
  index,
  disable,
  switchs,
  id,
  details,
  parcours,
  isflip,
  elearning,
  isElearning,
  enCours = false,
}) {

  const [showfiltreSubscribe, setShowfiltreSubscribe] = useState(false);
  const [scoreds, setScoreds] = useState([]);
  const [isfliped, setIsFliped] = useState(isflip);
  const [nbChapitres, setNbChapitres] = useState();
  const uri = process.env.REACT_APP_BASE_URL;
  const [score, setScore] = useState();
  const history = useHistory();

  function closefiltreSubscribe() {
    setShowfiltreSubscribe("false");
  }
//récupérer la formation elearning récupérée par son id
  function getElearning() {
    if (isflip) {
      axios
        .get(uri + "/elearning/" + id)
        .then((result) => {
          setNbChapitres(result?.data.chapitres.length);
        })
        .catch((err) => {
          console.log("err elearning", err);
        });
    }
  }

  if (disable) disable = "visually-hidden";
  let img;

  const url = "/" + details + "/" + id;

  if (image !== null) {
    img = base_url + image;
  } else {
    img = Placeholder;
  }
  function checkvalidity() {
    if (elearning === "non") {
      setShowfiltreSubscribe("true");
    } else {
      history.push(url);
    }
  }
  const [flip, setFlip] = useState(false);
//création des données e-learning de l'utilisateur en question.  Le score par formation, l'avancement...
  function getUserData() {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    let data = new FormData();
    data.append("user_email", localStorage.getItem("email"));

    if( enCours === true ){
      trackPromise(
        axios
          .post(
            uri + "/watershed/get_user_data_for_elearning/" + id,
            data,
            headersFiles
          )
          .then((result) => {
           if(result.data.play.label==="Commencer") {

            setIsFliped(false)

          }
            setScore(result?.data?.score);
            setScoreds(result?.data);


          })
          .catch((err) => {
            setScore([]);
            setScoreds([]);
            setIsFliped(false)
          })
      );
    }

  }

  useEffect(() => {
    getUserData();
    getElearning();
  }, []);

  const sectionStyle = {
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "290px",
  };

  return (
    <ReactCardFlip
      isFlipped={isfliped ? flip : false}
      flipDirection="horizontal"
      style={{ marginBottom: "20px" }}
    >
      <div>
        <div className="card">
          {switchs ? (
            <span className={`icon ${disable}`}>
              <span className="look-like-link" onClick={checkvalidity}>
              {isElearning ? <img
                src={IconE}
                alt="Icon"
                className="fleche-right"
              /> : <img
              src={Icon}
              alt="Icon"
              className="fleche-right"
            />}
              </span>
            </span>
          ) : (
            <span className={`count ${disable}`}>{index + 1}</span>
          )}

          <span className={`icon`}>

            <span className="look-like-link" onClick={checkvalidity}>
             {isElearning ? <img
                src={IconE}
                alt="Icon"
                className="fleche-right"
              /> : <img
              src={Icon}
              alt="Icon"
              className="fleche-right"
            />}
            </span>
          </span>

          <div
            style={sectionStyle}
            onClick={() => (isflip ? setFlip(!flip) : "")}
          ></div>
          <PopupSubscribe
            showfiltreSubscribe={showfiltreSubscribe}
            setShowfiltreSubscribe={setShowfiltreSubscribe}
            closefiltreSubscribe={closefiltreSubscribe}
            title={title}
          />
          <div onClick={checkvalidity}>
            <div className="userI card-body">
              <h5 className="card-title">
                {title}
                <div className="fleche-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M5 12H19"
                      stroke="#006FFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 5L19 12L12 19"
                      stroke="#006FFF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          minHeight: "380px",
          width: "auto",
          backgroundColor: "#1E2E45",
          padding: "30px",
        }}
        onClick={() => (isflip ? setFlip(!flip) : "")}
      >
        <div>
          <p className="titleBack">Parcours associé : </p>
          <p className="textBack">{parcours}</p>

          <p className="textBack">
            <span className="titleBack">Score actuel:</span> {score}%
          </p>
          <p className="titleBack">
            Unités d’apprentissage restantes :{nbChapitres - scoreds?.validated}
          </p>
          <button
            onClick={() => {
              history.push(url + "/run");
            }}
            className="btn btn-primary flex-end reprendre"
          >
            Reprendre
          </button>
          <br />
        </div>
      </div>
    </ReactCardFlip>
  );
}
