import { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link, useHistory, useParams } from "react-router-dom";
import Hn from "../../components/common/Hn";

import axios from "axios";
import Modal from "react-bootstrap/Modal";
import logoDoc from "../../assets/logodoc.png";
import { Interweave } from "interweave";

export default function DetailsWebinar({}) {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [showModalBool, setShowModalBool] = useState(false);
  const [showModalInscri, setShowModalInscri] = useState(false);
  const [showfiltre, setShowfiltre] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [check, setCheck] = useState(0);

  const params = useParams();
  const [webinar, setWebinar] = useState([]);
  let history = useHistory();

  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const uri = process.env.REACT_APP_BASE_URL;

  const data = {
    participant: localStorage.getItem("id"),
  };

  const handleClosefiltre = () => {
    setShowfiltre(false);
  };
//lister un éditeur par son id
  function getEditor() {
    trackPromise(
      axios
        .get(uri + "/webinars/" + params.id)
        .then((result) => {

          setTitle(result?.data?.editor?.title);
          setContent(result?.data?.editor?.source);
        })
        .catch((err) => {
          console.log("err sessions", err);
        })
    );
  }

  //lister un webinaire par son id
  function getWebinars() {
    trackPromise(
      axios
        .get(uri + "/webinars/" + params.id)
        .then((result) => {
          setWebinar(result?.data);

        })
        .catch((err) => {
          console.log("err webinars", err);
        })
    );
  }

  // vérifier si un participant est inscrit dans ce webianire récupéré par son id ou non  si return 1 donc le participant déja inscrit dans ce webinbaire choisi sinon c'est le cas inverse
  function getInscriWeb() {
    trackPromise(
      axios
        .get(
          uri +
            `/webinars/${params.id}/check/particiapnt/${localStorage.getItem(
              "id"
            )}`
        )
        .then((result) => {
          setCheck(result?.data);

        })
        .catch((err) => {
          console.log("err webinars", err);
        })
    );
  }
//ajouter (inscrire) un nouveau participant au webinaire crée
  function inscrire() {
    axios
      .put(`${uri}/webinars/edit/particiapnt/` + params.id, data, headers)
      .then((response) => {
        setTimeout(() => {
          history.go(0);
        }, 100);
      })
      .catch((error) => {
        console.log("error", error);
        // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }
  function handleChange(content, editor) {
    setContent(content);
  }

  useEffect(() => {
    getWebinars();
    getInscriWeb();
  }, []);

  const showModal = () => {};

  const hideModal = () => {
    setShowModalBool(false);
    setShowModalInscri(false);
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="details pt-5 px-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link rel="opener" to={"/webinair"}>
                  Webinaires
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {webinar?.title}
              </li>
            </ol>
            <div>

              <span className="look-like-link" onClick={()=>history.goBack()} >Retour</span>
              {webinar && (
                <Modal
                  show={showModalBool}
                  onHide={() => hideModal()}
                  className=""
                  fullscreen={true}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <div>
                      <img src={logoDoc} width="150" />
                      <Hn
                        title={webinar?.editor?.title}
                        level="h4"
                        plus={false}
                        className="pb-3"
                      />
                    </div>
                    <div className=" col-md-12 pb-3">
                      <Interweave content={webinar?.editor?.source} />
                    </div>
                  </Modal.Body>
                </Modal>
              )}

              <Modal
                show={showModalInscri}
                onHide={() => hideModal()}
                size="lg"
                className="special-width-popup"
              >
                <Modal.Header closeButton>
                  <Modal.Title className="px-3 nopad">
                    <Hn title="Félicitations!" level="h3" plus={false} />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row px-3 nopad">
                    <div className="col-md-12">
                      <Hn
                        title="Nous vous confirmons l'inscription de :"
                        level="h5"
                        plus={false}
                        className="label-popup"
                      />
                      <p className="text-white textBox">
                        {`${localStorage.getItem("nom")} ${localStorage.getItem("prenom")}`}
                      </p>
                    </div>
                    <div className="pt-2">
                    <Hn
                        title="Webinaire :"
                        level="h5"
                        plus={false}
                        className="label-popup"
                      />
                      <p className="text-white textBox">
                        {webinar?.title}
                      </p>
                    </div>
                  </div>
                  {/* /row */}
                  <br></br>
                  {/* /row */}{" "}
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary float-end" onClick={() => {hideModal(); inscrire()}}>
                    Confirmer
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </nav>
          <div className="head-details">
            <div className="row">
              <div className="col-md-9">
                <Hn
                  title={webinar?.intitule}
                  level="h1"
                  dispaly
                  className="pt-4"
                  plus={false}
                />

                <p className="text text-white py-4">
                  <span>

                    {webinar.obligation
                      ? " Participe à l’obligation de formation "
                      : ""}

                    {webinar.manager_spec ? "  Spécifique au manager" : ""}
                    {webinar.forward_catalog
                      ? "  Mise en avant dans le catalogue"
                      : ""}
                  </span>
                </p>
              </div>
              <div className="col-md-3">
                {check != 0 ? (
                  <button
                    className="btn btn-primary float-end"
                    onClick={() => {
                      setShowModalBool(true);
                    }}
                  >
                    Reprendre
                  </button>
                ) : (
                  <button
                    className="btn btn-primary float-end"
                    onClick={() => setShowModalInscri(true)}
                  >
                    S'inscrire
                  </button>
                )}
              </div>

              {/* <PopupVideo
                showfiltre={showfiltre}
                closefiltre={handleClosefiltre}
                play={play}
                run={params.run}
              /> */}
            </div>
          </div>
          <div className="body-details pb-5">
            <div className="row p-3">
              <div className="col-md-8 border-end">
                <Hn
                  title="Objectifs"
                  level="h4"
                  plus={false}
                  className="pb-3"
                />
                {webinar?.objects && (
                  <div className="simpletext py-1 text-white">
                    <Interweave content={webinar.objects} />
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <div className="justify-content-between rest ">
                  <div className="align-self-center" style={ {lineHeight: 6}}>
                    <Hn
                      title={
                        parseInt(
                          Math.abs(
                            webinar?.capacity_max -
                              webinar?.contacts?.length
                          )
                        ) + " places restantes "
                      }
                      plus={false}
                      className="text-white text-center"

                    />
                  </div>
                  <div className="align-self-center"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-details row mt-8 m-1">
            <div className="col-md-12">
              <div className={`titre-methode-vente pt-4 no-padding `}>
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <Hn
                    title="Détails de la formation"
                    level="h4"
                    className="mb-3"
                    plus={false}
                  />
                  <span
                    className="look-like-link"
                    onClick={() => setOpenDetails(!openDetails)}
                  >
                    Voir tout
                  </span>
                </div>
                <div
                  className="accordion"
                  style={{ display: openDetails ? "block" : "none" }}
                >
                  {webinar?.audience && (
                    <>
                      <Hn
                        title="Prérequis et public visé"
                        level="h5"
                        className="mb-2"
                        plus={false}
                      />
                      <div className="simpletext py-1 text-white">
                        <Interweave content={webinar.audience} />
                      </div>
                    </>
                  )}

                  {(webinar?.programFile || webinar.training_program) && (
                    <>
                      <div
                        className="flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        {" "}
                        <Hn
                          title="Programme"
                          level="h5"
                          className="mb-2 mt-3"
                          plus={false}
                        />
                        {webinar?.programFile && (
                            <a
                              href={
                                process.env.REACT_APP_PIC_WEBINAR_FILE +
                                webinar?.programFile
                              }
                              target="_blank"
                              className="donwload mb-2 mt-3"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                fill="currentColor"
                                className="bi bi-download"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                              </svg>{" "}
                              Télécharger le programme
                            </a>
                          )}
                      </div>
                      {webinar?.training_program && (
                        <div className="simpletext py-1 text-white">
                          <Interweave content={webinar.training_program} />
                        </div>
                      )}
                    </>
                  )}

                  {webinar?.comment && (
                    <>
                      <div style={{ justifyContent: "space-between" }}>
                        <Hn
                          title="Commentaire"
                          level="h5"
                          className="mb-2 mt-3"
                          plus={false}
                        />
                        <div className="simpletext py-1 text-white">
                          <Interweave content={webinar.comment} />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
