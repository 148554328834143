import ParticpantDatatable from "../participantDatatable";
import { Link, useParams } from "react-router-dom";

function Addparticipants() {
  const params = useParams();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/admin/session/show/${params.id}`}>
                  Formations présentielles - Session de formation
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ajouter un Participant
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">

            <ParticpantDatatable
              session={params.id}
            ></ParticpantDatatable>
            <a
              href={"/admin/session/show/" + params.id}
              className="btn btn-primary float-right">
              valider
            </a>
          </div>


        </div>
      </div>
    </div>
  );
}
export default Addparticipants;
