import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Hn from "../../../../components/common/Hn";
import "./elearningForm.css";
import { useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Add from "../../../../assets/icons/add.png";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import ProgressBar from "../../../../components/progressBar/ProgressBar";
import { trackPromise } from 'react-promise-tracker';
import toast, { Toaster } from 'react-hot-toast';
import { Interweave } from "interweave";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

function ElearningShow() {
  const params = useParams();
  const [learning, setLearning] = useState([]);
  const [skills, setSkills] = useState([]);
  const [chapitres, setChapitres] = useState([]);
  const [stats, setStats] = useState([]);
  const [tauxPanelActive, setTauxPanelActive] = useState(true);
  const [scorePanelActive, setScorePanelActive] = useState(false);
  const [tempsPanelActive, setTempsPanelActive] = useState(false);
  const [elearnings, setElearnings] = useState([]);
  const [trainings, setTrainings] = useState([]);

  const uri = process.env.REACT_APP_BASE_URL;
  let history = useHistory();

  useEffect(() => {
    panelActive('taux');
    elearningFind(params.id);
    getSkills();
    getElearnings();
    getTrainings();

  }, []);


  const labels = stats?.score_labels;
  const labels_values = stats?.score_values;

  const score_data = {
    labels,
    datasets: [
      {
        label: 'Scores',
        data: labels_values,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const data = {
    datasets: [
      {
        label: '# of Votes',
        data: [stats?.pas_commence?.nb, stats?.valide?.nb, stats?.non_valide?.nb, stats?.en_cours?.nb],
        backgroundColor: [
          '#4C4C4C',
          '#3EA279',
          '#DD3333',
          '#FFB13B',
        ],
        borderColor: [
          '#4C4C4C',
          '#3EA279',
          '#DD3333',
          '#FFB13B',
        ],
        borderWidth: 1,
      },
    ],
  };


  const score_dounught = {
    datasets: [
      {
        label: '#',
        data: [stats?.score_moyen, 100 - stats?.score_moyen],
        backgroundColor: [
          '#4C4C4C',
          '#3EA279',
          '#DD3333',
          '#FFB13B',
        ],
        borderColor: [
          '#4C4C4C',
          '#3EA279',
          '#DD3333',
          '#FFB13B',
        ],
        borderWidth: 1,
      },
    ],
  };

//récupérer les formations elearning
  function getElearnings() {
    trackPromise(
      axios
        .get(uri + "/elearning")
        .then((result) => {
          setElearnings(result?.data);
        })
        .catch((err) => {
          console.log("err location", err);
        })
    );
  }
//récupérer les formations présentielles
  function getTrainings() {
    trackPromise(
      axios
        .get(uri + "/training")
        .then((result) => {
          setTrainings(result?.data);
        })
        .catch((err) => {
          console.log("err location", err);
        })
    );
  }

//affichage d'un box (panel) avec 4 contenus calculés automatiquement(le taux de complétion de la formation elearning choisie,score,temps passé, et ses apprenants )
  const panelActive = (name) => {
    switch (name) {
      case 'taux':
        setTauxPanelActive(true);
        setScorePanelActive(false);
        setTempsPanelActive(false);
        break;

      case 'score':
        setTauxPanelActive(false);
        setScorePanelActive(true);
        setTempsPanelActive(false);
        break;

      case 'temps':
        setTauxPanelActive(false);
        setScorePanelActive(false);
        setTempsPanelActive(true);
        break;

      default:
        history.push("/admin/apprenant");
        break;
    }
  }

  //récupérer le contenu(les chapitres de la formation elearning choisie avec ses unités d'apprentissage)
  function elearningFind(id) {

    trackPromise(
      axios
        .get(`${uri}/elearning/get_elearning_data/${id}`)
        .then((result) => {

          setLearning(result?.data?.elearning);
          setChapitres(result?.data?.elearning?.chapitres)
          setStats(result?.data?.stats);

        })
        .catch((err) => {
          toast.error("Désolé cette formation n'a pas de contenu");
          setTimeout(() => {
            history.push("/admin/update-formation-e-learning/" + params.id);
          }, 1500);
        }));
  }

  //récupérer les compétences de la formation elearning
  function getSkills() {
    axios
      .get(uri + `/elearning/${params.id}/get_skills/`)
      .then((result) => {
        setSkills(result?.data);

      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
  return (
    <div className="container-fluid pb-5">
      <div className="row">
        <div className="container lists pe-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/admin/formation-e-learning"}>
                  Formation e-learning - Actions de formation
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {learning?.intitule}
              </li>
            </ol>
            <Link to={"/admin/formation-e-learning"} className={"btn btn-link resetBtn"}>
              Retour
            </Link>
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title={learning?.intitule}
                  level="h4"
                  className="h4-size pb-5"
                  plus={true}
                />
              </div>
              <div className="col-md-6">
                <Link to={`/admin/update-formation-e-learning/${learning?.id}`} className={"btn btn-primary float-end"}>
                  Modifier
                </Link>
              </div>
            </div>
            <div className="row pb-5" id="top-panels">
              <div className="col-md-3">
                <div className={`card-body py-5  text-white text-center ${tauxPanelActive ? 'top-active' : ''}`} style={{ backgroundColor: '#3A4048' }} onClick={() => { panelActive('taux'); }} >
                  <h5 className="card-title" >Taux de complétion</h5>
                  <p className="card-text">{parseInt(stats?.valide?.percent).toFixed(2)} %</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className={`card-body py-5  text-white text-center ${scorePanelActive ? 'top-active' : ''}`} style={{ backgroundColor: '#3A4048' }} onClick={() => { panelActive('score'); }} >
                  <h5 className="card-title">Score</h5>
                  <p className="card-text">{parseInt(stats?.score_moyen).toFixed(2)} %</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className={`card-body py-5  text-white text-center ${tempsPanelActive ? 'top-active' : ''}`} style={{ backgroundColor: '#3A4048' }} onClick={() => { panelActive('temps'); }} >
                  <h5 className="card-title">Temps passé</h5>
                  <p className="card-text">{ stats.temps_total }</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card-body py-5  text-white text-center" style={{ backgroundColor: '#3A4048' }} onClick={() => { panelActive('apprenant'); }} >
                  <h5 className="card-title">Apprenants</h5>
                  <p className="card-text">{stats?.total}</p>
                </div>
              </div>
            </div>
          </nav>

          {tauxPanelActive && (
            <div id="taux-div" className="p-3" style={{ backgroundColor: '#1E2E45' }}>
              <div className="row pt-3" >
                <div className="col-lg-8 col-md-7 col-xs-6 pt-3 text-white border-end">
                  <div className="row" >
                    <div className="col-lg-3">
                      <p>Pas commencé:</p>
                    </div>
                    <div className="col-lg-5">
                      <ProgressBar bgColor={"#4C4C4C"} width={parseInt(stats?.pas_commence?.percent).toFixed(2) + '%'} />
                    </div>
                    <div className="col-lg-4">
                      <p>{stats?.pas_commence?.nb} apprenants ({parseInt(stats?.pas_commence?.percent).toFixed(2)} %)</p>
                    </div>

                    <div className="col-lg-3">
                      <p>En cours:</p>
                    </div>
                    <div className="col-lg-5">
                      <ProgressBar bgColor={"#FFB13B"} width={parseInt(stats?.en_cours?.percent).toFixed(2) + '%'} />
                    </div>
                    <div className="col-lg-4">
                      <p>{stats?.en_cours?.nb} apprenants ({parseInt(stats?.en_cours?.percent).toFixed(2)} %)</p>
                    </div>

                    <div className="col-lg-3">
                      <p>Non validé:</p>
                    </div>
                    <div className="col-lg-5">
                      <ProgressBar bgColor={"#dd3333"} width={parseInt(stats?.non_valide?.percent).toFixed(2) + '%'} />
                    </div>
                    <div className="col-lg-4">
                      <p>{stats?.non_valide?.nb} apprenants ({parseInt(stats?.non_valide?.percent).toFixed(2)} %)</p>
                    </div>

                    <div className="col-lg-3">
                      <p>Validé:</p>
                    </div>
                    <div className="col-lg-5">
                      <ProgressBar bgColor={"#3EA279"} width={parseInt(stats?.valide?.percent).toFixed(2) + '%'} />
                    </div>
                    <div className="col-lg-4">
                      <p>{stats?.valide?.nb} apprenants ({parseInt(stats?.valide?.percent).toFixed(2)} %)</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="d-flex justify-content-between rest">
                        <div className="chart mb-7 ">
                          <div className="chartContainer" >
                            <Doughnut data={data} />
                            <div className="chartInner">
                              <div className="chartStatus">Total: {stats?.total}</div>
                            </div>
                          </div>
                        </div>
                        <div className="square" >
                          <input
                            className="btn btn-link"
                            hidden
                          />
                          <label htmlFor="file" type="button" >
                            <img
                              style={{ width: '30px' }}
                              src={require("../../../../assets/icons/Export2.png")}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {scorePanelActive && (
            <div id="score-div" className="p-3" style={{ backgroundColor: '#1E2E45' }}>
              <div className="row pt-3" >
                <div className="col-lg-8 col-md-7 col-xs-6 pt-3 text-white border-end">
                  <div className="row" >

                    <div className="col-lg-12">

                      <Bar data={score_data} />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="d-flex justify-content-between rest">
                        <div className="chart mb-7 ">
                          <div className="chartContainer" >
                            <Doughnut data={score_dounught} />
                            <div className="chartInner">
                              <div className="chartStatus">Total: {stats?.score_moyen}%</div>
                            </div>
                          </div>
                        </div>
                        <div className="square" >
                          <input
                            className="btn btn-link"
                            hidden
                          />
                          <label htmlFor="file" type="button" >
                            <img
                              style={{ width: '30px' }}
                              src={require("../../../../assets/icons/Export2.png")}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}

          {tempsPanelActive && (
            <div id="temps-div" className="p-3" style={{ backgroundColor: '#1E2E45' }}>
              <div id="export-btn" className="square float-end" >
                <input
                  className="btn btn-link"
                  hidden
                />
                <label htmlFor="file" type="button" >
                  <img
                    style={{ width: '30px' }}
                    src={require("../../../../assets/icons/Export2.png")}
                  />
                </label>
              </div>
              <div className="row pt-5 bottom-bordered mr-50">
                <div className="col-md-8">
                  <h5 className="text-white font-weight-bold">Temps moyen sur le Parcours</h5>
                  <p className="text-white">Calculé sur les apprenants ayant commencé ou terminé</p>
                </div>
                <div className="col-md-4">
                  <p className="text-white float-end">
                  { stats.average_time }
                  </p>
                </div>
              </div>


              <div className="row pt-5 bottom-bordered mr-50">
                <div className="col-md-8">
                  <h5 className="text-white font-weight-bold">Temps minimum passé en formation</h5>
                  <p className="text-white">Temps minimum passé par un apprenant pour terminer le parcours</p>
                </div>
                <div className="col-md-4">
                  <p className="text-white float-end">
                  { stats.min_time }
                  </p>
                </div>
              </div>


              <div className="row pt-5 mr-50">
                <div className="col-md-8">
                  <h5 className="text-white font-weight-bold">Temps maximum passé en formation</h5>
                  <p className="text-white">Temps de l'apprenant ayant passé le plus de temps sur le parcours</p>
                </div>
                <div className="col-md-4">
                  <p className="text-white float-end">
                  { stats.max_time }
                  </p>
                </div>
              </div>

            </div>
          )}
        </div>
        <div className="pe-5 mt-5" >
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="Informations générales"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <form className="row g-3">
                <div className="col-md-4">
                  <label htmlFor="ref" className="form-label">
                    Référence
                  </label>
                  <p>{learning?.ref}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="nom" className="form-label">
                    Intitulé*
                  </label>
                  <p>{learning?.intitule}</p>
                </div>
                <div className="col-md-4"></div>

                <div className="col-md-4">
                  <label htmlFor="denomi" className="form-label">
                    Image
                  </label>
                  <p>{learning?.image}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="adre" className="form-label">
                    Thème
                  </label>
                  <p>{learning?.theme?.name}</p>
                </div>
                {learning?.associationRoute?.id && (
                  <div className="col-md-4">
                    <label htmlFor="codepos" className="form-label">
                      Parcours associé
                    </label>
                    <p>{learning?.associationRoute?.title}</p>
                  </div>
                )}

                <div className="col-md-12">
                  <label htmlFor="ville" className="form-label">
                    Pré-requis et public visé
                  </label>
                  <Interweave content={learning?.prerequis ?? 'aucun pré-requis'} />
                </div>
                <div className="col-md-12">
                  <label htmlFor="pays" className="form-label">
                    Objectifs
                  </label>
                  <Interweave content={learning?.objectifs ?? 'aucun objectif'} />
                </div>
                <div className="col-12">
                  <label htmlFor="comm" className="form-label">
                    Programme de formation
                  </label>
                  <Interweave content={learning?.programme_de_formation ?? 'aucun programme'} />
                </div>

                <div className="col-md-4">
                  <label htmlFor="coutj" className="form-label">
                    Programme de formation
                  </label>
                  <p>{learning?.fichier_programme !== 'null' ? learning?.fichier_programme : 'aucun fichier'}</p>
                </div>

                <div className="col-md-4">
                  <label htmlFor="interloc" className="form-label">
                    Durée thèorique
                  </label>
                  <p>{learning?.duree} min</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="phone" className="form-label">
                    Actif
                  </label>
                  <p>{learning?.actif ? 'Oui' : 'Non'}</p>
                </div>
                {/* <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    Validation
                  </label>
                  <p>{learning?.validation}</p>
                </div> */}

                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Mise en avant dans le catalogue
                  </label>
                  <p>{(learning?.mis_en_avant == 1)? 'Oui' : 'Non'}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Spécifique au manager
                  </label>
                  <p>{(learning?.manager == 1)? 'Oui' : 'Non'}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Participe à l'obligation de formation
                  </label>
                  <p>{(learning?.obligation_de_formation == 1)? 'Oui' : 'Non'}</p>
                </div>
                <Hn
                  title="Compétences associées"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    <p>{learning?.skills}</p>
                  </label>
                </div>
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    {learning?.ref}
                  </label>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* lists */}
      <div className="row">
        <div className="container lists pe-4 mt-5">
          <div className="h-100 p-5 me-4 text-white bg-dark rounded-3">
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title="Chapitres de la formation"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
              </div>
              <div className="col-md-6 ">
                <div className="float-end">
                  <Link to={`/admin/creer-chapitre-e-learning/${learning?.id}`}>
                    <img src={Add} className="pe-1" /> Ajouter un chapitre
                  </Link>
                </div>
              </div>
            </div>
            <div className="form-elements">
              {chapitres?.map((chap, i) => {
                return (
                  <Accordion key={i}>
                    <Accordion.Item>
                      <Accordion.Header>
                        <span className="header-text">{chap?.titre}</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row g-3">
                          <p>
                            {chap?.unites.length} unités d'apprentissage
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElearningShow;
