import { ErrorMessage, Field, Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Hn from "../../../../components/common/Hn";
import AxioServices from "../../../../services/AxioServices";
import "./locationForm.css";


const countryList = require("../prestataire/data.json");
const validationSchema = Yup.object().shape({
  reference: Yup.string().required("Champ obligatoire."),
  nom: Yup.string().required("Champ obligatoire."),
  // capacity: Yup.string().required("Champ obligatoire."),
  // denomination: Yup.string().required("Champ obligatoire."),
  // adress: Yup.string().required("Champ obligatoire."),
  // code: Yup.number().required("Champ obligatoire."),
  // ville: Yup.string().required("Champ obligatoire."),
  // pays: Yup.string().required("Champ obligatoire."),
  // cout: Yup.number().required("Champ obligatoire."),
  // interlocuteur: Yup.string().required("Champ obligatoire."),
  // telephone: Yup.string().required("Champ obligatoire."),
  email: Yup.string()
  .email('Adresse email invalide.')
 // .required("Champ obligatoire."),
});

function LocationForm() {
  //let history = useHistory();
  const initialInputState = {
    reference: "",
    nom: "",
    capacity: "",
    denomination: "",
    adress: "",
    code: "",
    ville: "",
    pays: "",
    cout: "",
    interlocuteur: "",
    telephone: "",
    email: "",
    commentaire: "",
    note: ""
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  //const [pays, setPays] = useState("pays");
  const {
    reference,
    nom,
    capacity,
    denomination,
    adress,
    code,
    ville,
    pays,
    cout,
    interlocuteur,
    telephone,
    email,
    commentaire,
    note
  } = eachEntry;

  const handleChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const retour = (e) => {
    history.push("/admin/liste-lieu-formation/");
  };
  let history = useHistory();
//créer un nouveau lieu de formation
  const handleSubmit = (values) => {
    const datasend = {
      ref: values.reference,
      town: values.pays,
      address: values.adress,
      name: values.nom,
      capacity: parseInt(values.capacity),
      company_name: values.denomination,
      zip: values.code,
      country: values.ville,
      cost: values.cout,
      interlocutor_name: values.interlocuteur,
      tel: values.telephone,
      email: values.email,
      comment: values.commentaire,
      rating: values.rating
    };


    AxioServices.create("location/new", datasend).then(
      (result)=>{
        history.push("/admin/liste-lieu-formation/");
      }
    ).catch((err)=>{
      console.log(err);
    });

  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/liste-lieu-formation">Formations présentielles - Lieux de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ajouter un lieu de formation
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Ajouter un lieu de formation"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  nom: "",

                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="row g-3" autoComplete="new-password">
                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label">
                        Référence*
                      </label>
                      <input
                        type="text"
                        name="reference"
                        className="form-control"
                        id="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.reference}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Nom*
                      </label>
                      <input
                        type="text"
                        name="nom"
                        className="form-control"
                        id="init"
                        placeholder="Nom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nom}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="nom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="capac" className="form-label">
                        Capacité d'accueil
                      </label>
                      <input
                        type="number"
                        name="capacity"
                        className="form-control"
                        id="init"
                        placeholder="OO"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.capacity}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="capacity">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="denom" className="form-label">
                        Dénomination sociale
                      </label>
                      <input
                        type="text"
                        name="denomination"
                        className="form-control"
                        id="init"
                        placeholder="Dénomination sociale"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.denomination}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="denomination">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="addr" className="form-label">
                        Adresse
                      </label>
                      <input
                        type="text"
                        name="adress"
                        className="form-control"
                        id="init"
                        placeholder="Numéro et nom de voie"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.adress}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="adress">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="codep" className="form-label">
                        Code postal
                      </label>
                      <input
                        type="number"
                        name="code"
                        className="form-control"
                        id="assoc"
                        placeholder="00 000"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.code}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="code">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                        Ville
                      </label>
                      <input
                        type="text"
                        name="ville"
                        className="form-control"
                        id="init"
                        placeholder="Ville"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.ville}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="ville">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="pays" className="form-label">
                        Pays
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="pays"
                        id="pays"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pays}
                      >
                        <option>pays</option>
                        {Object.keys(countryList).map((element) => (
                          <option
                            key={element}
                            value={countryList[element].label}
                          >
                            {countryList[element].label}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage name="pays">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="coutj" className="form-label">
                        Coût par jour
                      </label>
                      <input
                        type="number"
                        name="cout"
                        className="cost form-control"
                        id="assoc"
                        placeholder="00"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.cout}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="cout">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="note" className="form-label">
                        Note sur 5
                      </label>
                      <input
                        type="number"
                        name="rating"
                        className="form-control"
                        id="rating"
                        placeholder="0"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.rating}
                        min={1}
                        max={5}
                      />
                      <ErrorMessage name="rating">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="interloc" className="form-label">
                        Nom de l'interlocuteur
                      </label>
                      <input
                        type="text"
                        name="interlocuteur"
                        className="form-control"
                        id="init"
                        placeholder="Nom de l'interlocuteur"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.interlocuteur}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="interlocuteur">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="phone" className="form-label">
                        Téléphone
                      </label>
                      <input
                        type="number"
                        name="telephone"
                        className="form-control"
                        id="init"
                        placeholder="00 00 00 00"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.telephone}
                        // style={{backgroundColor: "#3A4048"}}
                        autoComplete="new-password"
                      />
                      <ErrorMessage name="telephone">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        id="assoc"
                        placeholder="Email@gmail.com"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.email}
                        // style={{backgroundColor: "#3A4048"}}
                        autoComplete="new-password"
                        email
                      />
                       {errors.email && touched.email ?
                      (<div className="text-danger">{errors.email}</div>) : null}
                    </div>

                    <div className="col-12">
                      <label htmlFor="comm" className="form-label">
                        Commentaire
                      </label>
                      <textarea
                        className="form-control"
                        name="commentaire"
                        id="pre"
                        rows="3"
                        placeholder="Ajouter un commentaire"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.commentaire}
                        autoComplete="new-password"
                      ></textarea>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12 pt-5">
                      <button type="reset" className="btn btn-link resetBtn" onClick={retour}>
                        Annuler
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                        //disabled={isSubmitting}
                        //onClick={handleClick}
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default LocationForm;
