import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function HorizontalBarchart({ nbrInscrit, nbrParticipant }) {
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const labels = [
    "",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Participants",
        data: [nbrParticipant],
        // borderColor: "",
        backgroundColor: "#6B6DFF",
      },
      {
        label: "Inscrits",
        data: [nbrInscrit],
        // borderColor: "",
        backgroundColor: "#703afe",
      },
    ],
  };
  return <Bar options={options} data={data} style={{maxHeight: '250px'}} />;
}
