import Hn from "../../components/common/Hn";
import "../admin/forms/face-training/crud/createFaceTraining.css";
import "../admin/forms/location/ListLocationTraining.css";
import Datatable from "./Datatable";

function Listthematique() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn title="Thématiques" level="h1" className="pt-5" plus={false} />
          </div>
          <div className="mt-20 me-3">
              <Datatable />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Listthematique;
