import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../components/common/Hn";
import "./Login.css";

export default function Login() {
  const [accept, setAccept] = useState(false);
  const [agence, setAgence] = useState([]);
  let toastId = null;
  let history = createBrowserHistory({ forceRefresh: true });
  const legalUri = process.env.REACT_APP_LEGALS;

  if(!localStorage.getItem("id")){
    history.push('/login')
  }

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
//connexion que pour les comptes autorisés qui sont présentés dans le composant connexion selon chaque role et chaque leur redirection
  function checkcgv() {

    if (accept) {
      localStorage.setItem("authorise", "yes");
      //role
      if (localStorage.getItem("role") === "Admin") {
        history.push("/admin/apprenant");
      } else if (localStorage.getItem("role") === "Super-admin") {
        history.push("/admin/dashboard");
      } else {
        history.push("/Formations-e-learning");
      }

    } else {
      localStorage.setItem("authorise", "no");
      displayToast("Cerror", "Vous n'avez pas accepté les CGV, CGU");
    }
  }
  useEffect(() => {
    const result = localStorage.getItem("agence")?.split(",");
    result?.forEach(function (val, index, array) {
      array[index] = val;
    });

    setAgence(result); //est un useState pour afficher le nom de l'agence du user connecté qui s'affiche dans le popup loginProfil
  }, []);

  return (
    <div className="container-Bienvenue">
      <ToastContainer />
      <div className="row">
        <div className="card-body text-center py-5">
          <Hn title="Bienvenue" level="h1" />
          <Hn
            title={`${localStorage.getItem("nom")} ${localStorage.getItem("prenom")}`}
            level="h5"
            plus={false}
          />
          {agence?.map((val, i) => (
              <span className="nameAgence mb-3" key={i}>{val}</span>
            ))
          }
          <div className="form-check d-flex justify-content-center pt-3">
            <input
              className="form-check-input plus-right"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onChange={() => setAccept(!accept)} //accept est un state pour accepter les CGV( les conditions générales de vente ),CGU(les conditions générales d'utilisation)
              defaultChecked={accept}
            />
            <label
              className="form-check-label "
              htmlFor="flexCheckDefault"
              style={{ color: "white" }}
            >
              J'accepte les CGV, les CGU et le réglement intérieur de la plateforme
            </label>
          </div>

          <div className="pt-3">
            <button className="btn btn-primary" onClick={checkcgv}>
              Accéder à la plateforme
            </button>
          </div>
        </div>
        <div className="banner-info text-center pt-3">
          <ul className="list-inline ps-0">
            <li className="list-inline-item">
              <a href={`${legalUri}reglement-interieur-2022-pdf.pdf`} style={{ color: "gray" }} target="_blank" rel="noreferrer">
                Règlement intérieur
              </a>
            </li>
            <li className="list-inline-item">
              <a href={`${legalUri}cgv-formations-2022.pdf`} style={{ color: "gray" }} target="_blank" rel="noreferrer">
                CGV
              </a>
            </li>
            <li className="list-inline-item">
              <a href={`${legalUri}cgu.pdf`} style={{ color: "gray" }} target="_blank" rel="noreferrer">
                CGU
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
