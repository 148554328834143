import moment from "moment";

export const durationFormat = (duration) => {
  let diff = moment.duration(duration, 'seconds');
  let format = (diff.days() > 0 ? 'D[j] ' : '')
    + (diff.hours() > 0 ? 'HH[h] ' : '')
    + 'mm[m] ss[s]';

  return moment.utc(diff.asMilliseconds()).format(format);
}
