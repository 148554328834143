import axios from "axios";

import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hn from "../../../../components/common/Hn";
import "../face-training/crud/createFaceTraining.css";
import "../location/ListLocationTraining.css";
import ListFormerTrainingDataGrid from "./ListFormerTrainingDataGrid";

function ListFormer() {
  return (
    <div className="container-fluid"><ToastContainer />
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="formateurs" level="h2" className="pt-4" />
          </div>
          {/* lists-filter */}
          <div className="mt-20">
              <ListFormerTrainingDataGrid />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListFormer;
