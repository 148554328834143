import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Hn from "../../components/common/Hn";
import "./webinar.css";
import Option from "../../assets/icons/options.svg";
import edit from "../../assets/icons/edit.png";
import Play from "../../assets/icons/Play.png";
import DeleteUniteDeFormation from "../admin/forms/e-learning/chapitre/DeleteUniteDeFormation";
import Delete from "../../assets/icons/delete.png";
import Add from "../../assets/icons/add.png";
import DeleteChapitre from "../admin/forms/e-learning/chapitre/DeleteChapitre";
import EditChapitre from "../admin/forms/e-learning/chapitre/EditChapitre";
import EditUniteDeFormation from "../admin/forms/e-learning/chapitre/EditUniteDeFormation";
import { height } from "@mui/system";

const validationSchema = Yup.object().shape({
  titre: Yup.string().required("Le titre est obligatoire"),
});

function CreateWebinair3() {
  const [chapitres, setChapitres] = useState([]);
  const fileRef = useRef();
  const onHandelStatus = (touched, error, value) => {
    if (error && touched) {
      return "danger";
    } else if (value) {
      return "info";
    } else {
      return "warning";
    }
  };
  const { elearning_id } = useParams();
  const uri = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getChapitres();
  }, []);
//récupérer les chapitres d'une formation elearning
  function getChapitres() {
    trackPromise(
      axios
        .get(uri + "/elearning/" + elearning_id + "/chapitres")
        .then((result) => {
          setChapitres(result?.data);

        })
        .catch((err) => {
          console.log("err sessions", err);
        })
    );
  }

  let history = useHistory();
//créer un nouveau chapitre
  const handleSubmit = (values) => {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    var data = new FormData();
    data.append("titre", values.titre);
    data.append("elearning", elearning_id);

    trackPromise(
      axios
        .post(uri + "/chapitre/new", data, headersFiles)
        .then((result) => {
          history.push("/admin/create-unite-de-formation/" + result?.data?.id);
        })
        .catch((err) => {
          console.log("err training", err);
        })
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Webinar</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer un webinar
              </li>
            </ol>
          </nav>
          <div className=" p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer un webinar"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title="Etape 2 : Editeur"
                  level="p"
                  className="py-2 fw-bold"
                  plus={false}
                />
              </div>
              <div className="col-md-6 ">
                <div className="float-end">
                  <img src={Add} className="pe-1" />{" "}
                  <a href="">Ajouter un chapitre</a>
                </div>
              </div>
            </div>

            <div className="form-elements mt-2">
              <div className="form-aria p-4">
                <Formik
                  initialValues={{
                    titre: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form className="row g-3" onSubmit={handleSubmit}>
                      <Hn
                        title="Intitulé*"
                        level="p"
                        className="py-2 fw-bold"
                        plus={false}
                      />
                      <div className="col-md-12">
                        <input
                          status={onHandelStatus(
                            touched.ref,
                            errors.ref,
                            values.ref
                          )}
                          type="text"
                          className="form-control"
                          id="titre"
                          name="titre"
                          placeholder="Intitulé"
                          onChange={handleChange("titre")}
                          value={values.titre}
                          onBlur={handleBlur("titre")}
                          errors={errors.titre}
                        />
                        <ErrorMessage name="titre">
                          {(msg) => <div className="text-danger">{msg}</div>}
                        </ErrorMessage>
                        <div className="mt-4 p-5 text-white bg-dark rounded-3">
                          <div>
                            <input
                              status=""
                              type="text"
                              className="form-control dashed p-3"
                              id="titre"
                              name="titre"
                              placeholder=""
                              value=""
                            />
                          </div>
                          <div style={{ height: "150px" }}>
                            <textarea className=" form-control dashed p-3 mt-3"></textarea>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="form-elements mt-2">
              <div className="form-aria p-4">
                <Hn
                  title="Ajouter un document"
                  level="p"
                  className="py-2 fw-bold"
                  plus={false}
                />
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">Titre</label>
                    <input
                      status=""
                      type="text"
                      className="form-control"
                      id="titre"
                      placeholder="Titre du document"
                      value=""
                    />
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">Document</label>
                    <span
                      className="form-control"
                      onClick={() => fileRef.current.click()}
                    >
                      <img src={Add} className="pe-1" />{" "}
                      {fileRef?.current?.value
                        ? fileRef?.current?.value
                        : "Ajouter un fichier"}
                    </span>

                    <input
                      //ref={fileRef}
                      //multiple={false}
                      type="file"
                      name="image"
                      onChange
                      hidden
                      accept=".png,.jpg,.gif"
                    />
                  </div>

                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <p> Format : pdf ,jpg, png / 2ko </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-10 mt-5">
                  <a type="submit" className=" btn-link resetBtn" href="/">
                    Retour{" "}
                  </a>
                </div>

                <div className="col-2 mt-5">
                  <a
                    href={"/admin/add-skills-elearning/" + elearning_id}
                    className="btn btn-primary float-end"
                  >
                    Suivant
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateWebinair3;
