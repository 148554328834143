import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CadreItems from "../../components/common/CadreItems";
import Hn from "../../components/common/Hn";
import "../../components/owl/listformation.css";

import axios from "axios";

const ListMore = () => {
  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const IMG_URL_E = process.env.REACT_APP_PIC_ELEARNING_IMG;
  let params = useParams();
  const [training, setTraining] = useState([]);
  const [elearning, setElearning] = useState([]);
  const [encours, setEncours] = useState([]);
  const [title, setTitle] = useState('');
  const uri = process.env.REACT_APP_BASE_URL;

//récupérer les formations présentielles par théme
  function getTraining() {
    axios
      .get(uri + "/training/theme/" + params.theme)
      .then((result) => {
        setTraining(result.data);
        // On va faire une pirouette pour récupérer notre Titre
        if(result.data.length > 0){
          setTitle(result.data[0].theme.name)
        }
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }

  //récupérer les formations elearning par théme
  function getElearning() {
    axios
      .get(uri + "/training/elearning/theme/" + params.theme)
      .then((result) => {
        setElearning(result.data);
        // On va faire une pirouette pour récupérer notre Titre
        if(result.data.length > 0){
          setTitle(result.data[0].theme.name)
        }
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }

  //récupérer les formations elearning en cours
  function getEncours() {
    axios
      .get(uri + "/elearning/get_formations_en_cours/" + localStorage.getItem("email"))
      .then((result) => {
        setEncours(result?.data.filter((el) => el?.actif === true));
        setTitle('Formations en cours')
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }

  useEffect(() => {
    if (params.category === "training") {
      getTraining();
    } else if (params.category === "elearning") {
      getElearning();
    } else if (params.category === "encours") {
      getEncours();
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <nav aria-label="breadcrumb pb-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              {params.category === "training" && (
                <Link to="/formation-presentielle">
                  Catalogue de formations
                </Link>
              )}
              {(params.category === "elearning" || params.category === 'encours') && (
                <Link to="/Formations-e-learning">
                  Formations e-learning
                </Link>
              )}
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {title}
            </li>
          </ol>
        </nav>
        <Hn title={title} level="h4" className="mb-3 searchTitle" />

        {training && training.length > 0 && (
          <>
            <div className={`titreE pt-4`}>
              <p>Formations présentielles</p>
            </div>

            <div className="row">
              {training?.map((item, index) => (
                <div className="col-3" key={item.id}>
                  <CadreItems
                    title={item?.title || item?.intitule}
                    id={item?.id}
                    base_url={IMG_URL}
                    details={"detailformation"}
                    image={item?.image}
                    index={index}
                    switchs
                    key={index}
                    isflip={false}
                    isElearning={false}
                  />
                </div>
              ))}
            </div>
          </>
        )}

        {elearning && elearning.length > 0 &&  (
          <>
            <div className={`titreE pt-4`}>
                <p>Formations E-learning</p>
            </div>

            <div className="row">
              {elearning?.map((item, index) => {
                return (
                  <div className="col-3" key={item.id}>
                    <CadreItems
                      title={item?.title || item?.intitule}
                      id={item?.id}
                      base_url={IMG_URL_E}
                      details={"detailformations"}
                      image={item?.image}
                      index={index}
                      key={index}
                      switchs
                      elearning={localStorage.getItem('elearning')}
                      isflip={false}
                      isElearning={true}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}

        {encours && encours.length > 0 &&  (
          <>
            <div className={`titreE pt-4`}>
              <p>Formations E-learning en cours</p>
            </div>

            <div className="row">
              {encours?.map((item, index) => {
                return (
                  <div className="col-3" key={item.id}>
                    <CadreItems
                      title={item?.title || item?.intitule}
                      id={item?.id}
                      base_url={IMG_URL_E}
                      details={"detailformations"}
                      image={item?.image}
                      index={index}
                      key={index}
                      switchs
                      elearning={localStorage.getItem('elearning')}
                      isflip={false}
                      isElearning={true}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default ListMore;
