import axios from "axios";
import { useState, useEffect } from "react";
import range from "lodash.range";
import Papa from "papaparse";
import { toast, ToastContainer } from 'react-toastify';
import PopupFiltre from "../../../../components/popup/PopupFiltreParticipant";
import AxioServices from "../../../../services/AxioServices";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export default function Datatable({
  total,
  from
}) {
  let history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearch, setCurrentSearch] = useState('');
  const [participants, setParticipants] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);
  const [pages, setPages] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const uri = process.env.REACT_APP_BASE_URL;
  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';
  let toastId = null;

  useEffect(() => {
    getPage(1);
  }, []);

  useEffect(() => {
    if (searchQuery != null) {
      const timeOutId = setTimeout(() => searchParticipant(searchQuery), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchQuery]);

  useEffect(() => {
    setPages(fetchPageNumbers)
  }, [totalResults]);

  const displayToast = (type, msg) => {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      console.log("Toast already active");
    }
  }

  const handleCloseFilter = () => {
    setShowfiltre(false);
  };

  const handleShowFilter = () => {
    setShowfiltre(true);
  };

  const handleOnChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {

        AxioServices.create("participant/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };

  const fetchPageNumbers = () => {
    if(totalResults == null || totalResults === 0 || totalResults < 50){
      return [1];
    }

    const totalPages = Math.ceil(totalResults / 50);
    // const currentPage = currentPage;
    const pageNeighbours = 3;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = (pageNeighbours * 2);
    const totalBlocks = totalNumbers;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);

      //totalPages delete -1
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);
      let pages = range(startPage, endPage  );

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [1, ...pages, totalPages];
    }

    return range(1, (totalPages + 1));
  }

  const getPage = (item) => {
    axios
      .get(`${uri}/participant/pagination/?page=${item}&search=${currentSearch}`)
      .then((result) => {
        setParticipants(result?.data?.data);
        setTotalResults(result?.data?.count);
      })
      .catch((err) => {
        setParticipants([]);
        setTotalResults(0);
        console.log("err participants", err);
      });
  }

  function searchParticipant(value) {
    setCurrentSearch(value);
    let req = `${uri}/participant/pagination/?search=${value}`;
    axios
      .get(req)
      .then((result) => {
        if (result?.data.count > 0) {
          setCurrentPage(1)
          setParticipants(result?.data.data);
          setTotalResults(result?.data.count);
        } else {
          setCurrentPage(1)
          setParticipants([])
          setTotalResults(0);
        }
      })
      .catch((err) => {
        setCurrentPage(1)
        setParticipants([])
        setTotalResults(0);
      });
  }

  function searchWithFilter(val) {
    setParticipants([])
    setEachEntry(val);
    if (val.collaborator !== "" || val.email !== "" || val.emailAgent !== "" || val.email !== "") {
      let query = `collaborator=${val.collaborator}&agent=${val.agent}&offre=${val.offre}&manager=${val.agence}`;
      let req = uri + "/participant/filter?" + query;

      axios
        .get(req)
        .then((result) => {

          if (result?.data.length > 0) {
            setParticipants(result?.data);
          } else {
            setParticipants([])
          }
        })
        .catch((err) => {
          console.log("err filter", err);
        });
    }
  }

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/admin/apprenant/${row.ContactID}/${from || ''}`);
    },
  }

  const columns = [
    {
      dataField: "apprenant",
      text: "Collaborateur",
      csvText: "collaborator",
      sort: true,
    },
    {
      dataField: "mailcontact",
      text: "Email",
      csvText: "email",
      sort: true,
      width: 50,
      className: "cellTable"
    },
    {
      dataField: "agencymain",
      text: "Agent",
      csvText: "agent",
      sort: true,
    },
    {
      dataField: "agenceEmail",
      text: "Email Agent",
      csvText: "emailAgent",
      sort: true,
    },
    {
      dataField: "offre",
      text: "Offre",
      csvText: "offre",
      sort: true,
    },
    {
      dataField: "metier",
      classes:"text-center",
      text: "Manager",
      csvText: "manager",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{row.metier === "Manager" ? "Oui" : "Non"}</span>;
      },
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  return (
    <ToolkitProvider
      keyField="id"
      data={participants}
      columns={columns}
      exportCSV={{ onlyExportSelection: true, exportAll: false }}
      search
    >
      {(props) => (
        <div>
          <MyExportCSV {...props.csvProps} />
          <div className="lists-filter my-5"><ToastContainer />
            <div className="row pe-3">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={(e => setSearchQuery(e.target.value))}
                      />
                      {/* <span className="input-group-append">
                      <button className="btn btn-outline-dark height43" type="button">
                        <img
                          src={
                            require("../../../../assets/icons/Search.svg")
                              .default
                          }
                        />
                      </button>
                    </span> */}
                    </div>
                  </div>
                  <div className="px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button type="button"
                          className="btn btn-link"
                          onClick={handleShowFilter}
                        >
                          <img
                            src={require("../../../../assets/icons/filter.png")}
                          />
                        </button>
                      </div>
                      <PopupFiltre
                        showfiltre={showfiltre}
                        closefiltre={handleCloseFilter}
                        searchwithfiltre={searchWithFilter}
                        filterEntry={eachEntry}
                      />
                      <div className="square">
                        <button type="button" className="btn btn-link" onClick={() => props.csvProps.onExport()}>
                          <img src={require("../../../../assets/icons/Export.png")} />
                        </button>
                      </div>
                      <div className="square">
                        <input
                          type="file"
                          name="file"
                          id="filex"
                          accept={".csv"}
                          className="btn btn-link"
                          onChange={handleOnChange}
                          hidden
                        />
                        <label htmlFor="filex" type="button">
                          <img src={require("../../../../assets/icons/Import.png")} />
                        </label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            classes="table-dark "
            bordered={false}
            hover
            rowEvents={tableRowEvents}
          />
          <div className="row react-bootstrap-table-pagination">
            <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"></div>
            <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
              <ul className="pagination react-bootstrap-table-page-btns-ul ps-0">
                {pages.map((page, index) => {
                  if (page === LEFT_PAGE || page === RIGHT_PAGE) return (
                    <li key={index} className="page-item">
                      <span className="page-link">
                        <span className="sr-only">...</span>
                      </span>
                    </li>
                  );

                  return (
                    <li className={currentPage === page ? "active page-item" : " page-item"} title="1" key={index}>
                      <span className="page-link" onClick={() => {setCurrentPage(page); getPage(page);}}>
                        {page}
                      </span>
                    </li>
                  )
                }
                )}
              </ul>
            </div>
          </div>
          </div>
        )}
      </ToolkitProvider>
  );
}
