import axios from "axios";
import { ErrorMessage, Formik, getIn } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import Hn from "../../../../components/common/Hn";
import "./provider.css";
const countryList = require("./data.json");

const validationSchema = Yup.object().shape({
  nom: Yup.string().required("champ obligatoire"),
});

function CreatePrestataire() {
  const params = useParams();

  const [themes, setThemes] = useState([]);
  const [options, setOptions] = useState([]);
  const [provider, setProviderDetails] = useState([]);
  const [displayexiste, setDisplayexiste] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const uri = process.env.REACT_APP_BASE_URL;

  const customStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: "16px",
      fontWeight: state.isSelected ? "600" : "400",
      backgroundColor: "#3A4048",
      color: "white",
      border: "1px solid #006FFF",
      borderRadius: "none",
    }),
    dropdownIndicator: (base) => ({
      color: "#006FFF",
      marginRight: "10px",
    }),
    menu: (base) => ({
      backgroundColor: "#212730",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#1E2E45" : "#212730",
      fontWeight: state.isSelected ? "600" : "400",
      borderBottom: "1px solid #3A4048",
      color: "white",
      margin: "5px 14px 5px 10px",
    }),
    multiValue: (base, state) => ({
      ...base,
      color: "white",
      backgroundColor: "#1E2E45",
      fontWeight: state.isSelected ? "600" : "400",
      margin: "6px 10px 5px 10px",
      padding: "5px 14px 5px 10px",
    }),
    multiValueLabel: (base) => ({
      color: "white",
    }),
    multiValueRemove: (base) => ({
      color: "#006FFF",
      fontSize: "16px",
      height: "20px",
      width: "20px",
    }),
  };
  let history = useHistory();
  //récupérer un prestataire par son id
  function getProviderById(id) {
    axios
      .get(uri + "/provider/" + id, headers)
      .then((response) => {
        setProviderDetails(response.data);
        response.data.topic_interv.map((theme) => {
          setSelectedOption((selectedOption) => [
            ...selectedOption,
            { value: theme.id, label: theme.name },
          ]);
        });
      })
      .catch((error) => {
        // TODO 404 page
        console.log(error.response.data);

        return error.response;
      });
  }

  //modifier un prestataire choisi
  const handleSubmit = (values) => {
    const topics = [];
    selectedOption.map((op) => {
      topics.push(op.value);
    });


    let myProvider = {
      ref: values.ref,
      name: values.nom,
      topic_interv: topics,
      address: values.adresse,
      zip: values.codeP,
      city: values.ville,
      country: values.pays,
      website: values.site,
      contact_name: values.nom_contact,
      contact_surname: values.prenom_contact,
      tel: values.tel_contact,
      email: values.email_contact,
      comment: values.commentraire_contact,
    };

    axios
      .put(`${uri}/provider/edit/${params.id}`, myProvider, headers)
      .then((result) => {

        history.push("/admin/prestataires/");
      })
      .catch((err) => {
        console.log("err bd", err);
        console.log("before", displayexiste);
        if (err["response"]["data"] == "ref_exist") {
          setDisplayexiste(true);
        }
      });
  };
//récupérer tous les thémes
  function getThem() {
    axios
      .get(uri + "/thematic", headers)
      .then((result) => {
        result?.data.map((theme) => {
          setOptions((options) => [
            ...options,
            { value: theme.id, label: theme.name },
          ]);
        });
      })
      .catch((err) => {
        console.log("err theme", err);
      });
  }
  function getStyles(errors, fieldName) {
    if (getIn(errors, fieldName)) {
      return {
        border: "1px solid red",
      };
    }
  }

  useEffect(() => {
    getProviderById(params.id);
    getThem();
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/prestataires">
                  Formations présentielles - Prestataires
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Modifier un prestataire
              </li>
            </ol>
          </nav>
          <div className=" p-5 text-white bg-dark rounded-3 me-3">
            <Hn
              title="Modifier un prestataire"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  ref: provider?.ref,
                  nom: provider?.name,
                  theme: "",
                  adresse: provider?.address,
                  codeP: provider?.zip,
                  ville: provider?.city,
                  pays: provider?.country,
                  site: provider?.website,
                  nom_contact: provider?.contact_name,
                  prenom_contact: provider?.contact_surname,
                  tel_contact: provider?.tel,
                  email_contact: provider?.email,
                  commentraire_contact: provider?.comment,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="row g-3">
                    <div className="col-md-4">
                      <label htmlhtmlFor="init" className="form-label">
                        Réference
                      </label>
                      <input
                        type="text"
                        name="ref"
                        className="form-control"
                        style={{ color: "#808080", fontWeight: "600" }}
                        id="init"
                        placeholder="Référence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={provider?.ref}
                        disabled
                      />
                      {displayexiste && (
                        <div className="text-danger ref_exist">
                          Référence existe déjà
                        </div>
                      )}
                      <ErrorMessage name="ref">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlhtmlFor="init" className="form-label">
                        Nom*
                      </label>
                      <input
                        type="text"
                        name="nom"
                        className="form-control"
                        id="init"
                        placeholder="Nom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={provider?.name}
                        value={values.nom}
                      />
                      <ErrorMessage name="nom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4"></div>

                    <div className="col-md-8">
                      <label htmlhtmlFor="init" className="form-label">
                        Thèmes d’interventions
                      </label>
                      <Select
                        value={selectedOption}
                        //defaultInputValue={selectedOption}
                        onChange={setSelectedOption}
                        styles={customStyles}
                        options={options}
                        isMulti={true}
                        className=""
                        noOptionsMessage={() => 'Aucun résultat'}
                      />
                      <ErrorMessage name="themes">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlhtmlFor="image" className="form-label">
                        Adresse
                      </label>
                      <input
                        type="text"
                        name="adresse"
                        className="form-control"
                        id="init"
                        placeholder="Numéro et nom de voie"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        defaultValue={provider?.address}
                        value={values.adresse}
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>
                    <div className="col-md-4">
                      <label htmlhtmlFor="init" className="form-label">
                        Code postal
                      </label>
                      <input
                        type="number"
                        name="codeP"
                        className="form-control"
                        id="init"
                        placeholder="00 000"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        defaultValue={provider?.zip}
                        value={values.codeP}
                      />
                      <ErrorMessage name="codeP">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlhtmlFor="ville" className="form-label">
                        Ville
                      </label>
                      <input
                        type="text"
                        name="ville"
                        className="form-control"
                        id="ville"
                        placeholder="Ville"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        defaultValue={provider?.city}
                        value={values.ville}
                      />
                      <ErrorMessage name="ville">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlhtmlFor="actif" className="form-label">
                        Pays
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="pays"
                        id="pays"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={provider?.country}
                        value={values.pays}
                      >
                        <option>pays</option>
                        {Object.keys(countryList).map((element) => (
                          <option
                            key={element}
                            value={countryList[element].label}
                          >
                            {countryList[element].label}
                          </option>
                        ))}
                      </select>

                      <ErrorMessage name="pays">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlhtmlFor="site" className="form-label">
                        Site internet
                      </label>
                      <input
                        type="text"
                        name="site"
                        className="form-control"
                        id="site"
                        placeholder="Site Internet"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        defaultValue={provider?.website}
                        value={values.site}
                      />
                      <ErrorMessage name="site">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlhtmlFor="nom_contact" className="form-label">
                        Nom du contact
                      </label>
                      <input
                        type="text"
                        name="nom_contact"
                        className="form-control"
                        id="nom_contact"
                        placeholder="Nom"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        defaultValue={provider?.contact_name}
                        value={values.nom_contact}
                      />
                      <ErrorMessage name="nom_contact">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label
                        htmlhtmlFor="prenom_contact"
                        className="form-label"
                      >
                        Prénom du contact
                      </label>
                      <input
                        type="text"
                        name="prenom_contact"
                        className="form-control"
                        id="nom_contact"
                        placeholder="Prénom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={provider?.contact_surname}
                        value={values.prenom_contact}
                      />
                      <ErrorMessage name="prenom_contact">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlhtmlFor="tel_contact" className="form-label">
                        Téléphone du contact
                      </label>
                      <input
                        type="number"
                        name="tel_contact"
                        className="form-control"
                        id="tel_contact"
                        placeholder="06 00 00 000"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={provider?.tel}
                        value={values.tel_contact}
                      />
                      <ErrorMessage name="tel_contact">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlhtmlFor="email_contact" className="form-label">
                        Email du contact
                      </label>
                      <input
                        type="email"
                        name="email_contact"
                        className="form-control"
                        id="email_contact"
                        placeholder="Email@email.fr"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={provider?.email}
                        value={values.email_contact}
                      />
                      <ErrorMessage name="email_contact">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-12">
                      <label
                        htmlhtmlFor="commentraire_contact"
                        className="form-label"
                      >
                        Commentaire
                      </label>
                      <textarea
                        className="form-control"
                        name="commentraire_contact"
                        id="pre"
                        rows="3"
                        placeholder="Ajouter un commentaire"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={provider?.comment}
                        value={values.commentaire}
                      ></textarea>
                      <ErrorMessage name="commentraire_contact">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12 pt-5">
                      <a href="/admin/prestataires" type="reset" className="btn btn-link resetBtn">
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                        //disabled={isSubmitting}
                        //onClick={handleClick}
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default CreatePrestataire;
