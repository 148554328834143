// import Hn from "../../../../../../components/common/Hn";
import ParticpantDatatable from "./DatatableParticipants";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
function Addparticipants() {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };
  const params = useParams();
  const uri = process.env.REACT_APP_BASE_URL;
  const [participant, setParticipant] = useState([]);
//récupérer les participants
  function getParticipant() {
    setParticipant([]);
    axios
      .get(uri + "/participant", headers)
      .then((result) => {

        setParticipant(result?.data);
      })
      .catch((err) => {
        console.log("err participant", err);
      });
  }
  useEffect(() => {
    getParticipant();
  }, []);

  return (
    <div className="container-fluid">

      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href={"/admin/session/show/" + params.id}>
                  Webinaires/{params.id}
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Modifier List Participants
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">

            <ParticpantDatatable
              participant={participant}
              idWeb={params.id}
            ></ParticpantDatatable>
            <a
              href={"/detailWebinar/" + params.id}
              className="btn btn-primary float-right">
              valider
            </a>
          </div>


        </div>
      </div>
    </div>
  );
}
export default Addparticipants;
