import axios from "axios";
import { useEffect, useState } from "react";
import Barchart from "../../components/chartjs/Barchart";
import { HorizontalBarchart } from "../../components/chartjs/HorizontalBarchart";
import { Bar, Doughnut } from "react-chartjs-2";
import DateRange from "../../components/common/DateRange";
import Hn from "../../components/common/Hn";
import ProgressBar from "../../components/progressBar/ProgressBar";
import { CSVLink } from "react-csv";
import { trackPromise } from 'react-promise-tracker';
import toast, { Toaster } from 'react-hot-toast';
import dateFormat from "dateformat";
import "./dashboard.css";
function DashboardAdmin() {
  const [partheme, setPartheme] = useState([]);

  const [sector, setSector] = useState([]);
  const [themestats, setThemeStats] = useState([]);
  const [partheme_data, setPartheme_data] = useState([]);
  const [parvision, setParvision] = useState([]);
  const [parvision_data, setParvision_data] = useState([]);
  const [stats, setStats] = useState([]);
  const [elearningStats, setElearningStats] = useState([]);
  const [elearningData, setElearningData] = useState([]);
  const [nbrInscrit, setNbrInscrit] = useState([]);
  const [nbrParticipant, setNbrParticipant] = useState([]);
  const [parsecteur, setParsecteur] = useState([]);
  const [autocomplete, setAutocomplete] = useState("false");
  const [sector_agence, setSector_agencer] = useState("sector");
  const [parsecteur_data, setParsecteur_data] = useState([]);
  const [listAgence, setListAgence] = useState([]);
  const [inscrit, setInscrit] = useState([]);
  const [trained, setTrained] = useState([]);
  const [valuedatarange, setValuedatarange] = useState(['', '']);

  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const uri = process.env.REACT_APP_BASE_URL;
//convert est une fonction pour convertir le valuedatarange en dateform(value, "yyyy-mm-dd")
  const convert = (values, index) => {
    if(values?.length !== 0 && values != null){
      return dateFormat(values[index], 'yyyy-mm-dd');
    }else{
      return '';
    }
  }
   //récupérer les statistiques des toutes les formations elearning pour afficher le nombre de connexion et le temps global passé sur la plateforme
  function getElearninStats() {

    trackPromise(
      axios
        .get(`${uri}/elearning/get_all_elearning_data`)
        .then((result) => {
          setElearningStats(result?.data?.stats);
          if(typeof(result?.data?.data) != 'undefined'){
            setElearningData(result?.data?.data);
          }
        })
        .catch((err) => {
          toast.error("Désolé une erreur s'est produite");
        }));
  }
//récupérer le nombre d'action de formation présentielle et les thémes associés à chaque action
  function parthematique() {
    axios
      .get(uri + `/dashboard/theme?date-s=${convert(valuedatarange, 0)}&date-e=${convert(valuedatarange, 1)}`, headers)
      .then((result) => {

        const lb = result?.data?.map(function (item, i) {
          return item.name;
        });
        setPartheme(lb); //récupérer les noms des thémes qui ont des formations présentielles

        const ln = result?.data?.map(function (item, i) {
          return item.nbrFormation;
        });

        setPartheme_data(ln);//récupérer le nombre des formations présentielless de chaque théme

        setThemeStats(result?.data)//récupérer le nom des thémes avec leur nombre de formation et nombre de participants et la date de création


      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
 //récupérer toutes les visions de toutes les formations présentielles et leur états
  function getvision() {
    axios
      .get(uri + `/dashboard/global?date-s=${convert(valuedatarange, 0)}&date-e=${convert(valuedatarange, 1)}`, headers)
      .then((result) => {
        setParvision(["Dispensées", "Annulées", "Danger"]); //setParvision return un array qui contient ces trois champs
        setParvision_data([
          result?.data.dispense,
          result?.data.annule,
          result?.data.danger,
        ]);

        setStats(result?.data.stat);
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
//récupérer toutes les actions de formation présentielle avec leur nombre des participants et leur type(agence ou Secteur)
  function getparsecteur(val) {
    axios
      .get(uri + `/dashboard/sector?par=${val}&date-s=${convert(valuedatarange, 0)}&date-e=${convert(valuedatarange, 1)}`, headers)
      .then((result) => {
        const final = result.data;
        const ln = final?.data?.map(function (item, i) {
          return item.name || item.sector;
        });
        setParsecteur(ln);
        const lv = final?.data.map(function (item, i) {
          return item.nbrSector;
        });
        setParsecteur_data(lv);
        setSector(final?.stats);

        if (val === "agence") {
          setListAgence(final?.data);
          setAutocomplete("true");
        } else {
          setAutocomplete("false");
        }
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
//récupérer le nombre de tous les participants et le nombre des non inscrits dans les formations présentielles
  function getpresence() {
    axios
      .get(uri + `/dashboard/participant?date-s=${convert(valuedatarange, 0)}&date-e=${convert(valuedatarange, 1)}`, headers)
      .then((result) => {
        // setParvision(["dispense", "Annulé", "Danger"]);
        // setParvision_data([result?.data.dispense, result?.data.annule, result?.data.danger]);

        setNbrInscrit(result.data?.nbrInscrit);
        setNbrParticipant(result.data?.nbrParticipant);
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
//récupérer la liste des participants inscrits et qui sont formés
  function getinscrit() {
    axios
      .get(uri + `/dashboard/trained?date-s=${convert(valuedatarange, 0)}&date-e=${convert(valuedatarange, 1)}`, headers)
      .then((result) => {
        setInscrit(result.data);
        setTrained(result?.data?.stat);
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }

  const headersvision = [
    { label: "intitulé", key: "title" },
    { label: "Date de début ", key: "dateStart" },
    { label: "Date de fin ", key: "dateEnd" },
    { label: "Durée", key: "duration" },
    { label: "formateur ", key: "former" },
    { label: "Secteur", key: "sector" },
    { label: "Nombre de participants ", key: "nbr_participant" },
    { label: "Etat", key: "etat" }
  ];
  const headerstheme = [
    { label: "nom", key: "name" },
    { label: "Nombre d'actions de formation ", key: "nbrFormation" },
    { label: "Nombre de participants ", key: "nbrParticipants" },

  ];
  const headerssector = [
    { label: "intitulé", key: "title" },
    { label: "Nombre des participants ", key: "nbrParticipant" },
    { label: "type", key: "type" },

  ];
  const headerstrained = [
    { label: "Nom et prénom du participant", key: "fullName" },
    { label: "Date d'entrée ", key: "dateenter" },
    { label: "Statut", key: "status" },
  ];

  const headersElearningData = [
    { label: "Nom et prénom du participant", key: "nom" },
    { label: "Date d'entrée ", key: "date_entree" },
    { label: "Secteur", key: "secteur" },
    { label: "Agences", key: "agences" },
    { label: "Manager", key: "manager" },
    { label: "Unité de formation", key: "intitule" },
    { label: "Statut", key: "status" },
  ];

  const headerNbParticipants = [
    {label: "Nombre des participants", key: "nbrParticipant"},
  ];



  useEffect(() => {
    parthematique();
    getElearninStats();
    getvision();
    getpresence();
    getparsecteur(sector_agence);
    getinscrit();

  }, [valuedatarange]);

  const data = {
    datasets: [
      {
        label: "# of Votes",
        data: [inscrit?.nbrNotTrained, inscrit?.nbrTrained],
        backgroundColor: ["#FFFFFF", "#3EA279"],
        borderColor: ["#FFFFFF", "#3EA279"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists pe-4">
          <div className="lists-title">
            <Hn title="DASHBOARD" level="h2" className="pt-5" plus={false} />
          </div>
          <div className="row">
            <div className="col-md-9">
              <Hn
                title="Formation présentielles"
                level="h3"
                className="pt-5"
                plus={true}
              />
            </div>
            <div className="col-md-3 pt-3">
              <DateRange valuedatarange={valuedatarange} setValuedatarange={setValuedatarange}/>
            </div>

            <div className="col-md-6 pt-3">
              <div className="blocD">
                <p className="title-bloc">Montant total facturés</p>
                <p className="txt-bloc">3 245 € HT</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 pt-3">
              <div className="blocD">
                <div className="flex space-between">
                  <Hn
                    title="Vison globale"
                    level="h4"
                    className=""
                    plus={false}
                  />
                  <div className="square">
                    <CSVLink data={stats} headers={headersvision} filename={"visionGlobale.csv"}>
                      <button type="button" className="btn btn-link">
                        <img
                          src={require("../../assets/icons/exportDashboard.png")}
                        />
                      </button>
                    </CSVLink>

                  </div>
                </div>
                {parvision?.length > 0 && parvision_data?.length > 0 ? (
                  <Barchart
                    title={"Sessions"}
                    color={"#703AFE"}
                    label={parvision}
                    stats={parvision_data}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-6 pt-3">
              <div className="blocD">
                <div className="flex space-between">
                  <Hn
                    title="Par thématique"
                    level="h4"
                    className=""
                    plus={false}
                  />
                  <div className="square">

                    <CSVLink data={themestats} headers={headerstheme} filename={"thématique.csv"}>
                      <button type="button" className="btn btn-link">
                        <img
                          src={require("../../assets/icons/exportDashboard.png")}
                        />
                      </button>
                    </CSVLink>
                  </div>
                </div>
                {partheme?.length > 0 && partheme_data?.length > 0 ? (
                  <Barchart
                    title={"Formation"}
                    color={"#9500FF"}
                    label={partheme}
                    stats={partheme_data}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-12 pt-3">
              <div className="blocD">
                <div className="flex space-between">
                  <Hn
                    title="Par secteur/agence"
                    level="h4"
                    className=""
                    plus={false}
                  />

                  <div className="flex">
                    <select
                      className="form-select"
                      onChange={(e) => {
                        getparsecteur(e.target.value);
                        setSector_agencer(e.target.value);
                      }}
                      value={sector_agence}
                    >
                      <option value="sector">Par Secteur</option>
                      <option value="agence">Par Agence</option>
                    </select>

                    <div className="square">

                      <CSVLink data={sector} headers={headerssector} filename={"SecteurAgence.csv"}>
                        <button type="button" className="btn btn-link">
                          <img
                            src={require("../../assets/icons/exportDashboard.png")}
                          />
                        </button>
                      </CSVLink>
                    </div>
                  </div>
                </div>
                {parsecteur?.length > 0 && parsecteur_data?.length > 0 ? (
                  <Barchart
                    title={"Formation"}
                    color={"#6B6DFF"}
                    label={parsecteur}
                    stats={parsecteur_data}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-md-6 pt-3">
              <div className="blocD" style={{ height: '375px' }}>
                <Hn
                  title="Personnes formées / Non formées"
                  level="h4"
                  className=""
                  plus={false}
                />
                <div className="flex space-between" style={{ height: "80%" }}>
                  <div className="row alignCenter" style={{ width: "100%" }}>
                    <div className="col-md-4">
                      <div className="chartContainer">
                        <Doughnut data={data} />
                        <div className="chartInner">
                          <div className="chartStatus">Total: {inscrit?.nbrNotTrained + inscrit?.nbrTrained}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="legend">
                        <span className="green"></span>Personnes formées ({inscrit?.nbrTrained})
                      </div>
                      <div className="legend">
                        <span className="white"></span>Personnes non formées ({inscrit?.nbrNotTrained})
                      </div>
                    </div>
                  </div>
                  <div className="square">
                    <CSVLink data={trained} headers={headerstrained} filename={"TrainedOrNot.csv"}>
                      <button type="button" className="btn btn-link">
                        <img
                          src={require("../../assets/icons/exportDashboard.png")}
                        />
                      </button>
                    </CSVLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 pt-3">
              <div className="blocD" style={{ height: '375px' }}>
                <Hn
                  title="Nombre de participants"
                  level="h4"
                  className=""
                  plus={false}
                />
                <div className="float-end">
                  <div className="square ">
                    <CSVLink data={[{nbrParticipant}]} headers={headerNbParticipants} filename={"NbParticipants.csv"}>
                    <button type="button" className="btn btn-link ">
                      <img
                        src={require("../../assets/icons/exportDashboard.png")}
                      />
                    </button>
                    </CSVLink>
                  </div>
                </div>
                {partheme?.length > 0 && partheme_data?.length > 0 ? (
                  // <Barchart color={"#6B6DFF"} label={partheme} stats={partheme_data} />
                  <HorizontalBarchart
                    nbrInscrit={nbrInscrit}
                    nbrParticipant={nbrParticipant}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <Hn
              title="FORMATION E-LEARNING"
              level="h3"
              className="pt-5"
              plus={true}
            />
            <div className="col-md-6 pt-5">
              <div className="blocD">
                <p className="title-bloc">Nombre de connexion</p>
                <p className="txt-bloc">{elearningStats?.nb_connexions}</p>
              </div>
            </div>
            <div className="col-md-6 pt-5">
              <div className="blocD">
                <p className="title-bloc">
                  Temps global passé sur la plateforme
                </p>
                <p className="txt-bloc">{elearningStats?.temps_total}</p>
              </div>
            </div>
            <div className="col-md-12 py-3">
              <div className="blocD">
                <div className="row">
                  <div className="col-lg-8 col-md-7 col-xs-6 pt-3 text-white border-end">
                    <div className="row" >
                      <div className="col-lg-3">
                        <p>Pas commencé:</p>
                      </div>
                      <div className="col-lg-5">
                        <ProgressBar bgColor={"#4C4C4C"} width={elearningStats?.pas_commence?.percent + '%'} />
                      </div>
                      <div className="col-lg-4">
                        <p>{elearningStats?.pas_commence?.nb} apprenants ({elearningStats?.pas_commence?.percent} %)</p>
                      </div>

                      <div className="col-lg-3">
                        <p>En cours:</p>
                      </div>
                      <div className="col-lg-5">
                        <ProgressBar bgColor={"#FFB13B"} width={elearningStats?.en_cours?.percent + '%'} />
                      </div>
                      <div className="col-lg-4">
                        <p>{elearningStats?.en_cours?.nb} apprenants ({elearningStats?.en_cours?.percent} %)</p>
                      </div>

                      <div className="col-lg-3">
                        <p>Non validé:</p>
                      </div>
                      <div className="col-lg-5">
                        <ProgressBar bgColor={"#dd3333"} width={elearningStats?.non_valide?.percent + '%'} />
                      </div>
                      <div className="col-lg-4">
                        <p>{elearningStats?.non_valide?.nb} apprenants ({elearningStats?.non_valide?.percent} %)</p>
                      </div>

                      <div className="col-lg-3">
                        <p>Validé:</p>
                      </div>
                      <div className="col-lg-5">
                        <ProgressBar bgColor={"#3EA279"} width={elearningStats?.valide?.percent + '%'} />
                      </div>
                      <div className="col-lg-4">
                        <p>{elearningStats?.valide?.nb} apprenants ({elearningStats?.valide?.percent} %)</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="d-flex justify-content-between rest">
                          <div className="chart mb-7 ">
                            <div className="chartContainer" >
                              <Doughnut data={data} />
                              <div className="chartInner">
                                <div className="chartStatus">Total: {elearningStats?.total}</div>
                              </div>
                            </div>

                          </div>

                          <div className="square">
                            <CSVLink data={elearningData} headers={headersElearningData} filename={"elearningData.csv"}>
                              <button type="button" className="btn btn-link">
                                <img
                                  src={require("../../assets/icons/exportDashboard.png")}
                                />
                              </button>
                            </CSVLink>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DashboardAdmin;
