import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import "./popup.css";
import axios from "axios";

export default function PopupFiltreActionformationElear({ showfiltre, closefiltre, searchwithfiltre,filterEntry }) {

  const initialInputState = {
    intitule:filterEntry?.intitule ?? "",
    theme: filterEntry?.theme ??"",
    duree: filterEntry?.duree ??"",
    status: filterEntry?.status ??"",
  };

  
  const uri = process.env.REACT_APP_BASE_URL;
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [thematics, setTematique] = useState([]);
  const { intitule, theme, duree, status } = eachEntry;
  function getThematique() {
    axios
      .get(uri + "/thematic")
      .then((result) => {
        setTematique(result?.data);
        
        
      })
      .catch((err) => {
        console.log("err themes", err);
      });
  }
  const resetForm = () => {
    document.getElementById("filterForm").reset();
    setEachEntry({ intitule:"", theme:"", duree:"", status:"" })
  }
 
  function getvalueinput(e) {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    getThematique()
    setEachEntry(initialInputState);
  }, [showfiltre, closefiltre]);

  return (
    <Modal show={showfiltre} onHide={closefiltre} size="lg" className="filter">
      <Modal.Header closeButton>
        <Modal.Title className="px-3">
          <Hn title="Filtrer" level="h4" plus={false} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="filterForm">
          <div className="row px-2">
            <div className="col-md-6">
              <Hn
                title="Nom"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <input
                type="text"
                className="form-control drop"
                onChange={getvalueinput}
                name="intitule"
                value={eachEntry.intitule}
              />
            </div>
            <div className="col-md-6">
              <Hn
                title="Thème"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <select
                className="form-select"
                aria-label="Default select example"
                id="theme"
                value={eachEntry.theme}
                onChange={getvalueinput}
                name="theme"

              >
                <option defaultValue>
                  Sélectionner
                </option>
                {thematics?.map((theme, index) => {
                  return (
                    <option value={theme.id}  key={index} >{theme?.name}</option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6 mt-3">
              <Hn
                title="Nb d'heures"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <input
                type="number"
                className="form-control drop"
                onChange={getvalueinput}
                name="duree"
                value={eachEntry.duree}
              />
            </div>
            <div className="col-md-6 mt-3">
              <Hn
                title="Status"
                level="h5"
                plus={false}
                className="label-popup"
              />
              <select
                className="form-select"
                aria-label="Default select example"
                id="status"
                value={eachEntry.status}
                onChange={getvalueinput}
                name="status"
              >
                <option defaultValue>
                  Sélectionner
                </option>
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
              </select>
            </div>

          </div>
        </form>
      
        <br></br>
      </Modal.Body>
      <Modal.Footer>
       <div> <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
      <div className="flex">  <button className="secondaryModal"
          onClick={() => {
            resetForm()
          }
          }
          disabled={eachEntry.intitule === "" && eachEntry.theme === "" && eachEntry.duree === "" && eachEntry.status === "" ? "disabled" : ""}
        >
          Réinitialiser
        </button>
        <Button
          variant="primary"
          onClick={() => {
            closefiltre()
            searchwithfiltre({ intitule, theme, duree, status });
          }}
        >
          Valider
        </Button></div>
      </Modal.Footer>
    </Modal>
  );
}