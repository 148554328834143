import { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import PopupFiltrethematique from "../../components/popup/PopupFiltrethematique";
import Papa from "papaparse";
import AxioServices from "../../services/AxioServices";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useDatatable } from "../../components/hook/useDatatable";
import { Spinner } from "../../components/common/Spinner";

export default function Datatable() {
  const [id, setId] = useState();
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [themes, setThemes] = useState([]);

  const {selectRow} = useDatatable();
  let history = createBrowserHistory({ forceRefresh: true });

  const uri = process.env.REACT_APP_BASE_URL;
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/thematique/" + row.id);
    },
  };

  //récupérer tous les thématiques
  function getThemes() {
    return axios
      .get(uri + "/thematic")
      .then((result) => {
        setThemes(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }

  useEffect(() => {
    getThemes();
  }, []);

  const handleCloseConfirmationModal = () => {
    setAction(null);
    setShowConfirmationModal(false);
    setId(null);
  }

  function searchwithfiltre(val) {
    setEachEntry(val);
    if (val.ref === "" && val.name === "") {
      setThemes([]);
    } else {
      let themeFilter = themes.filter(el =>
        (el?.ref?.toLowerCase().includes(val.ref.toLowerCase()) && (val.ref.length > 0))
        || (el?.name?.toLowerCase() === val.name.toLowerCase() && (val.name.length > 0))
      )
      setThemes(themeFilter)
    }
  }

  const handleOnChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";

        AxioServices.create("thematic/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            toast.success("Importation en cours, Redirection...");
            setTimeout(() => {
              history.go(0);
            }, 5000);
          })
          .catch((err) => {
            toast.error("Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };

  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  };

  const manageAction = () => {
    setShowLoader(true);
    let promise;
    if(action === 'delete'){
      promise = axios.delete(`${uri}/thematic/${id}`);
    }
    if(action === 'clone'){
      promise = axios.post(`${uri}/thematic/duplicate/${id}`, headers);
    }
    promise
      .then((response) => {
        getThemes().then(() =>{
          toast.success(action === 'delete' ? "Suppression effectuée avec success..." : "Duplication effectuée avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch(() => {
        toast.error("Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      if(action === 'delete'){
        return axios.delete(`${uri}/thematic/${row}`);
      }
      if(action === 'clone'){
        return axios.post(`${uri}/thematic/duplicate/${row}`, headers);
      }
      return null;
    });
    setShowLoader(true);
    Promise.all(promises).then(() => {
        setSelectedRows([]);
        getThemes().then(() => {
          toast.success(action === 'delete' ? "Suppression(s) effectuée(s) avec success..." : "Duplication(s) effectuée(s) avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      toast.error("Quelque chose s'est mal passé, Réessayer");
    });
  }

  const columns = [
    {
      dataField: "ref",
      text: "Réference",
      csvText: "ref",
      sort: true,
    },
    {
      dataField: "name",
      text: "Nom de la thématique",
      csvText: "name",
      sort: true,
    },
    {
      dataField: "subtheme",
      text: "Nombre de sous Thèmes",
      csvText: "subtheme",
      formatter: (row) => {
        return <>{row.length}</>;
      },
      sort: true,
    },
    {
      dataField: "orderThematic",
      text: "Ordre d'affichae",
      csvText: "orderThematic",
      sort: true,
    },

    {
      dataField: "follow",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            <Link to={`/admin/updatethematique/${row.id}`} className="btn btn-link">
              <img src={require("../../assets/icons/edit.png")} />
            </Link>
            <button
              type="button"
              className="btn"
              onClick={() => {
                setId(row.id);
                setAction('clone');
                setShowConfirmationModal(true);
              }}
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button>

            <button
              type="button"
              className="btn"
              onClick={() => {
                setId(row.id);
                setAction('delete');
                setShowConfirmationModal(true);
              }}
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>
          </div>
        );
      },
      sort: true,
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });
    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  const bulkDelete = () => {
    setAction('delete');
    setShowConfirmationModal(true);
  }

  const bulkClone = () => {
    setAction('clone');
    setShowConfirmationModal(true);
  }

  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={themes}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-5 pe-3">
              <div className="row ">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e) =>
                            props.searchProps.onSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltrethematique
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                        />
                        <div className="square">
                          <label className="btn btn-link" htmlFor="export">
                            <button
                              onClick={() => props.csvProps.onExport()}
                              type="button"
                              className="btn btn-link"
                            >
                              <img
                                src={require("../../assets/icons/Export.png")}
                              />
                            </button>
                          </label>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label htmlFor="file" type="button">
                            <img
                              src={require("../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkClone}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../assets/icons/duplicate.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkDelete}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../assets/icons/delete.png")}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <Link to={`/admin/createthematique`} className="btn btn-primary btn-gy height43">
                          Créer une thématique
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow(selectedRows, setSelectedRows)}
              pagination={paginationFactory(options)}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      {showLoader && <Spinner />}

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            {action === 'delete' ? (id != null ? 'Etes-vous sûr(e) de vouloir supprimer la thématique' : 'Etes-vous sûr(e) de vouloir supprimer la selection') :
              id != null ? 'Etes-vous sûr(e) de vouloir dupliquer la thématique' : 'Etes-vous sûr(e) de vouloir dupliquer la selection'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={() => {
              id ? manageAction() : manageBulk()
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
