import axios from "axios";
import Hn from "../../../../../components/common/Hn";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./apprenant.css";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faDisplay, faDownload
} from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ConventionPDFDOC from "../../../../../components/doc/ConventionPDFDOC";
import AttestationPDFDOC from "../../../../../components/doc/AttestationPDFDOC";
export default function InfoApprenant() {
  const params = useParams();
  const uri = process.env.REACT_APP_BASE_URL;
  const [apprenant, setApprenant] = useState([]);
  const [edit, setEdit] = useState(false);
  const [attestations, setAttestations] = useState([]);
  const [dataAttestation, setDataAttestation] = useState([]);

 //récupérer un apprenant par son id
  function getApprenant(id) {
    axios
      .get(uri + `/participant/${id}`)
      .then((result) => {
        setApprenant(result?.data[0]);
        getAttestations(result?.data[0]?.mailcontact);
      })
      .catch((err) => {
        console.log("err apprenant", err);
      });
  }

  //rechercher une action de formation
  function findInTable(e) {

    if (e.target.value.length > 0) {
      const filtreTexte = (arr, requete) => {
        return arr.filter(
          (el) => el?.title.toLowerCase().indexOf(requete.toLowerCase()) !== -1
        );
      };

      setAttestations(filtreTexte(attestations, e.target.value));
    } else {
      getAttestations(apprenant?.mailcontact);
    }
  }

  //récupérer les attestations de l'apprenant choisi
  function getAttestations(userData) {
    let types = [];
    if(params.category){
      types = [
        params.category,
      ];
    } else {
      types = [
        'webinars',
        'trainings',
        'elearnings',
      ];
    }


    let promises = types.map((type) => {
      return axios
        .get(`${uri}/watershed/get_user_data/${userData}/${type}`)
        .then(function(response) {
          return response.data;
        })
    });

    Promise.allSettled(promises)
      .then((result) => {
        let values = [];
        result.forEach(res => { values.push(...res.value) });
        setAttestations(values);
      });
  }

  useEffect(() => {
    getApprenant(params.id);
  }, []);

  useEffect(() => {
    setDataAttestation({
      nom: apprenant.contactNom,
      prenom: apprenant.contactPrenom,
      address: apprenant.agenceAddress?.trim()+' '+apprenant.codePostal?.trim()+' '+apprenant.ville?.trim(),
    });
  }, [apprenant]);

  const Training = ({ item }) => {
    const [visible, setVisible] = useState(false);

    return (
      <>
        <tr>
          <td>{item.title}</td>
          <td>{item?.chapitres?.length}</td>
          <td>{item?.date_debut}</td>
          <td>{item?.duree}</td>
          <td>{item?.score}</td>
          <td>
            <button type="button" className="btn btn-link">
              <img src={require("../../../../../assets/icons/ExportAttestation.png")} />
            </button>
          </td>
        </tr>

        {visible &&
          item?.chapitres.map((item, index) => (
            <tr className="expandRow" key={index}>
              <td></td>
              <td>{item.titre}</td>
              <td>{item.date}</td>
              <td>{item.duree}</td>
              <td>{item.score}</td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </>
    );
  };

  const TrainingEdit = ({ item, onChange }) => {
    const [visible, setVisible] = useState(false);

    return (
      <>
        <tr>
          <td>{item?.intitule}</td>
          <td>{item?.chapitres?.length}</td>
          <td>
            <input
              className="form-control"
              type="date"
              value={item.date_debut}
            ></input>
          </td>
          <td>
            <input
              className="form-control"
              type="number"
              defaultValue={item.duree}
              onChange={(e) =>
                onChange(item.elearning_id, e.target.value, "duree")
              }
            ></input>
          </td>
          <td>
            <input
              className="form-control"
              type="number"
              value={item.score}
            ></input>
          </td>

          <td>
            <button type="button" className="btn btn-link">
              <img src={require("../../../../../assets/icons/ExportAttestation.png")} />
            </button>
          </td>
          <td>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setVisible(!visible)}
            >
              <img
                src={
                  !visible
                    ? require("../../../../../assets/icons/dropdownclosed.png")
                    : require("../../../../../assets/icons/dropdown.png")
                }
              />
            </button>
          </td>
        </tr>

        {visible &&
          item?.chapitres.map((item, index) => (
            <tr className="expandRow" key={index}>
              <td></td>
              <td>{item.titre}</td>{" "}
              <td>
                <input type="date" value={item.date}></input>
              </td>
              <td>
                <input type="number" value={item.duree}></input>
              </td>
              <td>
                <input type="number" value={item.score}></input>
              </td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </>
    );
  };


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists pe-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={"/admin/apprenant"}>
                  {params?.category == "elearning" ? 'Formation e-learning - Apprenants' : 'Formation presentielle - Apprenants'}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {apprenant?.contactPrenom + " " + apprenant?.contactNom}
              </li>
            </ol>
            <Link to={"/admin/apprenant"} className={"btn btn-link resetBtn"}>
              Retour
            </Link>
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title={`${apprenant?.contactPrenom} ${apprenant?.contactNom}`}
                  level="h4"
                  className="h4-size pb-5"
                  plus={true}
                />
              </div>
            </div>
          </nav>
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="Informations générales"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />
            <div className="form-elements">
              <div className="row">
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Apprenant
                    </label>
                    <br></br>
                    <span>
                      {`${apprenant?.contactPrenom} ${apprenant?.contactNom}`}
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Email
                    </label>
                    <br></br>
                    <span>{apprenant?.mailcontact}</span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Métier
                    </label>
                    <br></br>
                    <span>{apprenant?.metier}</span>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Agent immobilier
                      </label>
                      <br></br>
                      <span>{apprenant?.agencymain}</span>
                      <br></br>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Email agent immobilier
                      </label>
                      <br></br>
                      <span>{apprenant?.agenceEmail}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Nom de l'agence de rattachement
                      </label>
                      <br></br>
                      <span>{apprenant?.agenceImmobilier}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Adresse de l’agence
                      </label>
                      <br></br>
                      <span>{apprenant?.agenceAddress}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Code postal
                      </label>
                      <br></br>
                      <span>{apprenant?.codePostal}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Ville
                      </label>
                      <br></br>
                      <span>{apprenant?.ville}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Code GT
                      </label>
                      <br></br>
                      <span>{apprenant?.code_gt}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Offre de formation
                      </label>
                      <br></br>
                      <span>{apprenant?.offre}</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Date d'entrée
                      </label>
                      <br></br>
                      <span>{dateFormat(apprenant?.dateentree, "dd-mm-yyyy")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lists-filter my-5">
            <div className="row">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={findInTable}
                      />
                    </div>
                  </div>
                  <div className=" px-0 myxs">
                    <div className="flex">
                      <div className={edit ? "blue square" : "square"}>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => setEdit(true)}
                        >
                          <img
                            src={
                              edit
                                ? require("../../../../../assets/icons/editwhite.png")
                                : require("../../../../../assets/icons/edit.png")
                            }
                          />
                        </button>
                      </div>
                      <div className="square">
                        <button type="button" className="btn btn-link">
                          <img
                            src={require("../../../../../assets/icons/filter.png")}
                          />
                        </button>
                      </div>
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src={require("../../../../../assets/icons/Export2.png")}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {attestations?.length > 0 ? (
            <table className="table table-dark table-hover">
              <thead>
                <tr>
                  <th>Formation</th>
                  <th>Modalité</th>
                  <th>Date de début</th>
                  <th>Date de fin</th>
                  <th>Taux de réussite</th>
                  <th>Validation</th>
                  <th>Convention</th>
                  <th>Attestation</th>
                </tr>
              </thead>
              <tbody>
                {attestations.map((item, index) =>
                  // Désactivation temporaire le temps de voir ce que ca fait -_-
                  // edit ? (
                  //   <TrainingEdit
                  //     key={index}
                  //     item={item}
                  //   ></TrainingEdit>
                  // ) : (
                  //   <Training item={item} key={index}></Training>
                  // )
                  (
                    <tr className="td" key={index} >
                      <td>{item.title}</td>
                      <td>
                        <FontAwesomeIcon icon={faDisplay} />
                      </td>
                      <td>{!isNaN(new Date(item.start_date)) ? (new Date(item.start_date)).toLocaleDateString("fr") : item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>{item.score}%</td>
                      <td>
                        {item.passed ? (
                          <span className="text-success">
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              className="fa-lg"
                            />
                          </span>
                        ) : (
                          <span className="text-danger">
                            <FontAwesomeIcon icon={faCircleXmark} />
                          </span>
                        )}
                      </td>
                      <td>
                        {item?.type && item.type !== 'elearnings' ? (
                          <PDFDownloadLink document={<ConventionPDFDOC data={[dataAttestation, item, apprenant]} />} fileName="convention.pdf">
                            {({ loading }) =>
                              loading ? 'Loading document...' : <FontAwesomeIcon icon={faDownload} className="fa-lg" />
                            }
                          </PDFDownloadLink>
                        ) : '-'}
                      </td>
                      <td>
                        {item.passed ? (
                          <PDFDownloadLink document={<AttestationPDFDOC data={[dataAttestation, item]} />} fileName="attestation.pdf">
                            {({ loading }) =>
                              loading ? 'Loading...' : <FontAwesomeIcon icon={faDownload} className="fa-lg" />
                            }
                          </PDFDownloadLink>
                        ) : '-'}
                      </td>
                    </tr>
                  )


                )}
              </tbody>
            </table>
          ) : (
            <Hn
              title="Aucune attestation trouvée"
              level="h5"
              plus={false}
              className="text-center mt-5"
            />
          )}
        </div>
      </div>
    </div>
  );
}
