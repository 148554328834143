import Search from "../components/common/Search";

export default function SearchTraining() {

  return (
    <div className="container-fluid">
        <div className="row">
          <Search />
        </div>
    </div>
  );
};
