import axios from "axios";
import React, { useState } from "react";
import { Form, Alert, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Papa from "papaparse";
import AxioServices from "../../../../services/AxioServices";
import PopupFiltre from "../../../../components/popup/PopupFiltreSessions";
import { createBrowserHistory } from "history";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "../../../../components/common/Spinner";
import { useDatatable } from "../../../../components/hook/useDatatable";
import { Link } from "react-router-dom";

export default function Datatable({
  getSessions,
  sessions,
  setSessions,
}) {
  let history = createBrowserHistory({ forceRefresh: true });
  const [id, setId] = useState();
  let toastId = null;
  const [initialsession, setInitialsession] = useState([]);
  const [showfiltre, setShowfiltre] = useState(false);
  const [displayetat, setDisplayetat] = useState("false");
  const [eachEntry, setEachEntry] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const {selectRow} = useDatatable();
  const uri = process.env.REACT_APP_BASE_URL;

  const handleCloseConfirmationModal = () => {
    setAction(null);
    setShowConfirmationModal(false);
    setId(null);
  }

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {

      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";

        AxioServices.create("session/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
            displayToast("Csuccess", "Importation en cours, Redirection...");
            console.log("testimport", result.data)
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };

  const manageAction = () => {
    setShowLoader(true);
    let promise;
    if(action === 'delete'){
      promise = axios.delete(`${uri}/session/${id}`);
    }
    if(action === 'clone'){
      promise = axios.post(`${uri}/session/duplicate/${id}`, headers);
    }
    promise
      .then((response) => {
        getSessions().then(() =>{
          displayToast("Csuccess", action === 'delete' ? "Suppression effectuée avec success..." : "Duplication effectuée avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch(() => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      if(action === 'delete'){
        return axios.delete(`${uri}/session/${row}`);
      }
      if(action === 'clone'){
        return axios.post(`${uri}/session/duplicate/${row}`, headers);
      }
      return null;
    });
    setShowLoader(true);
    Promise.all(promises).then(() => {
        setSelectedRows([]);
        getSessions().then(() => {
          displayToast("Csuccess", action === 'delete' ? "Suppression(s) effectuée(s) avec success..." : "Duplication(s) effectuée(s) avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
    });
  }

  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  };

  function updateStatus(id, checked) {
    let status = {
      status: checked
    }

    axios
      .put(`${uri}/session/edit/comment/` + id, status, headers)
      .then((response) => {

      })
      .catch((error) => {
        console.log(error);
      });

  }

  function searchwithfiltre(vals) {
    setInitialsession(sessions);
    setEachEntry(vals);

    if (vals?.action !== "" || vals?.dateend !== "" || vals.datestart !== "" || vals?.ville !== "") {

      setInitialsession([]);

      let query = `action=${vals.action}&ville=${vals.ville}&datestart=${vals.datestart}&dateend=${vals.dateend}`;
      let req = uri + "/session/filter?" + query;

      axios
        .get(req)
        .then((result) => {
          if (result?.data.length > 0) {
            setSessions(result?.data);
            setDisplayetat("false");
          } else {
            setSessions(initialsession);
            setDisplayetat("true");
          }
        })
        .catch((err) => {
          console.log("err session filter", err);
          setDisplayetat("true");
        });
    }
    else {
      getSessions();
    }
  }

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/session/show/" + row.id);
    },
  }

  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  const columns = [
    {
      dataField: "status",
      text: "Actif",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="ps-3">
            <Form.Check
              type="switch"
              id={row.id}
              onChange={(e) => {
                updateStatus(row.id, e.target.checked)
              }}
              defaultChecked={row.status}
            />
          </div>
        );
      },
    },
    {
      dataField: "training.title",
      text: "Action",
      csvText: "title",
      sort: true,
    },
    {
      dataField: "location.town",
      text: "Ville",
      csvText: "ville",
      sort: true,
    },

    {
      dataField: "former.lastName",
      text: "Formateur",
      csvText: "formateur",
      sort: true,
    },
    {
      dataField: "contactSessions",
      text: "Inscrits",
      csvText: "inscrits",
      sort: true,
      formatter: (row) => {
        return <>{row?.length}</>;
      },
    },
    {
      dataField: "dateStart",
      text: "Date de début",
      csvText: "dateCreation",
      sort: true,
      formatter: (row) => {
        return (new Date(row)).toLocaleDateString("fr");
      },
    },
    {
      dataField: "dateEnd",
      text: "Date de fin",
      csvText: "datefin",
      sort: true,
      formatter: (row) => {
        return (new Date(row)).toLocaleDateString("fr");
      },
    },
    {
      dataField: "actions",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            <a
              href={"/admin/update-session-formation-presentielle/" + row.id}
              className="btn btn-link"
            >
              <img src={require("../../../../assets/icons/edit.png")} />
            </a>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {
                setId(row.id);
                setAction('clone');
                setShowConfirmationModal(true);
              }}
            >
              <img src={require("../../../../assets/icons/duplicate.png")} />
            </button>
            {localStorage.getItem("role") === "Super-admin" ? (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  setAction('delete');
                  setShowConfirmationModal(true);
                }}
              >
                <img src={require("../../../../assets/icons/delete.png")} />
              </button>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  const bulkDelete = () => {
    setAction('delete');
    setShowConfirmationModal(true);
  }

  const bulkClone = () => {
    setAction('clone');
    setShowConfirmationModal(true);
  }

  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={sessions}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false, fileName: 'Sessions.csv', }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter mb-5">
              <div className="row">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e) =>
                            props.searchProps.onSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className=" px-0 myxs">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <PopupFiltre
                          showfiltre={showfiltre}
                          closefiltre={handleClosefiltre}
                          searchwithfiltre={searchwithfiltre}
                          filterEntry={eachEntry}
                        />
                        <div className="square">
                          <button
                            onClick={() => props.csvProps.onExport()}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../../../assets/icons/Export.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label htmlFor="file" type="button">
                            <img
                              src={require("../../../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkClone}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../../../assets/icons/duplicate.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkDelete}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../../../assets/icons/delete.png")}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="px10 pe-3">
                      <div className="d-grid gap-2">
                        <Link to={"/admin/create-session-formation"} className="btn btn-primary btn-gy height43">
                          Créer une session de formation
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {displayetat === "true" && (
              <>
                <br></br>
                <Alert color="primary">Aucun resultat trouvé</Alert>
              </>
            )}
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark me-3"
              bordered={false}
              hover
              selectRow={selectRow(selectedRows, setSelectedRows)}
              pagination={paginationFactory(options)}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      {showLoader && <Spinner />}

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            {action === 'delete' ? (id != null ? 'Etes-vous sûr(e) de vouloir supprimer la session' : 'Etes-vous sûr(e) de vouloir supprimer la selection') :
              id != null ? 'Etes-vous sûr(e) de vouloir dupliquer la session' : 'Etes-vous sûr(e) de vouloir dupliquer la selection'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={() => {
              id ? manageAction() : manageBulk()
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
