import { useState, useEffect, Fragment } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link, useHistory, useParams } from "react-router-dom";
import Chart2 from "../../components/chartjs/Chart2";
import Hn from "../../components/common/Hn";
import PopupVideo from "../../components/popup/PopupVideo";
import "./DetailFormation.css";
import "../../components/popup/popupVideo.css";
import Placeholder from "../../assets/placeholder.png";
import axios from "axios";
import Iframe from "react-iframe";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/lazy";
import toast from "react-hot-toast";
import { Interweave } from "interweave";
import { Spinner } from "../../components/common/Spinner";

export default function DetailsFormationE({ disabled }) {
  const user = localStorage.getItem("email");
  const [unite, setUnite] = useState();
  const [content_url, setContentUrl] = useState(false);
  const [showModalBool, setShowModalBool] = useState(false);
  const [elearning, setElearning] = useState([]);
  const [validated, setValidated] = useState(0);
  const [nbUnites, setNbUnites] = useState(0);
  const [scoreds, setScoreds] = useState([]);
  const [play, setPlay] = useState([]);
  const [score, setScore] = useState();
  const [openDetails, setOpenDetails] = useState(false);
  const [playerCurrentTime, setPlayerCurrentTime] = useState(null);
  const [start, setDateStart] = useState(new Date());
  const [showLoader, setShowLoader] = useState(false);
  // scorm
  let scormScore = 0;
  let scormTemps = 0;
  let scormNbQuestions = 0;

  const IMG_URL = process.env.REACT_APP_PIC_ELEARNING_IMG;
  const params = useParams();

  let history = useHistory();

  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const uri = process.env.REACT_APP_BASE_URL;

  //créer les données e-learning de l'utilisateur en question.  Le score par formation, la validation,le nombre d'unités et l'avancement...
  function getUserData(user_email) {
    let data = new FormData();
    data.append("user_email", user_email);

    trackPromise(
      axios
        .post(
          uri + "/watershed/get_user_data_for_elearning/" + params.id,
          data,
          headers
        )
        .then((result) => {
          setScore(result?.data?.score);
          setScoreds(result?.data?.scoreds);
          setPlay(result?.data?.play);
          setValidated(result?.data?.validated);
          setNbUnites(result?.data?.nb_unites);
        })
        .catch((err) => {
          console.log("err data", err);

          toast.error("Désolé cette formation n'a pas de contenu");
          setTimeout(() => {
            history.push("/Formations-e-learning");
          }, 1500);
        })
    );
  }

  //récupérer l'elearning par id
  function getElearning() {
    trackPromise(
      axios
        .get(uri + "/elearning/" + params.id, headers)
        .then((result) => {
          setElearning(result?.data);
        })
        .catch((err) => {
          console.log("err elearning", err);
        })
    );
  }

  const img = elearning?.image ? IMG_URL + elearning.image : Placeholder;

  const refreshData = () => {
    getUserData(user);
    getElearning();
  }

  useEffect(() => {
    getUserData(user);
    getElearning();
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if(showModalBool){
        scormScore = event.data?.detail?.score;
        scormNbQuestions = event.data?.detail?.nb_questions;

        if (event.data?.detail?.exit === "oui" && typeof unite !== "undefined") {
          scormTemps = 1;
          handleModalHide();
        }
      }
    }

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    }
  }, [showModalBool]);

  const showModal = (url, unite) => {
    setUnite(unite);
    setContentUrl(url);
    scormScore = 0;
    scormTemps = 0;
    scormNbQuestions = 0;
    setShowModalBool(true);
  };

  const handleModalShow = () => {
    if(!unite?.duree || unite.duree === "00:00:00"){
      initWatershed();
    }
    setDateStart(new Date());
  };

  const handleModalHide = async () => {
    setShowLoader(true);
    await Promise.all([
      saveTimer(),
      saveScore(),
    ]).then(() => {
      refreshData();
      setShowLoader(false);
      setShowModalBool(false);
    })
  };

  const UniteApprentissageItem = ({ uniteApprentissage }) => {
    const [open, setOpen] = useState(true);
    return (
      <>
        <tr className="td">
          <td>{uniteApprentissage.chapitre.titre}</td>
          <td>{uniteApprentissage.chapitre.duree}</td>
          <td>Documents associés</td>
          <td>{uniteApprentissage.score}</td>
          <td>
            <span className="text-success">
              {uniteApprentissage.chapitre_passed === true && (
                <img
                  style={{ width: "20px" }}
                  src={require("../../assets/icons/Valid.png")}
                />
              )}
              {uniteApprentissage.chapitre_passed === false && (
                <img
                  style={{ width: "20px" }}
                  src={require("../../assets/icons/NotValid.png")}
                />
              )}
            </span>
          </td>
          <td>
            <span className="look-like-link" onClick={() => setOpen(!open)}>
              <img
                style={{ width: "20px" }}
                src={require("../../assets/icons/dropdown.png")}
              />
            </span>
          </td>
        </tr>

        {uniteApprentissage?.unites?.map((unite, index) => {
          return (
            <Fragment key={unite.id}>
              <tr
                className="accordion"
                style={{ visibility: open ? "visible" : "collapse" }}
              >
                <td className="ps-4">{unite.titre}</td>
                <td className="ps-4">{unite.duree && unite.duree !== '0' ? unite.duree : '-'}</td>
                <td>
                  {unite?.extension == "mp4" && (
                    <span
                      className="look-like-link"
                      style={{ color: "#006FFF" }}
                      onClick={() =>
                        showModal(
                          process.env.REACT_APP_PIC_ELEARNING_FILE +
                            unite?.document,
                          unite
                        )
                      }
                    >
                      <img
                        style={{ width: "20px" }}
                        className="unite_icone"
                        src={require("../../assets/icons/Play.png")}
                      />
                      Lancer la vidéo
                    </span>
                  )}

                  {unite?.extension == "mp3" && (
                    <span
                      className="look-like-link"
                      style={{ color: "#006FFF" }}
                      onClick={() =>
                        showModal(
                          process.env.REACT_APP_PIC_ELEARNING_FILE +
                            unite?.document,
                          unite
                        )
                      }
                    >
                      <img
                        style={{ width: "20px" }}
                        className="unite_icone"
                        src={require("../../assets/icons/Play.png")}
                      />
                      Lancer le fichier audio
                    </span>
                  )}

                  {unite?.extension == "pdf" && (
                    <span
                      className="look-like-link"
                      style={{ color: "#006FFF" }}
                      onClick={() => {
                        showModal(
                          process.env.REACT_APP_PIC_ELEARNING_FILE +
                            unite?.document,
                          unite
                        );
                      }}
                    >
                      <img
                        style={{ width: "20px" }}
                        className="unite_icone"
                        src={require("../../assets/icons/MenuMobile.png")}
                      />
                      Ouvrir le document PDF
                    </span>
                  )}

                  {unite?.extension == "zip" && (
                    <span
                      className="look-like-link"
                      style={{ color: "#006FFF" }}
                      onClick={() =>
                        showModal(
                          process.env.REACT_APP_PIC_ELEARNING_SCORM +
                            unite?.scormdirectoryname +
                            "/story.html",
                          unite
                        )
                      }
                    >
                      <img
                        style={{ width: "20px" }}
                        className="unite_icone"
                        src={require("../../assets/icons/MenuMobile.png")}
                      />
                      Ouvrir le document
                    </span>
                  )}
                </td>
                <td className="ps-4">{unite.score}</td>
                <td></td>
              </tr>
            </Fragment>
          );
        })}
      </>
    );
  };

  const initWatershed = () => {
    let data = new FormData();
    let verb = "start";

    data.append("verb", verb);
    data.append("user_email", user);
    data.append("unite", unite?.id);

    trackPromise(
      axios
        .post(uri + "/watershed/new", data, headers)
        .catch((err) => console.log("err data", err))
    );
  }

  const saveTimer = async () => {
    const now = new Date();
    const trackingDuration = now.getTime() - start.getTime();
    const passedTime = playerCurrentTime ?? Math.round(trackingDuration / 1000);

    const data = new FormData();
    data.append("email", user);
    data.append("unite", unite?.id);
    data.append("duree", passedTime);

    return await axios
      .post(uri + "/player_resume/new", data, headers)
      .catch((err) => {
        console.log("err", err);
      })
  }

  const saveScore = async () => {
    let data = new FormData();
    if (unite?.scormdirectoryname !== null) {
      if (scormTemps === 0) {
        return;
      }
      // validation scorm
      data.append("scorm_score", scormScore);
      data.append("scorm_nb_questions", scormNbQuestions);
    }

    let verb = "checkin";

    data.append("user_email", user);
    data.append("unite", unite?.id);
    data.append("unite_passed", true);
    data.append("verb", verb);

    return await axios
      .post(uri + "/watershed/new", data, headers)
      .catch((err) => {
        console.log("err data", err);
      })
  }

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="details pt-5 px-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link rel="opener" to={"/Formations-e-learning"}>
                  Formations e-learning
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {elearning?.intitule}
              </li>
            </ol>
            <div>
              <Link rel="opener" to={"/Formations-e-learning"}>
                Retour
              </Link>
              <Modal
                show={showModalBool}
                onShow={handleModalShow}
                onHide={handleModalHide}
                className="videomodal"
                backdrop="static"
                fullscreen={true}
              >
                {showLoader && <Spinner />}
                <Modal.Header closeButton>
                  <div id="unite_title" className="text-white">
                    <p className="elearning">{unite?.elearning_titre}</p>
                    <p className="chapitre">
                      {unite?.chapitre_titre} | {unite?.titre}
                    </p>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div id="content_player">
                    {(unite?.extension == "mp3" || unite?.extension == "mp4") && (
                      <ReactPlayer
                        id="mp4-player"
                        url={content_url}
                        controls={true}
                        onProgress={(progress) => {
                          setPlayerCurrentTime(progress.playedSeconds);
                        }}
                      />
                    )}

                    {unite?.extension == "zip" && (
                      <Iframe
                        url={content_url}
                        id="scorm_content"
                        display="initial"
                        position="relative"
                        className="vidIframe"
                      />
                    )}

                    {unite?.extension == "pdf" && (
                      <object data={content_url} type="application/pdf">
                        <p>
                          Erreur de chargement, vous pouvez télécharger le PDF
                          <a href={content_url} className='ml-1'>ici</a>
                        </p>
                      </object>
                    )}
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </nav>
          <div className="head-details">
            <div className="row">
              <div className="col-md-9">
                <Hn
                  title={elearning?.intitule}
                  level="h1"
                  dispaly
                  className="pt-4"
                  plus={false}
                />
                {elearning?.associationRoute?.id && (
                  <p className="text text-white">
                    {`Parcours associé : `}
                    <Link to={`/${elearning.associationRoute.type === 'elearning' ? 'detailformations' : 'detailformation'}/${elearning.associationRoute.id}`}>
                      {elearning.associationRoute.title}
                    </Link>
                  </p>
                )}
                {(elearning.obligation_de_formation || elearning.manager) && (
                  <p className="text text-white obligations">
                    {elearning.obligation_de_formation && (<span>Participe à l’obligation de formation</span>)}
                    {elearning.manager && (<span>Spécifique au manager</span>)}
                  </p>
                )}
              </div>
              <PopupVideo
                play={play}
                run={params.run}
                handleClose={refreshData}
              />
            </div>
          </div>
          <div className="body-details p-3">
            <div className="row pt-3">
              <div className="col-lg-3 col-md-5 col-xs-6 pt-3">
                <div>
                  <img src={img} className="float-end" width="80%" alt="..." />
                </div>
              </div>
              <div className="col-lg-9 col-md-7 col-xs-6">
                <div className="row pt-3">
                  <div className="col-md-8 border-end">
                    <Hn
                      title="Objectifs"
                      level="h4"
                      plus={false}
                      className="pb-3"
                    />
                    {elearning?.objectifs && (
                      <div className="simpletext py-1 text-white">
                        <Interweave content={elearning.objectifs} />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex justify-content-between rest">
                      <div className="align-self-center">
                        <Chart2
                          title={validated + "/" + nbUnites}
                          invalidated={100 - (validated * 100) / nbUnites}
                          validated={(validated * 100) / nbUnites}
                        />
                      </div>
                      <div className="align-self-center">
                        <Hn
                          title={`Il reste ${parseInt(nbUnites, 10) - parseInt(validated, 10)} unité(s) d'apprentissage`}
                          plus={false}
                          className="text-white text-center"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
                <div className="row pt-4 pe-2">
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Durée</h5>
                        <p className="card-text">{elearning?.duree} min</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Nombres d'unités</h5>
                        <p className="card-text">{nbUnites}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Validation</h5>
                        <p className="card-text">{elearning?.validation}%</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="card text-white bg-dark mb-3 text-center">
                      <div className="card-body">
                        <h5 className="card-title">Score actuel</h5>
                        <p className="card-text">{score}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-details row mt-8 m-1">
            <div className="col-md-12">
              <div className={`titre-methode-vente pt-4 no-padding `}>
                <div
                  className="flex justify-content-between"
                >
                  <Hn
                    title="Détails de la formation"
                    level="h4"
                    className="mb-3"
                    plus={false}
                  />
                  <span
                    className="look-like-link"
                    onClick={() => setOpenDetails(!openDetails)}
                  >
                    Voir tout
                  </span>
                </div>
                <div
                  className="accordion"
                  style={{ display: openDetails ? "block" : "none" }}
                >
                  {elearning?.prerequis && (
                    <>
                      <Hn
                        title="Prérequis et public visé"
                        level="h5"
                        className="mb-2"
                        plus={false}
                      />
                      <div className="simpletext py-1 text-white">
                        <Interweave content={elearning.prerequis} />
                      </div>
                    </>
                  )}

                  {(elearning?.programme_de_formation || elearning.fichier_programme) && (
                    <>
                      <div
                        className="flex justify-content-between"
                      >
                        <div>
                          <Hn
                            title="Programme"
                            level="h5"
                            className="mb-2 mt-3"
                            plus={false}
                          />
                        </div>
                        <div>
                          {elearning.fichier_programme && (
                            <a
                              href={
                                process.env.REACT_APP_PIC_ELEARNING_FILE +
                                elearning.fichier_programme
                              }
                              target="_blank"
                              className="donwload mb-2 mt-3"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                fill="currentColor"
                                className="bi bi-download"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                              </svg>
                              Télécharger le programme
                            </a>
                          )}
                        </div>
                      </div>
                      {elearning.programme_de_formation && (
                        <div className="simpletext py-1 text-white">
                          <Interweave content={elearning.programme_de_formation} />
                        </div>
                      )}
                    </>
                  )}

                  { elearning.commentaire && (
                    <>
                      <Hn
                        title="Commentaire"
                        level="h5"
                        className="mb-2 mt-3"
                        plus={false}
                      />
                      <div className="simpletext py-1 text-white">
                        <Interweave content={elearning.commentaire} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div className={`more-info ${disabled}`}></div>
            </div>
          </div>
          <div className="table pt-5">
            <table className="table table-dark table-hover">
              <thead>
                <tr className="tr">
                  <th>Unité d'apprentissage</th>
                  <th>Durée</th>
                  <th>Documents associés</th>
                  <th>Score obtenu</th>
                  <th>Validation</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {scoreds?.map((scored, i) => {
                  return (
                    <UniteApprentissageItem
                      uniteApprentissage={scored}
                      key={i}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
