import { useState } from "react";
import ParticipantDatatable from './participantDatatable';


function Participant({setParticipe, participe, session}) {
  const [participant, setParticipant] = useState([]);

  return (
    <div className="lists pe-4 pt-5">
      <ParticipantDatatable
        participant={participant}
        setParticipe={setParticipe}
        participe={participe}
        session={session}
        setParticipant={setParticipant}
      />
    </div>
  );
}
export default Participant;
