import Hn from "../../components/common/Hn";
import Papa from "papaparse";
import AxioServices from "../../services/AxioServices";

function Import() {

  const handleOnChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
    
        AxioServices.create("agence/import", JSON.stringify(results.data)) //importation des fichiers csv de l'agence
          .then((result) => {
         
          })
          .catch((err) => {
            console.log(err);
          });
      },
    });
  };
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Import csv"
              level="h1"
              className="pt-5"
              plus={true}
            />
          </div>
          <div className="lists-filter">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
                    <input type="file" name="filecsv" onChange={handleOnChange}></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Import;
