import './Icon.css';
export default function Icon({ name, data }) {

   const names = require(`../../assets/icons/${name}.svg`);

  return (
      <div className="d-flex text-white">
         <div style={{paddingRight:"3px"}}>
            <img src={names} className="nav_logo-icon p-1" alt="img" width='30' />
        </div>
        <div>
           <p style={{fontWeight:"300",marginBottom:"0"}}>{data}</p>
        </div>
    </div>
  );
}
