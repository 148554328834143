import axios from "axios";

import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../../../components/common/Hn";

import "./faceTraining.css";
import SessionDatatable from "./SessionDatatable.js";

function FaceSessions() {
  const [sessions, setSessions] = useState([]);
  const uri = process.env.REACT_APP_BASE_URL;

  //filtre d'une session
  useEffect(() => {
    getSessions();
  }, []);

//récupérer toutes les sessions des formations présentielles
  function getSessions() {
    return axios
      .get(uri + "/session")
      .then((result) => {
        setSessions(result?.data);
      })
      .catch((err) => {
        console.log("err  session", err);
      });
  }

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="sessions de formation" level="h2" className="pt-4" />
          </div>
          <div className="mt-20">
              <SessionDatatable
                sessions={sessions}
                getSessions={getSessions}
                setSessions={setSessions}
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceSessions;
