import axios from "axios";
import { useState , useEffect} from "react";
import { Link, useParams } from "react-router-dom";
import Hn from "../../components/common/Hn";
import "../admin/forms/formationelearning/elearningForm.css";
import "./webinar.css";
import Participant from "../admin/forms/face-training/crud/show-sessions-participants/participantSession";
import { Interweave } from "interweave";

function DetailWebinar() {
  const [webinarOne, setWebinarOne]=useState([]);
  const params = useParams();
  const uri = process.env.REACT_APP_BASE_URL;

  //récupérer un webinaire choisi par son id
  function getWebinarById() {
    axios.get(uri + `/webinars/${params.id}`)
      .then((result) => {
        setWebinarOne(result?.data);
      })
      .catch((err) => {
        console.log("err webinars", err);
      });
  }
  useEffect(() => {
    getWebinarById();
  }, []);


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-3 pb-0">
          <div className=" p-5 text-white bg-dark rounded-3">

            <div className="row">
              <div className="col-md-6">
                <Hn
                  title="Editeur"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
              </div>
              <div className="col-md-6 ">
                <Link to={`/update-webinar-etape2/${webinarOne.id}`}>
                  <img className="float-end" src={require("../../assets/icons/edit.png")} />
                </Link>
              </div>
            </div>
            <div className="col-md-12">
              <Hn
                title={webinarOne?.editor?.title}
                level="h5"
                plus={false}
              />
            </div>

            <div className=" editor col-md-12 ">
              <Interweave content={webinarOne?.editor?.source} />
            </div>
          </div>
          <div className="lists-filter my-5">
            <Participant type="webinars"/>
          </div>
        </div>

      </div>
    </div>
  );
}

export default DetailWebinar;
