import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button } from "react-bootstrap";
import axios from "axios";
import AxioServices from "../../../../services/AxioServices";
import { createBrowserHistory } from "history";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Papa from "papaparse";
import PopupFiltreFormer from "../../../../components/popup/PopupFiltreFormer";
import { useDatatable } from "../../../../components/hook/useDatatable";
import { Spinner } from "../../../../components/common/Spinner";

export default function ListFormerTrainingDataGrid() {
  const [id, setId] = useState();
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [former, setFormer] = useState([]);
  const {selectRow} = useDatatable();

  let history = createBrowserHistory({ forceRefresh: true });
  let toastId = null;
  const uri = process.env.REACT_APP_BASE_URL;

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setId(null);
  }

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  function getFormer() {
    return axios
      .get(uri+"/former")
      .then((result) => {
        setFormer(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }

  useEffect(() => {
    getFormer();
  }, []);

  const handleShowfiltre = () => {
    setShowfiltre(true);
  };
  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  };

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";

        AxioServices.create("former/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",
          },
        })
          .then((result) => {
              displayToast("Csuccess", "Importation en cours, Redirection...");
              setTimeout(() => {
                history.go(0);
              }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };

  function searchwithfiltre(val) {
    setEachEntry(val);
    if (val.nom === ""
      && val.prenom === ""
      && val.email === ""
      && val.tel === ""
      && val.organize === "") {
      getFormer();
      //  setParticipant(initialparticipant)
    } else {
      setFormer([]);
      let formerFilter = former.filter(el =>
        (el?.lastName?.toLowerCase().includes(val.nom.toLowerCase()) && (val.nom.length > 0))
        || (el?.firstName?.toLowerCase().includes(val.prenom.toLowerCase()) && (val.prenom.length > 0))
        || (el?.email?.toLowerCase() === val.email.toLowerCase() && (val.email.length > 0))
        || (el?.tel?.includes(val.tel) && (val.tel.length > 0))
        || el?.organize === val.organize
      )
      setFormer(formerFilter)
    }
  }

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  const manageAction = () => {
    setShowLoader(true);
    axios.delete(`${uri}/former/${id}`)
      .then((response) => {
        getFormer().then(() =>{
          displayToast("Csuccess", 'Suppression effectuée avec success...');
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch((error) => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      return axios.delete(`${uri}/former/${row}`);
    });
    setShowLoader(true);
    Promise.all(promises).then(() => {
      setSelectedRows([]);
      getFormer().then(() => {
          displayToast("Csuccess", "Suppression(s) effectuée(s) avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
    });
  }

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/former/" + row.id);
    },
  };

  const columns = [
    {
      dataField: "firstName",
      text: "Formateur",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {row.firstName} {row.lastName}
          </div>
        );
      },
    },
    {
      dataField: "firstName",
      text: "firstName",
      csvText: "firstName",
      hidden: true,
    },
    {
      dataField: "lastName",
      text: "lastName",
      csvText: "lastName",
      hidden: true,
    },
    {
      dataField: "organize",
      text: "Interne",
      csvText: "organize",
      sort: true,
    },
    {
      dataField: "tel",
      text: "Téléphone",
      csvText: "tel",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      csvText: "email",
      sort: true,
    },
    {
      dataField: "ref",
      text: "Heures réalisées",
      csvText: "ref",
      sort: true,
      hidden: true,
    },
    {
      dataField: "status",
      text: "status",
      csvText: "status",
      hidden: true,
    },
    {
      dataField: "comment",
      text: "comment",
      csvText: "comment",
      hidden: true,
    },
    {
      dataField: "avatar",
      text: "avatar",
      csvText: "avatar",
      hidden: true,
    },
    {
      dataField: "follow",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group float-end" role="group" aria-label="Basic example">
            <a
              href={"/admin/update-former/" + row.id}
              type="button"
              className="btn btn-link"
            >
              <img src={require("../../../../assets/icons/edit.png")} />
            </a>
            {localStorage.getItem("role") === "Super-admin" ? (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  setShowConfirmationModal(true);
                }}
              >
                <img src={require("../../../../assets/icons/delete.png")} />
              </button>
            ) : (
              ""
            )}
          </div>
        );
      },
      sort: true,
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    const onFollowChanged = () => {
      this.setState({ isFollow: !this.state.isFollow });

    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          onClick={() => {
            this.onFollowChanged(row);
          }}
        >
          Follow
        </Button>
      );
    };

    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  let rows = former.map((item, i) => item);

  const bulkDelete = () => {
    setShowConfirmationModal(true);
  }

  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={rows}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-5">
            <div className="row">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={(e=>props.searchProps.onSearch(e.target.value))}
                      />

                    </div>
                  </div>
                  <div className=" px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={handleShowfiltre}
                        >
                          <img
                            src={require("../../../../assets/icons/filter.png")}
                          />
                        </button>
                      </div>
                      <PopupFiltreFormer
                        showfiltre={showfiltre}
                        closefiltre={handleClosefiltre}
                        searchwithfiltre={searchwithfiltre}
                        filterEntry={eachEntry}
                      />
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={()=>props.csvProps.onExport()}
                        >
                          <img
                            src={require("../../../../assets/icons/Export.png")}
                          />
                        </button>
                      </div>
                      <div className="square">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept={".csv"}
                          className="btn btn-link"
                        onChange={handleOnChange}
                          hidden
                        />
                        <label htmlFor="file" type="button">
                          <img
                            src={require("../../../../assets/icons/Import.png")}
                          />
                        </label>
                      </div>
                      <div className="square">
                        <button
                          onClick={bulkDelete}
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src={require("../../../../assets/icons/delete.png")}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="px10">
                    <div className="d-grid gap-2">
                      <a
                        href="/admin/create-former"
                        className="btn btn-primary btn-gy height43"
                        type="button"
                      >
                        Ajouter un formateur
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark"
              bordered={false}
              hover
              selectRow={selectRow(selectedRows, setSelectedRows)}
              pagination={paginationFactory(options)}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      {showLoader && <Spinner />}

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            {id != null ? 'Etes-vous sûr(e) de vouloir supprimer le formateur' : 'Etes-vous sûr(e) de vouloir supprimer la selection'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={() => {
              id ? manageAction() : manageBulk()
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
