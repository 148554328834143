import axios from "axios";
import Hn from "../../components/common/Hn";
import {createBrowserHistory} from "history";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

export default function Info() {
    const params = useParams();
    const [competence, setCompetence] = useState([]);
    const uri = process.env.REACT_APP_BASE_URL;
    let history = createBrowserHistory({forceRefresh: true});
    //récupérer une compétence par son id
    function gecompetence(id) {
        axios.get(uri+`/skills/${
            params.id
        }`).then((result) => {
            setCompetence(result ?. data);
           
        }).catch((err) => {
            console.log("err location", err);
        });
    }

    useEffect(() => {
        gecompetence(params.id);
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="container lists p-5">
                    <nav aria-label="breadcrumb pb-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/admin/competence">Compétences</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                            {
                                            competence.title
                                        }
                            </li>
                        </ol>
                    </nav>
                    <div className="headupdate"><Hn title={
                                competence.title
                            }
                            level="h1"
                            className="py-4"/>
                        <a href={"/admin/updatecompetence/" + competence.id} className="btn btn-primary float-end">Modifier</a>
                    </div>

                    <div className="h-100 p-5 text-white bg-dark rounded-3">
                        <Hn title="Informations générales" level="h4" className="h4-size pb-5"
                            plus={false}/>

                        <div className="form-elements">
                            <div className="row">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label htmlFor="" className="form-label">
                                            Titre du référentiel
                                        </label>
                                        <br></br>
                                        <span>{
                                            competence.title
                                        }</span>
                                    </div>
                                </div>
                            </div>


                            <div className="">

                                <div className="row">
                                    <div className="col-8">
                                        <div className="mb-3">
                                            <label htmlFor="" className="form-label">
                                                Compétences
                                            </label>
                                            <br></br>
                                            {
                                            competence ?. subskills ?. map((val, index) => (
                                                <span key={index}>{
                                                    val.name
                                                }
                                                    <br></br>
                                                </span>
                                            ))
                                        } </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* lists */} </div>
        </div>
    );
}
