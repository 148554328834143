import { Form } from "react-bootstrap";
import profile from "../../assets/profil.png";
import "./BannerProfile.css";
import { useEffect, useState } from "react";

export default function BannerProfile({ title, user, collaborateur, getuserinfo, score }) {

  const [color, setColor] = useState("1");

  useEffect(() => {
    if (user[0]?.ContactID === localStorage.getItem("ind")) {
      setColor('1');
    } else {
      setColor('0');
    }
  }, [user]);

  return (
    <div className="desktop banner-profil p-4" id={color === "1" ? "firstColor" : "secondColor"}>
      <div className="row">
        <div className="col-lg-2 col-md-4 offset-0">
          <div className="">
            <img src={profile} alt="profile" className="image-profil img-fluid" />
          </div>
        </div>
        <div className="col-lg-10 col-md-8">
        {localStorage.getItem("role")==="Apprenants"
          ?"":
          <div className="select col-md">
            <Form.Select
              className="dropdown"
              onChange={(e) => getuserinfo(e.target.value)}
            >
              <option value={localStorage.getItem("ind")}>Mes informations</option>
              {collaborateur?.map((value, index) => (
                <option value={value.ContactID} key={index}>{value.apprenant}</option>
              ))}
            </Form.Select>
          </div>}
          <div className="banner-box">
            <div className="banner-title">
              <h3 className="h5">
                {title}
                {user[0]?.contactNom} {user[0]?.contactPrenom}
              </h3>
            </div>
            <div className="banner-texte">
              <h4 className="titre-h-5">{user[0]?.metier}</h4>
            </div>
            <div className="banner-info">
              <h5 className="titre-info-h-5">
                Renouvellement de la carte: 15 septembre 2024
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-xs-12 ">
              <div className="card text-white bg-dark mb-3 ">
                <div className={color === "1" ? "card-body firstColorCadre" : "card-body secondColorCadre"}>
                  <h5 className="card-title">Score moyen</h5>
                  <p className="card-text">{Math.floor(score.average_score) || "Aucune donnée disponible"}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12 ">
              <div className="card text-white bg-dark mb-3">
                <div className={color === "1" ? "card-body firstColorCadre" : "card-body secondColorCadre"}>
                  <h5 className="card-title">Score maximum</h5>
                  <p className="card-text">{score.max_score || "Aucune donnée disponible"}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12 ">
              {" "}
              <div className="card text-white bg-dark mb-3">
                <div className={color === "1" ? "card-body firstColorCadre" : "card-body secondColorCadre"}>
                  <h5 className="card-title">Score minimum</h5>
                  <p className="card-text">{score.min_score || "Aucune donnée disponible"}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-xs-12 ">
              {" "}
              <div className="card text-white bg-dark mb-3">
                <div className={color === "1" ? "card-body firstColorCadre" : "card-body secondColorCadre"}>
                  <h5 className="card-title">Temps total</h5>
                  <p className="card-text">{score.temps_total || "Aucune donnée disponible"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
