import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import SideBarAdmin from "./components/admin/sidebarAdmin/Sidebar";
import SideBar from "./components/common/Sidebar";
import Cordion from "./components/cordion/Cordion";
import Mostformation from "./components/owl counter/Mostformation";
import CreateFacelearning from "./views/admin/forms/face-training/crud/CreateFacelearning";
import CreateFaceSession from "./views/admin/forms/face-training/crud/CreateFaceSession";
import CreateFaceSession1 from "./views/admin/forms/face-training/crud/CreateFaceSession1";
import CreateFaceTraining from "./views/admin/forms/face-training/crud/CreateFaceTraining";
import CreateSkills from "./views/admin/forms/face-training/crud/CreateSkills";
import UpdateFaceTraining from "./views/admin/forms/face-training/crud/UpdateFaceTraining";
import FaceTraining from "./views/admin/forms/face-training/FaceTraining";
import FaceSession from "./views/admin/forms/face-training/faceSessions";
import ListePrestataire from "./views/admin/forms/prestataire/ListProviders";
import ProviderEdit from "./views/admin/forms/prestataire/ProviderEdit";
import getProvider from "./views/admin/forms/prestataire/ReadProvider";
import Connexion from "./views/connexion/Connexion";
import SearchTraining from "./views/SearchTraining";
import DetailFormation from "./views/detailFormation/DetailFormation";
import Email from "./views/email/Email";
import FormationElearning from "./views/formationElearning/FormationElearning";
import FormationPresentiel from "./views/formationPresentiel/FormationPresentiel";
import Profile from "./views/profile/Profile";
import Login from "./views/users/Login";
import ShowSession from "./views/admin/forms/face-training/crud/ShowSessions";
import CreateFormer from "./views/admin/forms/formateur/CreateFormer";
import ListFormer from "./views/admin/forms/formateur/ListFormer";
import ReadFormers from "./views/admin/forms/formateur/ReadFormers";
import UpdateFormer from "./views/admin/forms/formateur/UpdateFormer";
import ListLocationTraining from "./views/admin/forms/location/ListLocationTraining";
import LocationForm from "./views/admin/forms/location/LocationForm";
import LocationShow from "./views/admin/forms/location/LocationShow";
import LocationUpdate from "./views/admin/forms/location/LocationUpdate";
import CreateParticipant from "./views/admin/forms/participant/CreateParticipant";
import ListParticipant from "./views/admin/forms/participant/ListParticipant";
import CreatePrestataire from "./views/admin/forms/prestataire/CreateProvider";
import Createcompetence from "./views/competence/Createcompetence";
import InfoC from "./views/competence/Info";
import Listecompetence from "./views/competence/Listecompetence";
import Updatecompetence from "./views/competence/Updatecompetence";
import Createthematique from "./views/thematique/Createthematique";
import Info from "./views/thematique/Info";
import Listthematique from "./views/thematique/Listthematique";
import Updatethematique from "./views/thematique/Updatethematique";
import CompetencesElearning from "./views/admin/forms/e-learning/chapitre/CompetencesElearning";
import CreateChapitreElearning from "./views/admin/forms/e-learning/chapitre/CreateChapitreElearning";
import CreateUniteDeFormation from "./views/admin/forms/e-learning/chapitre/CreateUniteDeFormation";
import CreateElearning from "./views/admin/forms/e-learning/crud/CreateElearning";
import Elearning from "./views/admin/forms/e-learning/Elearning";
import CreateFaceSessionElearning from "./views/admin/forms/formationelearning/CreateFaceSessionElearning";
import CreateFaceSessionElearning2 from "./views/admin/forms/formationelearning/CreateFaceSessionElearning2";
import CreateFaceSessionElearning3 from "./views/admin/forms/formationelearning/CreateFaceSessionElearning3";
import CreateFaceSessionElearning4 from "./views/admin/forms/formationelearning/CreateFaceSessionElearning4";
import CreateFaceSessionElearning5 from "./views/admin/forms/formationelearning/CreateFaceSessionElearning5";
import ElearningShow from "./views/admin/forms/formationelearning/ElearningShow";
import ElearningShow2 from "./views/admin/forms/formationelearning/ElearningShow2";
import ElearningShow3 from "./views/admin/forms/formationelearning/ElearningShow3";
import Listapprenant from "./views/admin/forms/formationelearning/apprenant/Listapprenant";
import InfoApprenant from "./views/admin/forms/formationelearning/apprenant/InfoApprenant";
import InfoApprenant2 from "./views/admin/forms/formationelearning/apprenant/InfoApprenant2";
import InfoApprenant3 from "./views/admin/forms/formationelearning/apprenant/InfoApprenant3";
import CreateFaceSession2 from "./views/admin/forms/face-training/crud/CreateFaceSession2";
import ListActionFormation from "./views/actionformationpresentielle/ListActionFormation";
import DetailsFormationE from "./views/detailFormation/DetailsFormationE";
import UpdateElearning from "./views/admin/forms/e-learning/crud/UpdateElearning";
import Import from "./views/import/Import";
import ShowAction from "./views/formationPresentiel/ShowAction";
import UpdateSession from "./views/admin/forms/face-training/crud/UpdateSession";
import DashboardAdmin from "./views/dashbordAdmin/DashboardAdmin";
import Addparticipants from "./views/admin/forms/face-training/crud/show-sessions-participants/Add-participants";
import WebinarsAddParticipants from "./views/webinair/WebinarsAddParticipants";
import ListMore from "./views/formationPresentiel/ListMore";
import ListWebinair from "./views/webinair/ListWebinair";
import CreateWebinair from "./views/webinair/CreateWebinair";
import CreateWebinair2 from "./views/webinair/CreateWebinair2";
import CreateWebinair3 from "./views/webinair/CreateWebinair3";
import DetailWebinar from "./views/webinair/DetailWebinar";
import UpdateWebinar1 from "./views/webinair/UpdateWebinair1";
import UpdateWebinar2 from "./views/webinair/UpdateWebinair2";
import UpdateWebinar3 from "./views/webinair/UpdateParticipants";
import DetailsFrontWebinars from "./views/webinair/DetailsWebinarFront";
import CreateParticipantsWebinaire from "./views/webinair/CreateParticipantsWebinaire";
import CookieConsent from "react-cookie-consent";

const NoAuthUser = () => {
  return (
    <Switch>

      <Route path="/login" exact component={Connexion}></Route>
      <Route path="/loginProfil" exact component={Login}></Route>
      <Redirect from="*" to="/login" />
    </Switch>
  )

}
const AuthUser = () => {
  return (
    <Switch>
      <Route path="/login" exact component={Connexion}></Route>
      <Route path="/admin/dashboard" exact component={DashboardAdmin}></Route>
      <Route path="/loginProfil" exact component={Login}></Route>
      <Route path="/redirect" exact component={SearchTraining}></Route>
      <Route path="/search" exact component={SearchTraining}></Route>
      <Route path="/listmore/:theme/:category" exact component={ListMore}></Route>
      <Route path="/formation-presentielle" exact component={FormationPresentiel} ></Route>
      <Route path="/detailformation/:id" exact component={DetailFormation}></Route>
      <Route path="/admin/createSession-etape3/:id" exact component={CreateFaceSession2}></Route>
      <Route path="/Formations-e-learning" exact component={FormationElearning}></Route>
      <Route path="/profile" exact component={Profile}></Route>
      <Route path="/email" exact component={Email}></Route>
      <Route path="/mostformation" exact component={Mostformation}></Route>
      <Route path="/detailformations/:id" exact component={DetailsFormationE} ></Route>
      <Route path="/detailformations/:id/:run" exact component={DetailsFormationE} ></Route>
      <Route path="/addParticipant/:id" exact component={Addparticipants}></Route>
      <Route path="/cordion" exact component={Cordion}></Route>
      {/* <Route path="/connexion" exact component={Connexion}></Route> */}
      <Route path="/admin/formation-presentielle" exact component={FaceTraining}></Route>
      <Route path="/admin/liste-lieu-formation" exact component={ListLocationTraining} ></Route>
      <Route path="/admin/create-formation-presentielle" exact component={CreateFaceTraining} ></Route>
      <Route path="/admin/update-formation-presentielle/:id" exact component={UpdateFaceTraining} ></Route>
      <Route path="/admin/Sessions" exact component={FaceSession}></Route>
      <Route path="/admin/create-session-formation-presentielle" exact component={CreateFaceSession}></Route>
      <Route path="/admin/update-session-formation-presentielle/:id" exact component={UpdateSession} ></Route>
      <Route path="/admin/create-session-formation/:id" exact component={CreateFaceSession} ></Route>
      <Route path="/admin/create-session-formation" exact component={CreateFaceSession1} ></Route>
      <Route path="/admin/create-session-formation-elearning" exact component={CreateFaceSessionElearning} ></Route>
      <Route path="/admin/create-session-formation-elearning2" exact component={CreateFaceSessionElearning2} ></Route>
      <Route path="/admin/create-session-formation-elearning3" exact component={CreateFaceSessionElearning3} ></Route>
      <Route path="/admin/create-session-formation-elearning4" exact component={CreateFaceSessionElearning4} ></Route>
      <Route path="/admin/create-session-formation-elearning5" exact component={CreateFaceSessionElearning5} ></Route>
      <Route path="/admin/show-elearnig/:id" exact component={ElearningShow} ></Route>
      <Route path="/admin/Session/show/:id" exact component={ShowSession} ></Route>
      <Route path="/admin/show-elearning2" exact component={ElearningShow2} ></Route>
      <Route path="/admin/show-elearning3" exact component={ElearningShow3} ></Route>
      <Route path="/admin/apprenant" exact component={Listapprenant} ></Route>
      <Route path="/admin/apprenant/:id/:category" exact component={InfoApprenant} ></Route>
      <Route path="/admin/apprenant2" exact component={InfoApprenant2} ></Route>
      <Route path="/admin/apprenant3" exact component={InfoApprenant3} ></Route>
      <Route path="/admin/create-session-formation-apprendre" exact component={CreateFacelearning} ></Route>
      <Route path="/admin/create-formation-competence" exact component={CreateSkills} ></Route>
      <Route path="/admin/create-location-training" exact component={LocationForm} ></Route>
      <Route path="/admin/update-location-training/:id" component={LocationUpdate} ></Route>
      <Route path="/admin/show-location/:id" exact component={LocationShow} ></Route>
      <Route path="/admin/create-former" exact component={CreateFormer} ></Route>
      <Route path="/admin/former/:id" exact component={ReadFormers}></Route>
      <Route path="/admin/liste-formateurs" exact component={ListFormer} ></Route>
      <Route path="/admin/update-former/:id" exact component={UpdateFormer} ></Route>
      <Route path="/admin/prestataires" exact component={ListePrestataire} ></Route>
      <Route path="/admin/prestataire/:id" exact component={getProvider} ></Route>
      <Route path="/admin/create-prestataire" exact component={CreatePrestataire} ></Route>
      <Route path="/admin/prestataire-edit/:id" exact component={ProviderEdit} ></Route>
      <Route path="/admin/create-participant" exact component={CreateParticipant} ></Route>
      <Route path="/admin/participants" exact component={ListParticipant} ></Route>
      <Route path="/admin/thematique" exact component={Listthematique} ></Route>
      <Route path="/admin/createthematique" exact component={Createthematique} ></Route>
      <Route path="/admin/updatethematique/:id" exact component={Updatethematique} ></Route>
      <Route path="/admin/thematique/:id" exact component={Info}></Route>
      <Route path="/admin/action/formation" exact component={ListActionFormation} ></Route>
      {/* //////////////*/}
      <Route path="/admin/competence" exact component={Listecompetence} ></Route>
      <Route path="/admin/createcompetence" exact component={Createcompetence} ></Route>
      <Route path="/admin/updatecompetence/:id" exact component={Updatecompetence} ></Route>
      <Route path="/admin/competence/:id" exact component={InfoC}></Route>
      <Route path="/admin/showActionFormationPres/:id" exact component={ShowAction} ></Route>
      {/* /////////////// */}
      {/* Elearning routes */}
      <Route path="/admin/formation-e-learning" exact component={Elearning} ></Route>
      <Route path="/admin/creer-formation-e-learning" exact component={CreateElearning} ></Route>
      <Route path="/admin/creer-chapitre-e-learning/:elearning_id" exact component={CreateChapitreElearning} ></Route>
      <Route path="/admin/create-unite-de-formation/:chapitre_id" exact component={CreateUniteDeFormation} ></Route>
      <Route path="/admin/add-skills-elearning/:elearning_id" exact component={CompetencesElearning} ></Route>
      <Route path="/admin/create-session-formation-elearning4" exact component={CreateFaceSessionElearning4} ></Route>
      <Route path="/admin/create-session-formation-apprendre" exact component={CreateFacelearning} ></Route>
      <Route path="/admin/update-formation-e-learning/:id" exact component={UpdateElearning} ></Route>
      {/* WEBINAIR ROUTE */}
      <Route path="/webinair" exact component={ListWebinair} ></Route>
      <Route path="/createWebinair" exact component={CreateWebinair} ></Route>
      <Route path="/createWebinairEtape2/:id" exact component={CreateWebinair2} ></Route>
      <Route path="/createWebinairEtape3/:id" exact component={CreateWebinair3} ></Route>
      <Route path="/detailWebinar/:id" exact component={DetailWebinar} ></Route>
      <Route path="/update-webinar/:id" exact component={UpdateWebinar1} ></Route>
      <Route path="/update-webinar-etape2/:id" exact component={UpdateWebinar2} ></Route>
      <Route path="/update-webinar-etape3/:id" exact component={UpdateWebinar3} ></Route>
      <Route path="/create-webinar-etape3/:id" exact component={CreateParticipantsWebinaire} ></Route>
      <Route path="/Details-webinars/:id" exact component={DetailsFrontWebinars} ></Route>
      <Route path="/webinair/participant/:id" exact component={WebinarsAddParticipants}></Route>
      <Route path="/import" exact component={Import}></Route>
      {/* <Redirect from="*" to="/admin/dashboard" /> */}
    </Switch>
  )
}


function App() {
  const [userToken, setUserToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [decoded, setDecoded] = useState(null);


  useEffect(() => {
    const user = localStorage.getItem("user");

    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

  }, [isAuthenticated]);


  return (

    <Router>
      <CookieConsent buttonText="Je comprend">Ce site utilise des cookies pour améliorer l'expérience utilisateur.</CookieConsent>
      {localStorage.getItem("authorise") === "yes" ? (
        <>
          {localStorage.getItem("role2") !== "Apprenants" ?
            <>{localStorage.getItem("role") !== "Apprenants" &&
              localStorage.getItem("role") !== "Responsable" &&
              localStorage.getItem("role") !== "Partenaire" ? (
              <SideBarAdmin />
            ) : (
              <SideBar />
            )}
            </> : <SideBar />}
        </>
      ) : (
        ""
      )}
      <div>
        {localStorage.getItem("id") ?
          <AuthUser />
          :
          <NoAuthUser />
        }
      </div>
    </Router >
  );
}

export default App;
