import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Add from "../../../../assets/icons/add.png";
import Hn from "../../../../components/common/Hn";
import AxioServices from "../../../../services/AxioServices";
import "./locationForm.css";

const validationSchema = Yup.object().shape({
  nom: Yup.string().required("Champ obligatoire."),
  prenom: Yup.string().required("Champ obligatoire."),
  type: Yup.string().required("Champ obligatoire."),
  reference: Yup.string().required("Champ obligatoire."),
});

function UpdateFormer({ id }) {
  const params = useParams();
  const [former, setFormer] = useState([]);
  const [dbvalue, setDbvalue] = useState("");
  const uri = process.env.REACT_APP_BASE_URL;
  let headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  const fileRef = useRef();
  const initialInputState = {
    type: "",
    nom: "",
    prenom: "",
    reference: "",
    image: "",
    telephone: "",
    email: "",
    actif: "",
    commentaire: "",
  };
  const retour = () => {
    history.push("/admin/liste-formateurs");
  };
  let history = useHistory();
  const [eachEntry, setEachEntry] = useState(initialInputState);
  //const [pays, setPays] = useState("pays");
  const {
    type,
    nom,
    prenom,
    reference,
    image,
    telephone,
    email,
    actif,
    commentaire,
  } = eachEntry;

  const handleChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
//modifier un formateur
  const handleSubmit = (values) => {
    var datasend = new FormData();
    datasend.append("organize", values.type);
    datasend.append("firstName", values.nom);
    datasend.append("lastName", values.prenom);
    datasend.append("ref", values.reference);
    datasend.append("brochure", values.image);
    datasend.append("tel", values.telephone);
    datasend.append("email", values.email);
    datasend.append("status",  values.actif);
    datasend.append("comment", values.commentaire);

    AxioServices.put("former/edit/" + params.id, datasend, headers)
      .then((result) => {
        console.log(result.data)
         history.push("/admin/liste-formateurs");
      })
      .catch((err) => {
        console.log(err);
      });
  };
//récupérer un formateur par son id
  function getFormer(id) {
    axios
      .get(
        uri+`/former/${params.id}`
      )
      .then((result) => {
        setFormer(result?.data);
       
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }

  useEffect(() => {
    getFormer(params.id);
  }, []);

  return (
    <div className="container-fluid">
      <div className="row pe-4">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/liste-formateurs">
                  Formations présentielles - Formateurs
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Modifier un formateur
              </li>
            </ol>
          </nav>
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="Modifier un formateur"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  type: former?.organize,
                  nom: former?.firstName,
                  prenom: former?.lastName,
                  reference: former?.ref,
                  image: former?.brochure,
                  telephone: former?.tel? former?.tel: "",
                  email: former?.email,
                  actif: former?.status,
                  commentaire: former?.comment? former?.comment: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="row g-3">
                    <div className="col-md-4">
                      <label htmlFor="actif" className="form-label">
                        Formateur*
                      </label>
                      <select
                        className="form-select"
                        name="type"
                        aria-label="Sélectionner dans la liste"
                        id="type"
                        // value={former?.organize || values.type}
                        onChange={handleChange}
                        // defaultValue={"Default"}
                      >
                        <option
                          value="interne"
                          selected={
                            values.type === "interne" ||
                            former.organize === "interne"
                              ? "selected"
                              : ""
                          }
                        >
                          interne
                        </option>
                        <option
                          value="externe"
                          selected={
                            values.type === "externe" ||
                            former.organize === "externe"
                              ? "selected"
                              : ""
                          }
                        >
                          externe
                        </option>
                      </select>

                      <ErrorMessage name="type">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Nom*
                      </label>
                      <input
                        type="text"
                        name="nom"
                        className="form-control"
                        id="init"
                        placeholder="Nom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nom}
                      />
                      <ErrorMessage name="nom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Prénom*
                      </label>
                      <input
                        type="text"
                        name="prenom"
                        className="form-control"
                        id="init"
                        placeholder="prénom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prenom}
                      />
                      <ErrorMessage name="prenom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-8">
                      <label htmlFor="ref" className="form-label">
                        Référence
                      </label>
                      <input
                        type="text"
                        name="reference"
                        className="form-control"
                        id="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.reference}
                        autoComplete="off"
                        readOnly
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="image" className="form-label">
                        Image
                      </label>
                      <span
                        className="form-control"
                        onClick={() => fileRef.current.click()}
                      >
                        <img src={Add} className="pe-1" />{" "}
                        {fileRef?.current?.value
                          ? fileRef?.current?.value
                          : "Ajouter une image"}
                      </span>

                      <input
                        ref={fileRef}
                        multiple={false}
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".png,.jpg,.gif"
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="telephone" className="form-label">
                        Téléphone
                      </label>
                      <input
                        type="number"
                        name="telephone"
                        className="form-control"
                        id="init"
                        placeholder="00 00 00 00"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.telephone}
                      />
                      <ErrorMessage name="telephone">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="assoc"
                        placeholder="Email@gmail.com"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="actif" className="form-label">
                        Actif
                      </label>
                      <select
                        className="form-select"
                        name="actif"
                        aria-label="Default select example"
                        id="actif"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.actif}
                      >
                        <option value="1" defaultValue>
                          Oui
                        </option>
                        <option value="0">Non</option>
                      </select>
                      <ErrorMessage name="actif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="commentaire" className="form-label">
                        Commentaire
                      </label>
                      <textarea
                        className="form-control"
                        name="commentaire"
                        id="pre"
                        rows="3"
                        placeholder="Ajouter un commentaire"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.commentaire}
                      ></textarea>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12 pt-5">
                      <button type="reset" className="btn btn-link resetBtn" onClick={retour}>
                      Annuler
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                        //disabled={isSubmitting}
                        //onClick={handleClick}
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}
export default UpdateFormer;
