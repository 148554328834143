import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { createBrowserHistory } from 'history';
import PopupFiltreWebinaire from '../../components/popup/PopupFiltreWebinaire';
import AxioServices from "../../services/AxioServices";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
import { useDatatable } from "../../components/hook/useDatatable";
import { Spinner } from "../../components/common/Spinner";

export default function ListWebinarDataGrid() {
  let history = createBrowserHistory({forceRefresh:true})
  const [id, setId] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [webinars, setWebinars] = useState([]);

  const [show, setShow] = useState(false);
  const [showfiltre, setShowfiltre] = useState(false);
  const [eachEntry, setEachEntry] = useState("");

  const {selectRow} = useDatatable();

  let toastId = null;
  const uri = process.env.REACT_APP_BASE_URL;

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  function getWebinars() {
    return axios
      .get(uri + `/webinars/all`)
      .then((result) => {
        setWebinars(result?.data);
      })
      .catch((err) => {
        console.log("err webinar", err);
      });
  }

  useEffect(() => {
    getWebinars();
  }, [show]);

  const handleCloseConfirmationModal = () => {
    setAction(null);
    setShowConfirmationModal(false);
    setId(null);
  }

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }

  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
    searchwithfiltre(obj);
  };

  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  function searchwithfiltre(val) {
    setEachEntry(val)
    if (val) {
      let webinarsFilter = webinars.filter(el =>
      ( el?.title.toLowerCase().includes(val?.titre.toLowerCase() )&& (val.titre.length>0))
      || moment(el?.date).format("YYYY-MM-DD")  === val.date && (val.date.length>0)
      || (moment(el?.hour).format("kk:mm")===val.heure && (val.heure.length>0))
      || el?.former?.id === val.former && val.former.length>0
      )
      setWebinars(webinarsFilter)
    }
  }

  /***********Begin Import **********/

  const handleOnChange = (e) => {
    // setFile(e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        results.data[0].ref = results.data[0].ref + "x2";

        AxioServices.create("webinars/import", JSON.stringify(results.data), {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*",

          },

        })
          .then((result) => {

            displayToast("Csuccess", "Importation en cours, Redirection...");

            setTimeout(() => {
              history.go(0);
             }, 5000);
          })
          .catch((err) => {
            displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
          });
      },
    });
  };
  /*****************End import */

  const manageAction = () => {
    setShowLoader(true);
    let promise;
    if(action === 'delete'){
      promise = axios.delete(`${uri}/webinars/${id}`);
    }
    if(action === 'clone'){
      promise = axios.post(`${uri}/webinars/duplicate/${id}`, headers);
    }
    promise
      .then((response) => {
        getWebinars().then(() =>{
          toast.success(action === 'delete' ? "Suppression effectuée avec success..." : "Duplication effectuée avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch(() => {
        toast.error("Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      if(action === 'delete'){
        return axios.delete(`${uri}/webinars/${row}`);
      }
      if(action === 'clone'){
        return axios.post(`${uri}/webinars/duplicate/${row}`, headers);
      }
      return null;
    });
    setShowLoader(true);
    Promise.all(promises).then((response) => {
        setSelectedRows([]);
        getWebinars().then(() => {
          toast.success(action === 'delete' ? "Suppression(s) effectuée(s) avec success..." : "Duplication(s) effectuée(s) avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      toast.error("Quelque chose s'est mal passé, Réessayer");
    });
  }

  const columns = [
    {
      dataField: "title",
      text: "Webinaire",
      csvText: "webinar",
    },
    {
      dataField: "audience",
      text: "audience",
      csvText: "audience",
      hidden: true
    },
    {
      dataField: "capacity_max",
      text: "capacity_max",
      csvText: "capacity_max",
      hidden: true,
    },
    {
      dataField: "capacity_min",
      text: "capacity_min",
      csvText: "capacity_min",
      hidden: true,
    },
    {
      dataField: "cost",
      text: "cost",
      csvText: "cout",
      hidden: true,
    },
    {
      dataField: "duration",
      text: "duration",
      csvText: "durée",
      hidden: true,
    },
    {
      dataField: "forward_catalog",
      text: "forward_catalog",
      csvText: "forward_catalog",
      hidden: true,
    },
    {
      dataField: "manager_spec",
      text: "manager_spec",
      csvText: "manager_spec",
      hidden: true,
    },
    {
      dataField: "obligation",
      text: "obligation",
      csvText: "obligation",
      hidden: true,
    },
    {
      dataField: "programFile",
      text: "programFile",
      csvText: "programFile",
      hidden: true,
    },
    {
      dataField: "ref",
      text: "ref",
      csvText: "ref",
      hidden: true,
    },
    {
      dataField: "status",
      text: "status",
      csvText: "status",
      hidden: true,
    },
    {
      dataField: "objects",
      text: "objects",
      csvText: "objectif",
      hidden: true,
    },
    {
      dataField: "theme.name",
      text: "theme",
      csvText: "theme",
      hidden: true,
    },
    {
      dataField: "editor.title",
      text: "editor",
      csvText: "editor title",
      hidden: true,
    },
    {
      dataField: "provider.name",
      text: "provider",
      csvText: "provider ",
      hidden: true,
    },
    {
      dataField: "training_program",
      text: "training_program",
      csvText: "training_program ",
      hidden: true,
    },
    {
      dataField: "date",
      text: "Date",
      csvText: "date",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>{moment(row?.date).format("YYYY/MM/DD")}</>
        );

      },
    },
    {
      dataField: "hour",
      text: "Heure",
      csvText: "heure",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>{row?.hour} </>
        );

      },
    },
    {
      dataField: "former",
      text: "Formateur",
      csvExport: false,
      sort: true,
       formatter: (cell, row) => {
         return (
           <> {row?.former.map(el=>el?.lastName + ' '+el?.firstName).join(' - ')}</>
         );
       },
    },
    {
      dataField: "",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Link to={`/update-webinar/${row.id}`} className="btn btn-link">
              <img src={require("../../assets/icons/edit.png")} />
            </Link>
            <button
              type="button"
              className="btn"
              onClick={() => {
                setId(row.id);
                setAction('clone');
                setShowConfirmationModal(true);
              }}
            >
              <img src={require("../../assets/icons/duplicate.png")} />
            </button>
            <button
              type="button"
              className="btn"
              onClick={() => {
                setId(row.id);
                setAction('delete');
                setShowConfirmationModal(true);
              }}
            >
              <img src={require("../../assets/icons/delete.png")} />
            </button>
          </div>
        );
      },
    },
  ];

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/detailWebinar/" + row.id);
    },
  }

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  const bulkDelete = () => {
    setAction('delete');
    setShowConfirmationModal(true);
  }

  const bulkClone = () => {
    setAction('clone');
    setShowConfirmationModal(true);
  }

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={webinars}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false , fileName: 'Webinaires.csv'}}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter"> <ToastContainer />
            <div className="row">
              <div className="col-xl-4 col-lg-1"></div>
              <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-4  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onChange={(e=>props.searchProps.onSearch(e.target.value))}
                      />

                    </div>
                  </div>
                  <div className="px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                           onClick={handleShowfiltre}
                        >
                          <img src={require("../../assets/icons/filter.png")} />
                        </button>
                      </div>
                      <PopupFiltreWebinaire
                      showfiltre={showfiltre} closefiltre={handleClosefiltre} searchwithfiltre={searchwithfiltre} filterEntry={eachEntry}
                      />
                      <div className="square">
                          <button className="btn btn-link" htmlFor="export" onClick={() => props.csvProps.onExport()}>
                            <img
                              src={require("../../assets/icons/Export.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <input
                            type="file"
                            name="file"
                            id="file"
                            accept={".csv"}
                            className="btn btn-link"
                            onChange={handleOnChange}
                            hidden
                          />
                          <label htmlFor="file" type="button">
                            <img
                              src={require("../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                      <div className="square">
                        <button
                          onClick={bulkClone}
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src={require("../../assets/icons/duplicate.png")}
                          />
                        </button>
                      </div>
                      <div className="square">
                        <button
                          onClick={bulkDelete}
                          type="button"
                          className="btn btn-link"
                        >
                          <img
                            src={require("../../assets/icons/delete.png")}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="px10">
                    <div className="d-grid gap-2">
                      <Link to="/createWebinair" className="btn btn-primary btn-gy height43" >
                        Créer un Webinaire
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark mt-5"
              bordered={false}
              hover
              selectRow={selectRow(selectedRows, setSelectedRows)}
              pagination={paginationFactory(options)}
              rowEvents={tableRowEvents}
            />
          </div>
        )}
      </ToolkitProvider>

      {showLoader && <Spinner />}

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            {action === 'delete' ? (id != null ? 'Etes-vous sûr(e) de vouloir supprimer le webinar' : 'Etes-vous sûr(e) de vouloir supprimer la selection') :
              id != null ? 'Etes-vous sûr(e) de vouloir dupliquer le webinar' : 'Etes-vous sûr(e) de vouloir dupliquer la selection'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={() => {
              id ? manageAction() : manageBulk()
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
