import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Hn from "../common/Hn";
import axios from "axios";
import "./popup.css";
export default function PopupFiltre({ showfiltre, closefiltre, searchwithfiltre, filterEntry }) {

  const initialInputState = {
    action: filterEntry?.action ?? "",
    ville: filterEntry?.ville ?? "",
    dateend: filterEntry?.dateend ?? "",
    datestart: filterEntry?.datestart ?? ""
  };
  const uri = process.env.REACT_APP_BASE_URL;
  const [eachEntry, setEachEntry] = useState(initialInputState);
  const [training, setTraining] = useState([]);
  const {
    action,
    ville,
    dateend,
    datestart
  } = eachEntry;
  const resetForm = () => {
    document.getElementById("filterForm").reset();
    setEachEntry({ action: "", ville: "", dateend: "", datestart: "" })
  }
  function getTraining() {
    axios
      .get(uri + "/training")
      .then((result) => {
        setTraining(result?.data);
        // setInitraining(result?.data);
      })
      .catch((err) => {
        console.log("err location", err);
      });
  }


  function getvalueinput(e) {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    getTraining()
    setEachEntry(initialInputState);
  }, [showfiltre, closefiltre]);


  return (
    <Modal show={showfiltre}
      onHide={closefiltre}
      size="lg"
      className="filter">
      <Modal.Header closeButton>
        <Modal.Title className="px-3 nopad">
          <Hn title="Filtrer" level="h4"
            plus={false} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="filterForm">
          <div className="row px-3 nopad">
            <div className="col-md-6">
              <Hn title="Action de formation" level="h5"
                plus={false}
                className="label-popup" />
              <select
                className="form-select"
                aria-label="Default select example"
                id="training"
                value={eachEntry.action}
                onChange={getvalueinput}
                name="action"
              >
                <option defaultValue>
                  Sélectionner
                </option>
                {training?.map((train, index) => {
                  return (
                    <option value={train.title}  key={index} >{train?.title}</option>
                  );
                })}
              </select>
              {/* <input type="text" className="form-control drop"
                        onChange={getvalueinput} name="action"/> */}
            </div>
            <div className="col-md-6">
              <Hn title="Ville" level="h5"
                plus={false}
                className="label-popup" />
              <input type="text" className="form-control drop"
                onChange={getvalueinput} name="ville"
                value={eachEntry.ville} />
            </div>
            <div className="col-md-6 pt-3">
              <Hn title="Date début" level="h5"
                plus={false}
                className="label-popup" />
              <input type="date" className="form-control drop"
                onChange={getvalueinput} name="datestart" value={eachEntry.datestart} />
            </div>
            <div className="col-md-6 pt-3">
              <Hn title="Date fin" level="h5"
                plus={false}
                className="label-popup" />
              <input type="date" className="form-control drop"
                onChange={getvalueinput} name="dateend" value={eachEntry.dateend} />
            </div>
          </div>
        </form>
        {/* /row */}
        <br></br>


        {/* /row */} </Modal.Body>
      <Modal.Footer>
        <div> <button className="btn btn-link resetBtn" onClick={closefiltre} style={{ color: "#006FFF" }}>
          Annuler
        </button></div>
        <div className="flex">  <button className="secondaryModal"
          onClick={() => {
            resetForm()
          }
          }
          disabled={eachEntry.intitule === "" && eachEntry.theme === "" && eachEntry.duree === "" && eachEntry.status === "" ? "disabled" : ""}
        >
          Réinitialiser
        </button>
          <Button
            variant="primary"
            onClick={() => {
              closefiltre()
              searchwithfiltre({ action, ville, datestart, dateend });
            }}
          >
            Valider
          </Button></div>


      </Modal.Footer>
    </Modal>
  );
}