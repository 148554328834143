import { ErrorMessage, Formik } from "formik";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Hn from "../../../../components/common/Hn";
import AxioServices from "../../../../services/AxioServices";
import "./participant.css";

// const validationSchema = Yup.object().shape({
//   nom: Yup.string().required("Champ obligatoire."),
//   prenom: Yup.string().required("Champ obligatoire."),
// });

function CreateParticipant() {
  let headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  const fileRef = useRef();
  //let history = useHistory();
  const initialInputState = {
    participant: "",
    email: "",
    metier: "",
    agentimm: "",
    emailagent: "",
    agence: "",
    adresseagence: "",
    code: "",
    ville: "",
    codegt: "",
    offre: "",
    elearning: "",
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  //const [pays, setPays] = useState("pays");
  const {
    participant,
    email,
    metier,
    agentimm,
    emailAgent,
    agence,
    adresseagence,
    code,
    ville,
    codeGt,
    offre,
    elearning,
  } = eachEntry

  const handleChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  let history = useHistory();
//créer un nouveau prestataire
  const handleSubmit = (values) => {
    const datasend = {
      collaborator: values.participant,
      email: values.email,
      agent:  values.agence,
      emailAgent: values.emailagent,
      job: values.metier,
      nameAgent: values.agentimm,
      address :values.adresseagence,
      zip: values.code,
      ville: values.ville,
      codeGt: values.codegt,
      offre: values.offre,
      type_training: values.elearning,
      presence: values.elearning
    };
    AxioServices.create("participant/new", datasend, headers)
      .then((result) => {
       
        history.push("/admin/participants");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/create-location-training">
                  Formations présentielles - Sessions de formation
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="/admin/create-location-training">
                  Nom de la session de formation
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ajouter un participant
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Ajouter un participant"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  participant: "",
                  email: "",
                  metier: "",
                  agentimm: "",
                  emailagent: "",
                  agence: "",
                  adresseagence: "",
                  code: "",
                  ville: "",
                  codegt: "",
                  offre: "",
                  elearning: "",
                }}
                // validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="row g-3">
                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Participant
                      </label>
                      <input
                        type="text"
                        name="participant"
                        className="form-control"
                        id="init"
                        placeholder="Nom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.participant}
                      />
                      <ErrorMessage name="nom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Email*
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        id="init"
                        placeholder="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      
                      />
                      <ErrorMessage name="nom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Métier*
                      </label>
                      <input
                        type="text"
                        name="metier"
                        className="form-control"
                        id="init"
                        placeholder="métier"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.metier}

                 
                      />
                      <ErrorMessage name="prenom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label">
                        agent immobilier
                      </label>
                      <input
                        type="texte"
                        name="agentimm"
                        className="form-control"
                        id="agent"
                        placeholder="agent immobilier"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.agentimm}
                        autoComplete="off"
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Email agent immobilier
                      </label>
                      <input
                        type="email"
                        name="emailagent"
                        className="form-control"
                        id="init"
                        placeholder="email agent immobilier"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.emailagent}
                        
                       
                      />
                      <ErrorMessage name="prenom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="telephone" className="form-label">
                        Nom de l'agence de rattachement
                      </label>
                      <input
                        type="text"
                        name="agence"
                        className="form-control"
                        id="init"
                        placeholder="nom de l'agence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.agence}
                      />
                      <ErrorMessage name="telephone">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="email" className="form-label">
                        Adresse de l'agence
                      </label>
                      <input
                        type="text"
                        name="adresseagence"
                        className="form-control"
                        id="assoc"
                        placeholder=""
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.adresseagence}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="telephone" className="form-label">
                        Code postal
                      </label>
                      <input
                        type="number"
                        name="code"
                        className="form-control"
                        id="init"
                        placeholder="00 00 00 00"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.code}
                       
                      />
                      <ErrorMessage name="telephone">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-4">
                      <label htmlFor="commentaire" className="form-label">
                        Ville
                      </label>
                      <input
                        className="form-control"
                        name="ville"
                        id="pre"
                        placeholder="Ajouter une ville"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.ville}
                      ></input>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="telephone" className="form-label">
                        Code GT
                      </label>
                      <input
                        type="number"
                        name="codegt"
                        className="form-control"
                        id="init"
                        placeholder="00 00 00 00"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.codegt}
                       
                      />
                      </div>
                    <div className="col-md-4">
                      <label htmlFor="actif" className="form-label">
                        Offre de formation
                      </label>
                      <select
                        className="form-select"
                        name="offre"
                        aria-label="Default select example"
                        id="actif"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.offre}
                      >
                        <option value={true} defaultValue>
                          Oui
                        </option>
                        <option value={false}>Non</option>
                      </select>
                      <ErrorMessage name="actif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="actif" className="form-label">
                        Elearning
                      </label>
                      <select
                        className="form-select"
                        name="elearning"
                        aria-label="Default select example"
                        id="actif"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.elearning}
                      >
                        <option value={true} defaultValue>
                          Oui
                        </option>
                        <option value={false}>Non</option>
                      </select>
                      <ErrorMessage name="actif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12 pt-5">
                      <button type="reset" className="btn btn-link">
                        Annuler
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                        //disabled={isSubmitting}
                        //onClick={handleClick}
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default CreateParticipant;
