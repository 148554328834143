import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import Hn from "../../../../../../components/common/Hn";
import PopupFiltre from "../../../../../../components/popup/PopupFiltreParticipant";
import { Link } from "react-router-dom";
import { Spinner } from "../../../../../../components/common/Spinner";
import { toast, ToastContainer } from "react-toastify";
import { useDatatable } from "../../../../../../components/hook/useDatatable";

export default function ParticpantDatatable({
  id_session,
  type
}) {
  const uri = process.env.REACT_APP_BASE_URL;
  const [showfiltre, setShowfiltre] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [action, setAction] = useState(null);
  const [presence, setPresence] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [id, setId] = useState();
  const params = useParams();

  const {selectRow} = useDatatable();

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const handleCloseConfirmationModal = () => {
    setAction(null);
    setPresence(null);
    setShowConfirmationModal(false);
    setId(null);
  }

  const handleCloseFilter = (obj) => {
    setShowfiltre(false);
    searchWithFilter(obj);
  };

  const handleShowFilter = () => {
    setShowfiltre(true);
  };

  function getParticipants() {
    let url;
    if (type === "webinars") {
      url = `${uri}/participant/${params.id}/webinars`;
    } else {
      url = `${uri}/participant/session/${params.id}`;
    }

    return axios
      .get(url, headers)
      .then((result) => {
        setParticipants(result?.data);
      })
      .catch((err) => {
        console.log("err err", err);
      });
  }

  useEffect(() => {
    getParticipants();
  }, []);

  function searchWithFilter(val) {
    if (val) {
      setParticipants([]);
      let participantsFilter = participants.filter(el =>

        (el.apprenant.toLowerCase().includes(val.collaborator.toLowerCase()) && (val.collaborator.length > 0))
        || el?.mailcontact.toLowerCase() === val.email.toLowerCase()
        || (el?.agencymain.toLowerCase().includes(val.agent.toLowerCase()) && (val.agent.length > 0))
        || el?.agenceEmail.toLowerCase() === val.emailAgent.toLowerCase()
      )
      setParticipants(participantsFilter)
    }
  }

  const manageAction = () => {
    setShowLoader(true);
    let promise;
    if(action === 'delete'){
      let url;
      let data;
      if (type === "webinars") {
        url = `${uri}/webinars/delete/particiapnt/${params.id}`;
        data = {participant: id}
      } else {
        url = `${uri}/session/delete/particiapnt/${params.id}`;
        data = {participant: [id]}
      }
      promise = axios.put(url, data, headers)
    }
    if(action === 'presence') {
      let url;
      if (type === 'webinars') {
        url = `${uri}/webinars/edit/presence/${id_session}`
      } else {
        url = `${uri}/session/edit/presence/${id_session}`
      }

      let dataEnd = {
        participant: id,
        presence: presence,
      };
      promise = axios.put(url, dataEnd, headers)
    }
    promise
      .then((response) => {
        getParticipants().then(() =>{
          toast.success(action === 'delete' ? "Suppression effectuée avec succès" : "Présence mise à jour");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch(() => {
        toast.error("Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      if(action === 'delete') {
        let url;
        let data;
        if (type === "webinars") {
          url = `${uri}/webinars/delete/particiapnt/${params.id}`;
          data = {participant: row}
        } else {
          url = `${uri}/session/delete/particiapnt/${params.id}`;
          data = {participant: [row]}
        }
        return axios.put(url, data, headers)
      }
      if(action === 'presence' && presence) {
        let url;
        if (type === 'webinars') {
          url = `${uri}/webinars/edit/presence/${id_session}`
        } else {
          url = `${uri}/session/edit/presence/${id_session}`
        }

        let dataEnd = {
          participant: row,
          presence: presence,
        };
        return axios.put(url, dataEnd, headers)
      }

      return null;
    });

    setShowLoader(true);

    Promise.all(promises).then(() => {
      setSelectedRows([]);
      getParticipants().then(() => {
        toast.success(action === 'delete' ? "Suppression(s) effectuée(s) avec success..." : "Présence(s) mise(s) à jour");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      toast.error("Quelque chose s'est mal passé, Réessayer");
    });
  }

  const columns = [
    {
      dataField: "contactPrenom",
      text: "Collaborateur",
      csvText: "collaborator",
    },
    {
      dataField: "mailcontact",
      text: "Email",
      csvText: "email",
      sort: true,
    },
    {
      dataField: "agencymain",
      text: "Agent",
      csvText: "agent",
      sort: true,
    },
    {
      dataField: "agenceEmail",
      text: "Email agent",
      csvText: "emailAgent",
      sort: true,
    },
    {
      dataField: "presence",
      text: "Présence",
      csvText: "presence",
      formatter: (cell, row) => {
        return (
          <select
            className="form-select"
            onChange={(event) => {
              setId(row.id);
              setAction('presence');
              setPresence(event.target.value);
              setShowConfirmationModal(true);
            }}
          >
            <option value="prevu" selected={row?.presence === "prevu" ? "selected" : ""}>
              Prévu
            </option>
            <option value="absent" selected={row?.presence === "absent" ? "selected" : ""}>
              Absent
            </option>
            <option value="annule" selected={row?.presence === "annule" ? "selected" : ""}>
              Annulé
            </option>
            <option value="present" selected={row?.presence === "present" ? "selected" : ""}>
              Présent
            </option>
          </select>
        );
      },
      sort: true,
    },
    {
      dataField: "offre",
      text: "Offre",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{row.offre ? "Oui" : "Non"}</span>;
      },
    },
    {
      dataField: "metier",
      text: "Manager",
      csvText: "manager",
      sort: true,
      formatter: (cell, row) => {
        return <span>{row.metier === "Manager" ? "Oui" : "Non"}</span>;
      },
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row) => {
        return (
          <div>
            <button
              className="btn btn-link"
              onClick={() => {
                setId(row.id);
                setAction('delete');
                setShowConfirmationModal(true);
              }}
            >
              <img src={require("../../../../../../assets/icons/delete.png")} />
            </button>
          </div>
        );
      },
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button
          className="hidden"
          hidden
          onClick={handleClick}
          name="export"
          id="export"
        >
          Export to CSV
        </button>
      </div>
    );
  };

  const bulkPresence = (value) => {
    setAction('presence');
    setShowConfirmationModal(true);
  }

  const bulkDelete = () => {
    setAction('delete');
    setShowConfirmationModal(true);
  }

  return (
    <>
      <ToastContainer />
      <ToolkitProvider
        keyField="id"
        data={participants}
        columns={columns}
        exportCSV={{ onlyExportSelection: true, exportAll: false }}
        search
      >
        {(props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter">
              <div className="row">
                <div className="col-xl-4 col-lg-1">
                  <Hn title="participants" level="h3" className="pt-4" plus={false} />
                </div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e) =>
                            props.searchProps.onSearch(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="px-0 myxs">
                      <div className="flex">
                        <PopupFiltre
                          showfiltre={showfiltre}
                          closefiltre={handleCloseFilter}
                        />
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowFilter}
                          >
                            <img
                              src={require("../../../../../../assets/icons/filter.png")}
                            />
                          </button>
                        </div>
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => props.csvProps.onExport()}
                          >
                            <img src={require("../../../../../../assets/icons/download.png")} />
                          </button>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkDelete}
                            type="button"
                            className="btn btn-link"
                          >
                            <img src={require("../../../../../../assets/icons/delete.png")} />
                          </button>
                        </div>
                        <div className="ps-3">
                          <select
                            className="form-select h-100"
                            onChange={(event) => {
                              setPresence(event.target.value);
                              bulkPresence();
                            }}
                          >
                            <option value="">
                              --
                            </option>
                            <option value="prevu">
                              Prévu
                            </option>
                            <option value="absent">
                              Absent
                            </option>
                            <option value="annule">
                              Annulé
                            </option>
                            <option value="present">
                              Présent
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div
                        className="btn btn-link"
                        style={{ textDecoration: "none" }}
                      >
                        <Link to={`/${type === "session" ? 'addParticipant' : 'webinair/participant'}/${params.id}`} className="resetBtn ">
                          <img src={require("../../../../../../assets/icons/add.png")} />
                          <span> Ajouter des participants</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              {...props.baseProps}
              classes="table-dark mt-5"
              bordered={false}
              selectRow={selectRow(selectedRows, setSelectedRows)}
              hover
              pagination={paginationFactory(options)}
            />

          </div>
        )}
      </ToolkitProvider>

      {showLoader && <Spinner />}

      <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
        <Modal.Header closeButton>
          <Modal.Title className="modaltitle">
            {action === 'delete' ? (id != null ? 'Etes-vous sûr(e) de vouloir supprimer l\'actions de formation' : 'Etes-vous sûr(e) de vouloir supprimer la selection') :
              id != null ? 'Etes-vous sûr(e) de vouloir modifier la présence de l\'utilisateur' : 'Etes-vous sûr(e) de vouloir modifier la présence de la selection'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
            Annuler
          </Button>
          <Button
            type="button"
            variant="btn btn-primary"
            onClick={() => {
              id ? manageAction() : manageBulk()
            }}
          >
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
