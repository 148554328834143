import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Hn from "../../../../components/common/Hn";
import "./provider.css";

//import { useHistory, useParams } from "react-router-dom";

function ReadProvider() {
  const params = useParams();
  const [providerOne, setProvider] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const uri = process.env.REACT_APP_BASE_URL;

  useEffect(() => {

    provider(params.id);
  }, []);
//récupérer un prestataire par son id
  function provider(id) {

    axios
      .get(`${uri}/provider/${id}`)
      .then((result) => {

        setProvider(result?.data);

      })
      .catch((err) => {
        console.log("err location", err);
      });
  }

  return (
    <div className="banner-provider container-fluid">
      <div className="row">
        <div className=" container lists pe-4">
        <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/prestataires">Formations présentielles - Prestataires</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {providerOne?.contact_name}
              </li>
            </ol>

          </nav>
          <a type="submit" className=" btn-link resetBtn" href="/admin/prestataires">
                Retour </a>
          <div className="row">

            <div className="col-md-6">
              <Hn title={providerOne?.contact_name + ' ' + providerOne?.contact_surname} level="h2" className="pt-4" />
            </div>
            <div className="col-md-6">
              <a
                href={"/admin/prestataire-edit/" + providerOne.id}
                className="btn btn-primary float-end mt-4"
                type="button"
              >
                Modifier
              </a>
            </div>
          </div>

          {/* lists-filter */}
          <div className="row mt-30 mb-135">

            <div className="col-12">
              <div className="h-100 p-5 text-white bg-dark rounded-3">
                <Hn
                  title={providerOne?.contact_name + ' ' + providerOne?.contact_surname}
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />

                <div className="form-elements">

                  <div className="row g-3">
                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label">
                        Réference
                      </label>
                      <p>{providerOne?.ref}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="nom" className="form-label">
                        Nom
                      </label>
                      <p>{providerOne?.name}</p>
                    </div>
                    <div className="col-md-4">

                    </div>

                    <div className="col-md-8">
                      <label htmlFor="denomi" className="form-label">
                        Thème d'intervention
                      </label>
                    <p>
                    {providerOne.topic_interv ?
                      (
                        providerOne.topic_interv.map((topic, index) => {
                          return (<span key={index}>
                            {topic?.name} {index<providerOne.topic_interv.length-1 ? ", " : ""}
                          </span>)
                        })
                      ):""
                      }
                    </p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="denomi" className="form-label">
                        Adresse
                      </label>
                      <p>{providerOne?.address}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="codepos" className="form-label">
                        Code postal
                      </label>
                      <p>{providerOne?.zip}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="codepos" className="form-label">
                        Ville
                      </label>
                      <p>{providerOne?.city}</p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                        Pays
                      </label>
                      <p>{providerOne?.country}</p>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="pays" className="form-label">
                        Site Internet
                      </label>
                      <p>{providerOne?.website}</p>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="coutj" className="form-label">
                        Nom du contact
                      </label>
                      <p>{providerOne?.contact_name}</p>
                    </div>

                    <div className="col-4">
                      <label htmlFor="comm" className="form-label">
                        Prénom du contact
                      </label>
                      <p>
                        {providerOne?.contact_surname}
                      </p>
                    </div>
                    <div className="col-4">
                      <label htmlFor="comm" className="form-label">
                        Téléphone du contact
                      </label>
                      <p>
                        {providerOne?.tel}
                      </p>
                    </div>
                    <div className="col-4">
                      <label htmlFor="comm" className="form-label">
                        Email du contact
                      </label>
                      <p>
                        {providerOne?.email}
                      </p>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReadProvider;
