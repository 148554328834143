import { ErrorMessage, Formik } from "formik";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Add from "../../../../assets/icons/add.png";
import Hn from "../../../../components/common/Hn";
import AxioServices from "../../../../services/AxioServices";
import "./locationForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserHistory } from "history";

const validationSchema = Yup.object().shape({
  nom: Yup.string().required("Champ obligatoire."),
  prenom: Yup.string().required("Champ obligatoire."),
  type: Yup.string().required("Champ obligatoire."),
  reference: Yup.string().required("Champ obligatoire."),
});

function CreateFormer() {
  let headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  };
  const fileRef = useRef();
  //let history = useHistory();
  const initialInputState = {
    type: "",
    nom: "",
    prenom: "",
    reference: "",
    image: "",
    telephone: "",
    email: "",
    actif: "",
    commentaire: "",
  };
  const [eachEntry, setEachEntry] = useState(initialInputState);
  //const [pays, setPays] = useState("pays");
  const {
    type,
    nom,
    prenom,
    reference,
    image,
    telephone,
    email,
    actif,
    commentaire,
  } = eachEntry;
  let history = createBrowserHistory({ forceRefresh: true });
  let toastId = null;
  const handleChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };
  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
//créer un nouveau formateur
  const handleSubmit = (values) => {
    
    var datasend = new FormData();
    datasend.append("organize", values.type);
    datasend.append("firstName", values.nom);
    datasend.append("lastName", values.prenom);
    datasend.append("ref", values.reference);
    datasend.append("brochure", values.image);
    datasend.append("tel", values.telephone);
    datasend.append("email", values.email);
    datasend.append("status", values.actif);
    datasend.append("comment", values.commentaire);

   

    AxioServices.create("former/new", datasend, headers)
      .then((result) => {
        
        displayToast(
          "Csuccess",
          "Formateur ajouté avec success, Redirection..."
        );
        setTimeout(() => {
          history.push("/admin/liste-formateurs");
        }, 3000);
      })
      .catch((err) => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  };

  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row pe-3">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/liste-formateurs">
                  Formations présentielles - Formateurs
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ajouter un formateur
              </li>
            </ol>
          </nav>
          <div className="p-5 text-white bg-dark rounded-3">
            <Hn
              title="Ajouter un formateur"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  type: "",
                  nom: "",
                  prenom: "",
                  reference: "",
                  image: "",
                  telephone: "",
                  email: "",
                  actif,
                  commentaire,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="row g-3">
                    <div className="col-md-4">
                      <label className="form-label">Formateur*</label>
                      <select
                        className="form-select"
                        name="type"
                        aria-label="Sélectionner dans la liste"
                        id="former"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.type}
                      >
                        <option value="" default>
                          Sélectionner dans la liste
                        </option>
                        <option value="interne" defaultValue>
                          Formateur interne
                        </option>
                        <option value="externe">Formateur externe</option>
                      </select>

                      <ErrorMessage name="type">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Nom*</label>
                      <input
                        type="text"
                        name="nom"
                        className="form-control"
                        id="init"
                        placeholder="Nom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nom}
                      />
                      <ErrorMessage name="nom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Prénom*</label>
                      <input
                        type="text"
                        name="prenom"
                        className="form-control"
                        id="init"
                        placeholder="prénom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.prenom}
                      />
                      <ErrorMessage name="prenom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-8">
                      <label className="form-label">Référence*</label>
                      <input
                        type="text"
                        name="reference"
                        className="form-control"
                        id="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.reference}
                        autoComplete="off"
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Image</label>
                      <span
                        className="form-control"
                        onClick={() => fileRef.current.click()}
                      >
                        <img src={Add} className="pe-1" />{" "}
                        {fileRef?.current?.value
                          ? fileRef?.current?.value
                          : "Ajouter une image"}
                      </span>

                      <input
                        ref={fileRef}
                        multiple={false}
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".png,.jpg,.gif"
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Téléphone</label>
                      <input
                        type="number"
                        name="telephone"
                        className="form-control"
                        id="init"
                        placeholder="00 00 00 00"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.telephone}
                      />
                      <ErrorMessage name="telephone">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="assoc"
                        placeholder="Email@gmail.com"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Actif</label>
                      <select
                        className="form-select"
                        name="actif"
                        aria-label="Default select example"
                        id="actif"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.actif}
                      >
                        <option value={true} defaultValue>
                          Oui
                        </option>
                        <option value={false}>Non</option>
                      </select>
                      <ErrorMessage name="actif">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label className="form-label">Commentaire</label>
                      <textarea
                        className="form-control"
                        name="commentaire"
                        id="pre"
                        rows="3"
                        placeholder="Ajouter un commentaire"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.commentaire}
                      ></textarea>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12 pt-5">
                      <a
                        type="reset"
                        className="btn btn-link resetBtn"
                        href="/admin/liste-formateurs"
                      >
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                        //disabled={isSubmitting}
                        //onClick={handleClick}
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default CreateFormer;
