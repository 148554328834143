import { useState } from 'react';
import './jumbotron.css';
import dateFormat from "dateformat";

const Footer =()=>{
  return(
    <>
    <div className="footerDoc">
  <p>Le service formation</p>
      <p>formation@guy-hoquet.com</p>
      <p>01 58 46 44 44</p>
      </div>
    </>)
}
const Convocation = ({ item }) => {
  return (<>
    <h4 className="boxTitle">Votre Convocation à une formation est disponible</h4>
    <p className="mt-3">Bonjour {item?.name_contact},</p>
    <p>Nous avons le plaisir de vous convier à la formation {item?.name_training} qui se déroulera
      du <b>{dateFormat(item?.date_start, "dd/mm/yyyy")}</b> au <b>{dateFormat(item?.date_end, "dd/mm/yyyy")}</b> aux horaires suivantes de #heure début# à #heure fin#
      Nous vous donnons rendez-vous à : {item?.location} En cas d’empêchement, merci de nous en informer au plus vite.
      Le pôle formation se tient à votre disposition pour répondre à toutes vos questions.
    </p>
<Footer></Footer>
  </>
  )
}
const Confirmation = ({ item }) => {
  return (<>
    <h4 className="boxTitle">Votre Confirmation d’inscription est disponible</h4>
    <p className="mt-3">Bonjour <b>{item?.name_contact}</b> ,</p>
    <p>Nous avons le plaisir de vous confirmer votre inscription à la formation <b>{item?.name_training}</b>  qui se déroulera
      du <b>{dateFormat(item?.date_start, "dd/mm/yyyy")}</b> au <b>{dateFormat(item?.date_end, "dd/mm/yyyy")}</b> .<br></br>
      Vous recevrez 48h avant le début de la formation la convocation finale par mail.

      Dans l’attente de vous retrouver, merci de répondre au questionnaire de positionnement de votre formation en <a href="https://forms.office.com/r/D0iNJYZNdC">cliquant ici</a>

      En cas d’empêchement, merci de nous en informer au plus vite.

      Le pôle formation se tient à votre disposition pour répondre à toutes vos questions.

    </p>
    <Footer></Footer>
  </>
  )
}
const Annulation = ({ item }) => {
  return (<>
    <h4 className="boxTitle">Annulation de votre formation</h4>
    <p className="mt-3">Bonjour <b>{item?.name_contact}</b>,</p>
    <p>Nous sommes au regret de vous informer que la formation  <b>{item?.name_training} </b>
    pour laquelle vous étiez inscrit aux dates suivantes : <b>{dateFormat(item?.date_start, "dd/mm/yyyy")}</b> au <b>{dateFormat(item?.date_end, "dd/mm/yyyy")}</b>
    ne remplit pas les conditions nécessaires à son maintien. <br></br>

Le pôle formation se tient à votre disposition pour répondre à toutes vos questions. 

    </p>
    <Footer></Footer>
  </>
  )
}
const Convention = ({ item }) => {
 
  
  return (<>
    <h4 className="boxTitle">Convention de formation</h4>
    <p className="mt-3">Bonjour <b>{item?.name_contact}</b>,</p>
    <p>Suite à l’inscription à la formation <b>{item?.name_training}</b> , 
    nous avons le plaisir de vous informer que la convention de formation est disponible 
    sur votre plateforme de formation dans votre espace profil.  <br></br>

Le pôle formation se tient à votre disposition pour répondre à toutes vos questions. 


    </p>
    <Footer></Footer>
  </>
  )
}
const Suiteformation = ({ item }) => {
  return (<>
    <h4 className="boxTitle">Convention de formation</h4>
    <p className="mt-3">Bonjour <b>{item?.name_contact}</b>,</p>
    <p>Merci d’avoir participé à la formation  <b>{item?.name_training}</b> , 
    Dans une volonté constante de vous proposer la meilleure expérience de formation possible,
     merci de bien vouloir prendre quelques minutes pour nous faire part de votre avis en 
    <a href="https://forms.office.com/r/98kxprr3A6">cliquant ici </a>

</p><p>Votre retour nous est précieux afin de répondre au mieux à vos attentes.</p>

<p>Le pôle formation se tient à votre disposition pour répondre à toutes vos questions. 


    </p>
    <Footer></Footer>
  </>
  )
}
const Emargement = ({ item }) => {
  return (<>
    <h4 className="boxTitle">Convention de formation</h4>
    <p className="mt-3">Bonjour <b>{item?.name_contact}</b>,</p>
    <p>Nous avons le plaisir de vous faire parvenir les feuilles d’émargement de la formation  <b>{item?.name_training}</b> , 
    qui se déroulera du <b>{dateFormat(item?.date_start, "dd/mm/yyyy")}</b> au <b>{dateFormat(item?.date_end, "dd/mm/yyyy")}</b>
    Merci de les faire signer à tous les participants et de nous les retourner au plus vite. 
<br></br>Le pôle formation se tient à votre disposition pour répondre à toutes vos questions. 

    </p>
    <Footer></Footer>
  </>
  )
}

export default function Jumbotron({data,type}) {
  //const [type, setType] = useState(data?.data?.type)

  
  
  const RenderSwitch = ({ param }) => {


    
    switch (param) {
      case 'convocation':
        return <Convocation item={data}></Convocation>;
      case 'confirmation':
        return <Confirmation item={data}></Confirmation>;
      case 'annulation':
        return <Annulation item={data}></Annulation>;
      case 'suiteformation':
        return <Suiteformation item={data}></Suiteformation>;
      case 'convention':
        return <Convention item={data}></Convention>;
      case 'emargement':
        return <Emargement></Emargement>;
      default :
      return "Aucun message disponible"
    }

  }
  return (
    <div>
      <div className="text-white rounded-3 jumbotron">
        <div className="box h-100 p-5 ">
         <RenderSwitch param={type?.trim()}></RenderSwitch>
       

      
          
        </div>
      </div>
    </div>

  );
}





