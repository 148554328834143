import React, { useRef, useState, useEffect } from "react";
import { trackPromise } from 'react-promise-tracker';
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createBrowserHistory } from 'history';
import "./createElearning.css";
import Hn from "../../../../../components/common/Hn";
import Add from "../../../../../assets/icons/add.png";
import axios from "axios";

import { ProgressBar } from "react-bootstrap";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEditor } from "../../../../../components/hook/useEditor";

//const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']
const validationSchema = Yup.object().shape({
  reference: Yup.string().required("La référence est obligatoire"),
  intitule: Yup.string().required("L'intitulé de formation est obligatoire"),
  image: Yup.mixed().nullable().required("Choissisez une image SVP"),
  theme: Yup.string().required("Veuillez SVP choisir un thème"),
  //   theme: Yup.object().shape({
  //     label: Yup.string().required("Required"),
  //     value: Yup.string().required("Required")
  // }),

  parcours_associe: Yup.string(),
  prerequis: Yup.string(),
  object: Yup.string(),
  program: Yup.string(),
  validation: Yup.string().required("Le taux de validation est obligatoire."),
  pdf: Yup.string().nullable(),
  duree: Yup.number().required("La durée de la formation est obligatoire."),
  commentaire: Yup.string(),

});

function CreateElearning() {
  const managerRef = useRef();
  const misEnAvantRf = useRef();
  const ObligationDeFormationRef = useRef();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const fileRef = useRef();
  const pdfRef = useRef();
  const [themes, setThemes] = useState([]);
  const imageValue = Yup.ref('image');
  const [trainings, setTrainings] = useState([]);
  const [elearnings, setElearnings] = useState([]);
  const { editorConfiguration } = useEditor();

  useEffect(() => {
    getTrainings();
    getElearnings();
  }, []);
  const uri = process.env.REACT_APP_BASE_URL;
// Récupérer la liste des formations elearning
  function getElearnings() {

    trackPromise(
    axios.get(uri + "/elearning")
        .then((result) => {
          setElearnings(result?.data);
        })
        .catch((err) => {
          console.log("err location", err);
        }));
  }

// Récupérer la liste des formations présentielles
  function getTrainings() {
    trackPromise(
      axios
        .get(uri + "/training")
        .then((result) => {
          setTrainings(result?.data);
        })
        .catch((err) => {
          console.log("err location", err);
        }));
  }
 // Changer la valeur d'un checkbox
  const handleChangecheckbox = (e) => {

    if (e.target.checked) {
      e.target.value = 1;
    } else {
      e.target.value = 0;
    }
  }

  let history = createBrowserHistory({ forceRefresh: true })

  // Continuez avec l'exécution du gestionnaire de soumission
  // Créer une action de formation elearning

  const handleSubmit = (values) => {


    const options = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setUploadPercentage(percent);
        }
      }
    };


    var data = new FormData();
    data.append('ref', values.reference);
    data.append('intitule', values.intitule);
    data.append('objectifs', values.object);

    data.append('programme_de_formation', values.program);
    data.append('prerequis', values.prerequis);
    data.append('parcours_associe', values.parcours_associe);

    data.append('mis_en_avant', 0);
    data.append('manager', 0);
    data.append('obligation_de_formation', 0);


    if (misEnAvantRf.current.checked) {
      data.append('mis_en_avant', 1);
    }
    if (managerRef.current.checked) {
      data.append('manager', 1);
    }
    if (ObligationDeFormationRef.current.checked) {
      data.append('obligation_de_formation', 1);
    }

    data.append('actif', values.etat);
    data.append('theme', values.theme);
    data.append('validation', values.validation);

    data.append('commentaire', values.commentaire);
    data.append('image', values.image);

    data.append('duree', values.duree);
    data.append('fichier_programme', values.pdf);




    trackPromise(
    axios.post(uri + "/elearning/new", data, options)
        .then((result) => {

          setUploadPercentage(100);

          setTimeout(() => {
            setUploadPercentage(0);
          }, 1000);

          history.push("/admin/creer-chapitre-e-learning/" + result?.data?.id);
        })
        .catch((err) => {
          console.log("err training", err);
        }));

  };
  // Retour la liste des thématiques
  function getThem() {
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    trackPromise(
    axios.get(uri + "/thematic", headersFiles)
        .then((result) => {
          setThemes(result?.data);

        })
        .catch((err) => {
          console.log("err theme", err);
        }));
  }
  useEffect(() => {
    getThem();
  }, []);

  return (
    <div className="container-fluid mb-5">
      <div className="row ">
        <div className="container lists pe-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/formation-e-learning">Formations e-learning - Action de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Créer une action de formation
              </li>
            </ol>
          </nav>
          <div className=" p-5 text-white bg-dark rounded-3">
            <Hn
              title="Créer une action de formation"
              level="h4"
              className="h4-size"
              plus={false}
            />
            <Hn
              title="Étape 1 : Informations générales"
              level="p"
              className="py-2 fw-bold"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  reference: "",
                  intitule: "",
                  image: null,
                  theme: "",
                  parcours_associe: "",
                  prerequis: "",
                  object: "",
                  program: "",
                  pdf: null,
                  duree: "",
                  etat: '0',
                  commentaire: "",
                  miseavant: 0,
                  manager: "0",
                  validation: 80,
                  obligation: 0,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label">
                        Référence*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ref"
                        name="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reference}
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-8">
                      <label htmlFor="init" className="form-label">
                        Intitulé*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="intitule"
                        id="init"
                        placeholder="Intitulé"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.intitule}
                      />
                      <ErrorMessage name="intitule">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="image" className="form-label">
                        Image*
                      </label>
                      <span
                        className="form-control"
                        onClick={() => fileRef.current.click()}
                      >

                        <img src={Add} className="pe-1" />  {fileRef?.current?.value ? fileRef?.current?.value : 'Ajouter une image'}
                      </span>
                      <p className="formatImg">Format : jpg, png / 10 mo max / Résolution conseillée : xxx</p>
                      <input
                        ref={fileRef}
                        multiple={false}
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".png,.jpg,.gif"
                      />
                      <ErrorMessage name="image">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                      {/* <input className="form-control" type="file" id="image" placeholder="Ajouter une image" /> */}
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="theme" className="form-label">
                        Thème*
                      </label>
                      <select

                        className="form-select"
                        aria-label="Default select example"
                        name="theme"
                        id="theme"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.theme}
                      >
                        <option value="">Sélectionner dans la liste</option>
                        {themes.map((theme, i) => {
                          return (
                            <option value={theme.id}>{theme.name}</option>
                          );
                        })}
                      </select>
                      <ErrorMessage name="theme">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="parcours_associe" className="form-label">
                        Parcours associé
                      </label>

                      <select

                        className="form-select"
                        name="parcours_associe"
                        id="parcours_associe"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.parcours_associe}
                      >
                        <option value="">Sélectionner dans la liste</option>

                        {elearnings.map((learning, i) => {
                          return (
                            <option value={learning.id} key={i}>{learning.intitule}</option>
                          );
                        })}

                        {trainings.map((train, i) => {
                          return (
                            <option value={train.id} key={i}>{train.title}</option>
                          );
                        })}

                      </select>


                      <ErrorMessage name="parcours_associe">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="pre" className="form-label">
                        Pré-requis et public visé
                      </label>
                      <CKEditor
                        editor={ ClassicEditor }
                        config={ editorConfiguration }
                        data={values.prerequis}
                        onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          setFieldValue('prerequis', data)
                        } }
                      />
                      <ErrorMessage name="prerequis">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="object" className="form-label">
                        Objectifs
                      </label>
                      <CKEditor
                        editor={ ClassicEditor }
                        config={ editorConfiguration }
                        data={values.object}
                        onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          setFieldValue('object', data)
                        } }
                      />
                      <ErrorMessage name="object">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="pre" className="form-label">
                        Programme de la formation
                      </label>
                      <CKEditor
                        editor={ ClassicEditor }
                        config={ editorConfiguration }
                        data={values.program}
                        onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          setFieldValue('program', data)
                        } }
                      />
                      <ErrorMessage name="program">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="prog" className="form-label">
                        Programme de la formation
                      </label>
                      <span
                        className="form-control"
                        onClick={() => pdfRef.current.click()}
                      >
                        <img src={Add} className="pe-1" />
                        {pdfRef?.current?.value ? pdfRef?.current?.value : 'Ajouter un pdf'}
                      </span>

                      <input
                        ref={pdfRef}
                        multiple={false}
                        type="file"
                        name="pdf"
                        onChange={(event) => {
                          setFieldValue("pdf", event.currentTarget.files[0]);
                        }}
                        hidden
                        accept=".pdf"
                      />
                      <ErrorMessage name="pdf">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>

                      {values.pdf && uploadPercentage > 0 && (
                        <div className="row mt-3">
                          <div className="col pt-1">
                            <ProgressBar
                              now={uploadPercentage}
                              striped={true}
                              label={`${uploadPercentage}%`}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="duree" className="form-label">
                        Durée théorique (en minutes)*
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="duree"
                        id="duree"
                        placeholder="60"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.duree}
                      />
                      <ErrorMessage name="duree">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="actif" className="form-label">
                        Actif
                      </label>
                      <select
                        className="form-select"
                        name="etat"
                        aria-label="Default select example"
                        id="actif"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.etat}
                      >
                        <option value="1">
                          Oui
                        </option>
                        <option value="0">Non</option>
                      </select>
                      <ErrorMessage name="etat">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="comment" className="form-label">
                        Commentaire
                      </label>
                      <CKEditor
                        editor={ ClassicEditor }
                        config={ editorConfiguration }
                        data={values.commentaire}
                        onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          setFieldValue('commentaire', data)
                        } }
                      />
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="validation" className="form-label">
                        Valdation
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="validation"
                        id="validation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.validation}
                      />
                      <ErrorMessage name="validation">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>

                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={misEnAvantRf}
                          name="miseavant"
                          type="checkbox"
                          id="check"
                          onChange={e => handleChangecheckbox(e)}
                        />
                        <label className="form-check-label" htmlFor="check">
                          Mettre en avant dans le catalogue
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={managerRef}
                          name="manager"
                          type="checkbox"
                          id="check2"
                          onChange={e => handleChangecheckbox(e)}

                        />
                        <label className="form-check-label" htmlFor="check2">
                          Spécifique au manager
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <div className="form-check">
                        <input
                          className="form-check-input square-check"
                          ref={ObligationDeFormationRef}
                          name="obligation"
                          type="checkbox"
                          id="check3"
                          onChange={e => handleChangecheckbox(e)}

                          value={values.obligation}
                        />
                        <label className="form-check-label" htmlFor="check3">
                          Participe à l'obligation de formation
                        </label>
                      </div>
                    </div>

                    <div className="col-12 mt-5">
                      <a
                        type="reset"
                        className="btn btn-link text-decoration-none"
                        href="/admin/formation-e-learning"
                      >
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                      // disabled={isSubmitting}
                      >
                        Suivant
                      </button>

                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default CreateElearning;
