import Hn from "../../../../components/common/Hn";
import "./faceTraining.css";
import Datatable from "./Datatable.js";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FaceTraining() {
  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row">
        <div className=" container lists pe-4">
          <div className="lists-title">
            <Hn
              title="Formations présentielles"
              level="h1"
              className="pt-5"
              plus={false}
            />
            <Hn title="Actions de formation" level="h2" className="pt-4" />
          </div>

          <div className="mt-20 trainingTable">
              <Datatable />
          </div>

        </div>
      </div>
    </div>
  );
}

export default FaceTraining;
