import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Add from "../../../../../assets/icons/add.png";
import Play from "../../../../../assets/icons/Play.png";
import Hn from "../../../../../components/common/Hn";
import "./createUnite.css";
import { trackPromise } from 'react-promise-tracker';
import toast, { Toaster } from 'react-hot-toast';
import edit from "../../../../../assets/icons/edit.png";
import { Button, Modal, ProgressBar } from "react-bootstrap";

const validationSchema = Yup.object().shape({
    titre: Yup.string().required("Le titre est obligatoire"),
});

function EditChapitre({ chapitreToBeEdited }) {
    const [showModal, setShowModal] = useState(false);
    const uri = process.env.REACT_APP_BASE_URL;
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const fileRef = useRef();

    const onHandelStatus = (touched, error, value) => {
        if (error && touched) {
            return "danger";
        } else if (value) {
            return "info";
        } else {
            return "warning";
        }
    };

    const deleteChapitre = (chapitre) => {
        trackPromise(
            axios.post(uri + "/chapitre/" + chapitre?.id)
                .then((result) => {
                    hideModal();
                    toast.success("Ce chapitre a bien été modifié!");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                })
                .catch((err) => {
                    hideModal();
                    toast.error("Désolé vous ne pouvez pas modifier ce chapitre");
                    console.log("err sessions", err);
                }));
    }

    const showModalFunction = (chapitre) => {
        setShowModal(chapitre);
    }

    const hideModal = () => {
        setShowModal(false);
    }



    const handleSubmit = (values) => {

        //upload
        const options = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;

                let percent = Math.floor((loaded * 100) / total);

                if (percent < 100) {
                    setUploadPercentage(percent);
                }
            }
        };



        var data = new FormData();
        data.append('titre', values.titre);

        trackPromise(
            axios.post(
                uri + "/chapitre/" + chapitreToBeEdited?.id + "/edit",
                data,
                options
            )
                .then(() => {
                    setUploadPercentage(100);

                    setTimeout(() => {
                        setUploadPercentage(0);
                    }, 1000);

                    values.titre = '';
                    document.getElementById("formulaire-edit-unite").reset();
                    toast.success("Ce chapitre a bien été modifiée!");
                    window.location.reload();
                })
                .catch((err) => {
                    toast.error("Action non autorisée!");
                    console.log("err training", err);
                    setUploadPercentage(0);
                }));


    };

    useEffect(() => {

    }, [chapitreToBeEdited]);


    return (
        <>

            <li>
                <span className="dropdown-item cursor-pointer" onClick={(e) => showModalFunction(chapitreToBeEdited)}>
                    <img src={edit} className="pe-1" />
                    Modifier
                </span>
            </li>
            <Modal show={showModal} onHide={(e) => hideModal()} className="deleteModal">
                <Modal.Header closeButton>
                    <Modal.Title className="modaltitle">
                        Modification du chapitre "{chapitreToBeEdited?.titre}"
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row g-3">
                        <Formik
                            initialValues={{
                                titre: chapitreToBeEdited?.titre,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }) => (
                                <form className="row g-3" id="formulaire-edit-unite" onSubmit={handleSubmit}>

                                    <div className="col-md-12">
                                        <label className="form-label">Titre</label>
                                        <input
                                            status={onHandelStatus(
                                                touched.ref,
                                                errors.ref,
                                                values.ref
                                            )}
                                            type="text"
                                            className="form-control"
                                            id="titre"
                                            name="titre"
                                            placeholder="Ajouter un titre"
                                            onChange={handleChange("titre")}
                                            value={values.titre}
                                            onBlur={handleBlur("titre")}
                                            errors={errors.titre}
                                        />
                                        <ErrorMessage name="titre">
                                            {(msg) => <div className="text-danger">{msg}</div>}
                                        </ErrorMessage>
                                    </div>

                                   <div></div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <Button variant="" onClick={(e) => hideModal()} style={{ color: "#006FFF" }}>
                                                Annuler
                                            </Button>
                                        </div>

                                        <div className="col-md-6 text-right">
                                            <Button
                                                type="submit"
                                                variant="btn btn-primary"
                                                onClick={handleSubmit}
                                            >
                                                Modifier
                                            </Button>
                                        </div>
                                    </div>

                                </form>
                            )}
                        </Formik>

                    </div>
                </Modal.Body>

            </Modal>

        </>
    );
}

export default EditChapitre;
