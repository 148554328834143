import { FormGroup } from "reactstrap";
import Pagination from 'react-bootstrap/Pagination';

import { useEffect, useState } from "react";
import axios from "axios";
import Mostformation from "../../components/owl counter/Mostformation";
import "./Header.css";
import "./Search.css";

export default function Search() {
  const [elearnings, setElearnings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const uri = process.env.REACT_APP_BASE_URL;
  const IMG_URL = process.env.REACT_APP_PIC_ELEARNING_IMG;

  let active = 2;
  let items = [];

  for (let number = 1; number <= total / 50; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active} onClick={() => setCurrentPage(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const paginationBasic = (
    <div className="float-e">
      <Pagination size="lg">{items}</Pagination>
    </div>
  );


  const search= (event)=>{
    axios
      .get(uri + "/training/search?page="+currentPage+"&search="+event.target.value )
      .then((result) => {
        setElearnings(result?.data);
        setTotal(result?.data.total);
      })
  }

  const getPage = (search) => {

    axios
      .get(uri + "/training/search?page="+currentPage+"&search="+search )
      .then((result) => {
        setElearnings(result?.data);
        setTotal(result?.data.total);
      })
  };

  useEffect(() => {
    getPage('');
  }, []);

  return (
    <div className="search p-3">
      <FormGroup className="searchinput mb-0">
        <input
          type="recherche"
          placeholder="Recherche"
          onChange={search}
        />
      </FormGroup>

      <Mostformation
        details={"detailformations"}
        //  data={elearnings.slice(0,100)}
        data={elearnings?.data}
        url={IMG_URL}
        carousel={false}
        switchs={true}

      />
       {paginationBasic}
    </div>
  );
}
