import { Fragment } from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import CadreItems from "../common/CadreItems";
import "./listformation.css";
import { Link } from "react-router-dom";

export default function Listwiththematic({
                                           title,
                                           formation,
                                           details,
                                           url,
                                           theme,
                                           isflip,
                                           elearning,
                                           isElearning,
                                           category = 'training'
                                         }) {
  const options = {
    items: 4,
    responsiveClass: true,
    margin: 27,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.25,
      },
      400: {
        items: 1.3,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className={`e-learning  p-3`}>
          <div className="row">
            <div className="col offset-1">
              <div className={`titreE pt-4 `}>
                <p>{title}</p>
              </div>
            </div>
            <div className="col">
              <div className={`more-info `}>
                <Link rel="opener" to={"/listmore/" + theme + "/" + category}>
                  Voir tout
                </Link>
              </div>
            </div>
          </div>

          <OwlCarousel className="owl-theme" {...options}>
            {formation?.map((item, index) => {
              return (<Fragment key={index}>
                {item?.theme?.name === title ? (
                    <div className="item">
                      <CadreItems
                        title={item?.title || item?.intitule}
                        parcours={item?.parcours_associe}
                        id={item?.id}
                        base_url={url} image={item?.image}
                        index={index} details={details}
                        switchs isflip={isflip}
                        elearning={elearning}
                        isElearning={isElearning}/>
                    </div>)
                  : ""}
              </Fragment>)
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}
