import axios from "axios";
import { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import Hn from "../../components/common/Hn";
import Mostformation from "../../components/owl counter/Mostformation";
import Listwiththematic from "../../components/owl/Listwiththematic";
import ListingWebinaire from "../webinair/ListingWebinaire";
import { trackPromise } from "react-promise-tracker";
export default function FormationPresentiel({ title }) {
  const [trainingMost, setTrainingMost] = useState([]);
  const [trainingMostSorted, setTrainingMostSorted] = useState([]);
  const [slideritem, setSlideritem] = useState([]);
  const uri = process.env.REACT_APP_BASE_URL;
  const IMG_URL = process.env.REACT_APP_PIC_FACETRAINING_PATH;
  const [thematics, setThematics] = useState([]);
  let [slider, setSlider] = useState([]);
  const [traininge, setTraininge] = useState([]);
//récupérer les TOP 10 des formations présentielles selon le nombre d’inscrit aux sessions
  function getTrainingMost() {
    axios
      .get(uri + "/training")
      .then((result) => {
        let trainingMostSortedD = result?.data?.filter(
          (el) => el?.status === true
        );

        trainingMostSortedD.sort(function (a, b) {
          return b.sessions.length - a.sessions.length;
        });

        setTrainingMostSorted(trainingMostSortedD);
        setTrainingMost(result?.data?.filter((el) => el?.status === true));

        if (
          result?.data.filter((el) => el.forward_catalog === true).length > 0
        ) {
          setSlideritem(
            result?.data?.filter(
              (el) => el.forward_catalog === true && el?.status === true
            )
          );
        } else {
          setSlideritem(
            result?.data?.filter((el) => el?.status === true).slice(0, 3)
          );
        }
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }

  //récupérer tous les webinars
  function getWebinars() {
    trackPromise(
      axios
        .get(uri + "/webinars/")
        .then((result) => {
          let temporary_slider = [];
          result?.data.filter((item, index) => {
            if (item.forward_catalog === true) {
              let new_item = {
                ...item,
                type_icone: "webinar",
                from: "webinar",
              };
              temporary_slider.push(new_item);
            }
          });
          temporary_slider = [...slider, ...temporary_slider];

          slider = temporary_slider;
          setSlider(temporary_slider);
        })
        .catch((err) => {
          console.log("err webinars", err);
        })
    );
  }

  //récupérer toutes les formations présentielles selon chaque théme
  function getTraining() {
    axios
      .get(uri + "/training/")
      .then((result) => {
        setTraininge(result?.data?.filter((el) => el?.status === true));

        getThematic(result?.data?.filter((el) => el?.status === true));
        let temporary_slider = [];
        result?.data.filter((item, index) => {
          if (item?.status == true && item?.forward_catalog == true) {
            let new_item = { ...item, from: "presentiel" };
            temporary_slider.push(new_item);
          }
        });
        temporary_slider = [...slider, ...temporary_slider];

        slider = temporary_slider;
        setSlider(temporary_slider);
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }
  //récupérer tous les thémes des formations présentielles
  function getThematic(info) {

    setThematics(info.map((el)=>{return{name:el.theme?.name,slug:el.theme?.slug}}))
  }

  useEffect(() => {
    getTrainingMost();
    getWebinars();
    getTraining();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Banner
          title="Formations"
          data={slider}
          details="detailformation"
          url={"detailformation"}
          from="presentiel"
        />
      </div>
      <div className="pb-4 pt-5">
        <ListingWebinaire />
      </div>
      {trainingMostSorted.length != 0 ?
      <>
      <Hn title="Top 10 des formations" level="h2" className="pt-5" />

      <Mostformation
        details={"detailformation"}
        data={trainingMostSorted.slice(0, 10)}
        url={IMG_URL}
        carousel
        isflip={false}
      />
</>
 : ""
}
      <Hn title="Les formations en présentiel" level="h3" />
       {thematics &&
       thematics?.sort((a, b) => a.name > b.name ? 1 : -1).filter((v,i,a)=>a.findIndex(v2=>(v2.slug===v.slug))===i).map((item, i) => {
          return (
            <>
              <Listwiththematic
                title={item?.name}
                formation={traininge}
                details={"detailformation"}
                key={i}
                url={IMG_URL}
                theme={item?.slug}
                isflip={false}
                isElearning={false}
                />
              </>
            );
          })}
    </div>
  );
}
