import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import Modal from "react-bootstrap/Modal";
import dateFormat from "dateformat";
import Icon from "../../components/common/Icon";
import Hn from "../../components/common/Hn";
function ListingWebinaire() {

  const params = useParams();
  let history = useHistory();

  const uri = process.env.REACT_APP_BASE_URL;
  const [webinair, setWebinair] = useState([]);
  const [id, setId] = useState([]);
  const [webinarsTitle, setWebinarsTitle] = useState([]);
  const [showModalInscri, setShowModalInscri] = useState(false);

  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const options = {
    items: 2,
    responsiveClass: true,
    margin: 27,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.25,
      },
      400: {
        items: 1.3,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const hideModal = () => {
    setShowModalInscri(false);
  };

  const data = {
    participant: localStorage.getItem("id"),
  };

  function getwebinair() {
    axios
      .get(uri + `/webinars/`)
      .then((result) => {
        setWebinair(result?.data);
      })
      .catch((err) => {
        console.log("err webinar", err);
      });
  }

  function inscrire(id) {
    axios
      .put(`${uri}/webinars/edit/particiapnt/` + id, data, headers)
      .then((response) => {
        hideModal();
        setTimeout(() => {
          history.go(0);
        }, 100);
      })
      .catch((error) => {
        console.log("error", error);
        // displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
      });
  }

  useEffect(() => {
    getwebinair();
  }, []);

  return (
    <div>

      <div className="webinars mt-5 pt-3">
        {webinair.length > 0 ?
        <>
          <Hn
        title="WEBINAIRE A VENIR"
        level="h3"
        className="pb-4 pt-4"
      />
        <OwlCarousel className="owl-theme" {...options}>
          {webinair && webinair.map((item, index) => {
            return (
              <div key={item.id}>
                <div className="pt-3">
                  <h4>
                    {item?.title}
                    {/* {item?.former[0]?.lastName} */}
                  </h4>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <Icon name="Calendar" data={dateFormat(item?.date, "dd/mm/yyyy")} />
                    </li>
                    <li className="list-inline-item">
                      <Icon name="Clock" data={item?.hour} />
                    </li>
                    <li className="list-inline-item">

                      <Icon name="profil" data={item?.former[0]?.lastName + " " + item?.former[0]?.firstName } />
                    </li>
                  </ul>
                </div>
                <div className="d-flex ">
                  <div className="me-2">
                    {
                      item?.contacts.filter(obj => obj.id === localStorage.getItem('id')).length !== 0 ?
                        <Link rel="opener" title="Reprendre" className="btn btn-primary" to={"/Details-webinars/" + item.id}>
                          Reprendre
                        </Link>
                        :
                        <button
                          role="button"
                          title=" S'inscrire"
                          className="btn btn-primary"
                          onClick={() => { setId(item.id); setWebinarsTitle(item.title); setShowModalInscri(true) }}
                        >
                          S'inscrire
                        </button>
                    }
                  </div>
                  <div>
                    <Link rel="opener" className="btn btn-outline-primary" to={"/Details-webinars/" + item.id}>
                      En savoir plus
                    </Link>
                  </div>
                </div>
              </div>
            )
          }) }
        </OwlCarousel>
        </>
        : ""}

        <Modal
          show={showModalInscri}
          onHide={() => hideModal()}
          size="lg"
          className="special-width-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title className="px-3 nopad">
              <Hn title="Félicitations!" level="h3" plus={false} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row px-3 nopad">
              <div className="col-md-12">
                <Hn
                  title="Nous vous confirmons l'inscription de :"
                  level="h5"
                  plus={false}
                  className="label-popup"
                />
                <p className="text-white textBox">
                  {localStorage.getItem("nom") + "   " + localStorage.getItem("prenom")}
                </p>
              </div>
              <div className="pt-2">
                <Hn
                  title="Webinaire :"
                  level="h5"
                  plus={false}
                  className="label-popup"
                />
                <p className="text-white textBox">
                  {webinarsTitle}
                </p>
              </div>
            </div>
            {/* /row */}
            <br></br>
            {/* /row */}{" "}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary float-end" onClick={() => {  inscrire(id) }}>
              Confirmer
            </button>
          </Modal.Footer>
        </Modal>
      </div>

    </div>

  )
}

export default ListingWebinaire;
