import axios from "axios";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Hn from "../../components/common/Hn";
import Add from "../../assets/icons/add.png";
import Close from "../../assets/icons/Close.png";
import { createBrowserHistory } from 'history';

export default function Createcompetence() {
  let history = createBrowserHistory({forceRefresh:true})
  const uri = process.env.REACT_APP_BASE_URL;

  //créer une nouvelle compétence
  const handleSubmit = (values) => {
    
    const headersFiles = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    let data = {
      title: values.titre,
      subskills: values.soustheme
    }
   
    axios
      .post(
        uri+`/skills/new`,
        data,
        headersFiles
      )
      .then((result) => {
        history.push("/admin/competence");
      
      })
      .catch((err) => {
        console.log("err training", err);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row me-3">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/competence">Compétence</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ajouter une référentiel
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Creer une référentiel"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <Formik
                initialValues={{
                  titre: "",
                  sousthemes: [{ soustheme: "" }],
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object().shape({
                  sousthemes: Yup.array(),
                  titre: Yup.string(),
                })}
                render={({ values, errors }) => (
                  <Form>
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">
                            Titre du référentiel
                          </label>
                          <Field
                            className="form-control"
                            name="titre"
                            placeholder="Titre"
                          />
                          <ErrorMessage
                            className="form-text text-danger"
                            name="titre"
                          />
                        </div>
                      </div>
                      
                    </div>
                    <label htmlFor="" className="form-label">
                                    Compétences
                                  </label>
                    <FieldArray
                      name="sousthemes"
                      render={(arrayHelpers) => (
                        <div className="">
                          {values.sousthemes.map((time, index) => (
                            <div className="row" key={index}>
                              <div className="col-8">
                                <div className="mb-3">
                                  
                                  <Field
                                    className="form-control"
                                    name={`soustheme.${index}`}
                                    placeholder="compétence"
                                  />
                                  <ErrorMessage
                                    className="form-text text-danger"
                                    name={`soustheme.${index}`}
                                  />
                                </div>
                              </div>
                              <div className="col-2 mt-4">
                                <span
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <img
                                    src={Close}
                                    className="pe-1"
                                    style={{
                                      marginTop: "-35px",
                                      marginLeft: "-10px",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          ))}

                          <span
                            onClick={() =>
                              arrayHelpers.insert(values.sousthemes.length, {
                                soustheme: "",
                              })
                            }
                            style={{ color: "#0d6efd" }}
                          >
                            <img src={Add} className="pe-1" /> Ajouter une compétence
                          </span>
                          <div className="col-12 pt-5">
                            <a
                              type="reset"
                              className="btn btn-link resetBtn"
                              href="/admin/competence"
                            >
                              Annuler
                            </a>
                            <button
                              type="submit"
                              className="btn btn-primary float-end"
                            >
                              Valider
                            </button>
                          </div>
                        </div>
                      )}
                    />
                  </Form>
                )}
              />
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}
