import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from "react-router-dom";
import PopupFiltre from "./PopupFiltre";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "../../../../components/common/Spinner";
import { useDatatable } from "../../../../components/hook/useDatatable";

export default function ListProvidersDataGrid() {
  const [providers, setProviders] = useState([]);
  const [id, setId] = useState();
  const [eachEntry, setEachEntry] = useState("");
  const [showfiltre, setShowfiltre] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const {selectRow} = useDatatable();

  let toastId = null;
  let history = useHistory();
  const uri = process.env.REACT_APP_BASE_URL;

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setId(null);
  }

  //récupérer tous les prestataires
  function getProviders() {
    return axios
      .get(`${uri}/provider`)
      .then((result) => {
        setProviders(result?.data);
      })
      .catch((err) => {
        console.log("err providers", err);
      });
  }

  useEffect(() => {
    getProviders();
  }, []);

  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push("/admin/prestataire/" + row.id);

    },
  }

  const handleClosefiltre = (obj) => {
    setShowfiltre(false);
  };

  const handleShowfiltre = () => {
    setShowfiltre(true);
  };

  function displayToast(type, msg) {
    if (!toast.isActive(toastId)) {
      toastId = toast(msg, {
        className: type,
        closeOnClick: false,
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log("Toast already active");
    }
  }
  //supprimer un prestatire
  const manageAction = () => {
    setShowLoader(true);
    axios.delete(`${uri}/provider/${id}`)
      .then((response) => {
        getProviders().then(() =>{
          displayToast("Csuccess", 'Suppression effectuée avec success...');
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      })
      .catch((error) => {
        displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
        setShowConfirmationModal(false);
        setShowLoader(false);
      });
  }

  const manageBulk = () => {
    let promises = selectedRows.map(function(row) {
      return axios.delete(`${uri}/provider/${row}`);
    });
    setShowLoader(true);
    Promise.all(promises).then(() => {
        setSelectedRows([]);
        getProviders().then(() => {
          displayToast("Csuccess", "Suppression(s) effectuée(s) avec success...");
          handleCloseConfirmationModal();
          setShowLoader(false);
        });
      }
    ).catch(() =>{
      handleCloseConfirmationModal();
      setShowLoader(false);
      displayToast("Cerror", "Quelque chose s'est mal passé, Réessayer");
    });
  }

  function searchwithfiltre(val) {
    setEachEntry(val);
    console.log(val)
    if (val.name === ""
      && val.pays === ""
      && val.tel === ""
      && val.email === "" && val.contact === "") {

      getProviders();
      //  setParticipant(initialparticipant)
    } else {
      setProviders([]);
      // let providersFilter = providers.filter(el =>
      //   (el?.name?.toLowerCase().includes(val.name.toLowerCase()) && (val.name.length > 0))
      //   || (el?.country.toLowerCase() === val.pays.toLowerCase() && (val.pays.length > 0))
      //   || (el?.email?.toLowerCase().includes(val.email.toLowerCase()) && (val.email.length > 0))
      //   || el?.tel === val.tel
      // )

      let locationFilter = providers.filter(el => {
        let ch = 0; let lf; let i = 0;
        if (val.name) {
          i = i + 1
          lf = el?.name?.toLowerCase().includes(val?.name?.toLowerCase())
          if (lf) ch = ch + 1;
        }
        if (val.pays) {
          i = i + 1
          lf = el?.country?.toLowerCase().includes(val?.pays?.toLowerCase())
          if (lf) ch = ch + 1;
        }
        if (val.tel) {
          i = i + 1
          lf = el?.tel?.toLowerCase().includes(val?.tel?.toLowerCase())
          if (lf) ch = ch + 1;
        }
        if (val.email) {
          i = i + 1
          lf = el?.email?.toLowerCase().includes(val?.email?.toLowerCase())
          if (lf) ch = ch + 1;
        }
        if (val.contact) {
          i = i + 1
          lf = el?.contact_name?.toLowerCase().includes(val?.contact?.toLowerCase())
          if (lf) ch = ch + 1;
        }

        if (ch === i) { return true }
        return false
      })

        setProviders(locationFilter)

    }

  }

  const columns = [
    {
      dataField: "ref",
      text: "ref",
      csvText: "ref",
      hidden: "true",
    },
    {
      dataField: "name",
      text: "Nom",
      csvText: "name",
      sort: true,

    },
    {
      dataField: "address",
      text: "address",
      csvText: "address",
      sort: true,
      hidden: true
    },
    {
      dataField: "country",
      text: "Pays",
      csvText: "country",
      sort: true,
    },
    {
      dataField: "contact_name",
      text: "Contact",
      sort: true,
      csvText: "contact_name",

    },
    {
      dataField: "contact_surname",
      text: "contact_surname",
      sort: true,
      csvText: "contact_surname",
      hidden: true
    },
    {
      dataField: "comment",
      text: "comment",
      sort: true,
      csvText: "comment",
      hidden: true
    },
    {
      dataField: "zip",
      text: "zip",
      sort: true,
      csvText: "zip",
      hidden: true
    },
    {
      dataField: "tel",
      text: "Téléphone",
      sort: true,
      csvText: "tel",

    },
    {
      dataField: "city",
      text: "city",
      sort: true,
      csvText: "city",
      hidden: true
    },
    {
      dataField: "website",
      text: "website",
      sort: true,
      csvText: "website",
      hidden: true
    },
    {
      dataField: "email",
      text: "Email",
      csvText: "email",
      sort: true,
    },
    {
      dataField: "follow",
      text: "",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div
            className="btn-group float-end"
            role="group"
            aria-label="Basic example"
          >
            <a
              href={"/admin/prestataire-edit/" + row.id}
              type="button"
              className="btn btn-link"
            >
              <img
                src={require("../../../../assets/icons/edit.png")}
              />
            </a>

            {localStorage.getItem("role") === "Super-admin" ? (
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setId(row.id);
                  setShowConfirmationModal(true);
                }}
              >
                <img
                  src={require("../../../../assets/icons/delete.png")}
                />
              </button>
            ) : (
              ""
            )}
          </div>
        );
      },
      sort: true
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="hidden" hidden onClick={handleClick} name="export" id="export">Export to CSV</button>
      </div>
    );
  };

  let rows = providers.map((item, i) => item);

  const bulkDelete = () => {
    setShowConfirmationModal(true);
  }

  return (
    <ToolkitProvider
      keyField="id"
      data={rows}
      columns={columns}
      exportCSV={{ onlyExportSelection: true, exportAll: false }}
      search
    >
      {
        (props) => (
          <div>
            <MyExportCSV {...props.csvProps} />
            <div className="lists-filter my-3">
              <div className="row">
                <div className="col-xl-4 col-lg-1"></div>
                <div className="col-xl-8 col-lg-11 col-md-12 col-xs-12">
                  <div className="flexgy">
                    <div className="col-md-4  mr-1  px-0">
                      <div className="input-group">
                        <input
                          className="form-control height43"
                          type="search"
                          placeholder="Recherche"
                          onChange={(e => props.searchProps.onSearch(e.target.value))}

                        />
                      </div>
                    </div>
                    <div className=" px-0 myxs ">
                      <div className="flex">
                        <div className="square">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleShowfiltre}
                          >
                            <img
                              src={require("../../../../assets/icons/filter.png")}
                              title="Filtrer"
                            />
                          </button>
                          <PopupFiltre
                            showfiltre={showfiltre}
                            closefiltre={handleClosefiltre}
                            searchwithfiltre={searchwithfiltre}
                            filterEntry={eachEntry}
                          />
                        </div>
                        <div className="square">
                          <button type="button" className="btn btn-link" onClick={() => props.csvProps.onExport()}>
                            <img
                              src={require("../../../../assets/icons/Export.png")}
                              title="Export"
                            />
                          </button>
                        </div>
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept={".csv"}
                          className="btn btn-link"
                          // onChange={handleOnChange}
                          hidden
                        />
                        <div className="square">
                          <label htmlFor="file" type="button">
                            <img
                              src={require("../../../../assets/icons/Import.png")}
                            />
                          </label>
                        </div>
                        <div className="square">
                          <button
                            onClick={bulkDelete}
                            type="button"
                            className="btn btn-link"
                          >
                            <img
                              src={require("../../../../assets/icons/delete.png")}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="px10">
                      <div className="d-grid gap-2">
                        <a
                          href="/admin/create-prestataire"
                          className="btn btn-primary height43"
                          type="button"
                        >
                          Ajouter un prestataire
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              data={providers}
              {...props.baseProps}
              classes="table-dark mt-5"
              bordered={false}
              hover
              selectRow={selectRow(selectedRows, setSelectedRows)}
              pagination={paginationFactory(options)}
              rowEvents={tableRowEvents}
            />

            {showLoader && <Spinner />}

            <Modal show={showConfirmationModal} onHide={handleCloseConfirmationModal} className="deleteModal">
              <Modal.Header closeButton>
                <Modal.Title className="modaltitle">
                  {id != null ? 'Etes-vous sûr(e) de vouloir supprimer le prestataire' : 'Etes-vous sûr(e) de vouloir supprimer la selection'}
                </Modal.Title>
              </Modal.Header>

              <Modal.Footer>
                <Button variant="" onClick={handleCloseConfirmationModal} style={{ color: "#006FFF" }}>
                  Annuler
                </Button>
                <Button
                  type="button"
                  variant="btn btn-primary"
                  onClick={() => {
                    id ? manageAction() : manageBulk()
                  }}
                >
                  Valider
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )
      }
    </ToolkitProvider>

  );
}
