import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import Hn from "../../../../../components/common/Hn";
import PopupFiltre from "../../../../../components/popup/PopupFiltreParticipant";
import { toast, ToastContainer } from "react-toastify";

export default function ParticpantDatatable({ type="session", session }) {
  const [notInscrits, setNotInscrits] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [eachEntry, setEachEntry] = useState("");
  const uri = process.env.REACT_APP_BASE_URL;
  const options = {
    sizePerPage: 50,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
  };

  const handleCloseFilter = (obj) => {
    setShowFilter(false);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  function searchParticipant(value) {
    let req = uri + "/participant/?search=" + value;

    axios
      .get(req)
      .then((result) => {
        if (result?.data.length > 0) {
          setNotInscrits(result?.data);
        } else {
          setNotInscrits([]);
        }
      })
      .catch((err) => {
        console.log("err search", err);
      });
  }

  function searchWithFilter(val) {
    setNotInscrits([]);
    setEachEntry(val);
    let query = `collaborator=${val.collaborator}&agent=${val.agent}&offre=${val.offre}&manager=${val.agence}`;
    let req = uri + "/participant/filter?" + query;

    axios
      .get(req)
      .then((result) => {
        if (result?.data.length > 0) {
          setNotInscrits((prevState) => [...prevState, result?.data]);
        } else {
          setNotInscrits([]);
        }
      })
      .catch((err) => {
        console.log("err filter", err);
      });
  }

  function getParticipant() {
    axios
      .get(uri + "/participant")
      .then((result) => {
        setNotInscrits(result?.data);
      })
      .catch((err) => {
        console.log("err participant", err);
      });
  }

  useEffect(() => {
    getParticipant();
  }, []);

  const columns = [
    {
      dataField: "apprenant",
      text: "Collaborateur",
      csvText: "apprenant",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {row?.contactNom} {row?.contactPrenom}
          </>
        );
      },
    },
    {
      dataField: "mailcontact",
      text: "Email",
      csvText: "email",
      sort: true,
    },
    {
      dataField: "agencymain",
      text: "Agent",
      csvText: "agent",
      sort: true,
    },
    {
      dataField: "agenceEmail",
      text: "Email Agent",
      csvText: "emailAgent",
      sort: true,
    },
    {
      dataField: "offre",
      text: "Offre",
      formatter: (cell, row) => {
        return <span>{row.offre}</span>;
      },
    },
  ];

  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    hideSelectAll: true,
    onSelect: (row, isSelect) => {

      let dataEndDelete = {
        participant: [row.id],
      };

      let dataEndWebinars = {
        participant: row.id,
      };

      let data;
      let api_add;
      let api_delete;
      if (type === "webinars") {
        api_add = `webinars/edit/particiapnt/${session}`;
        api_delete = `webinars/delete/particiapnt/${session}`;
        data = dataEndWebinars
      } else {
        api_add = `session/edit/particiapnt/${session}`;
        api_delete = `session/delete/particiapnt/${session}`;
        data = dataEndDelete
      }

      if (isSelect) {
        axios
          .put(`${uri}/${api_add}`, dataEndWebinars, headers)
          .then((response) => {
            toast.success('Le participant a bien été rajouté')
          })
          .catch((error) => {
            toast.error('Le participant n\'a pas été rajouté')
          });
      } else {
        axios
          .put(`${uri}/${api_delete}`, data, headers)
          .then((response) => {
            toast.success('Le participant a bien été supprimé')
          })
          .catch((error) => {
            toast.error('Le participant n\'a pas été supprimé')
          });
      }
    },
  };
  return (
    <>
      <ToastContainer />
      <ToolkitProvider
      keyField="ContactID"
      data={notInscrits}
      columns={columns}
      search
    >
      {(props) => (
        <div>
          <div className="lists-filter mb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-6">
                <Hn
                  title="Ajouter un participant"
                  level="h4"
                  className="h4-size pb-5"
                  plus={false}
                />
              </div>
              <div className="col-xl-8 col-lg-6 col-md-12 col-xs-12">
                <div className="flexgy">
                  <div className="col-md-6  mr-1  px-0">
                    <div className="input-group">
                      <input
                        className="form-control height43"
                        type="search"
                        placeholder="Recherche"
                        onKeyUp={(e) => searchParticipant(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className=" px-0 myxs">
                    <div className="flex">
                      <div className="square">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={handleShowFilter}
                        >
                          <img src={require("../../../../../assets/icons/filter.png")} />
                        </button>
                      </div>
                      <PopupFiltre
                        showfiltre={showFilter}
                        closefiltre={handleCloseFilter}
                        searchwithfiltre={searchWithFilter}
                        filterEntry={eachEntry}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BootstrapTable
            {...props.baseProps}
            classes="table-dark table-responsive"
            bordered={false}
            hover
            selectRow={selectRow}
            pagination={paginationFactory(options)}
          />
        </div>
      )}
    </ToolkitProvider>
    </>

  );
}
