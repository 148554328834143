import { useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';

import Hn from '../../../../../components/common/Hn';
import './createFaceTraining.css';

import Icon from "../../../../../components/common/Icon";

function CreateFaceLearning() {

    const fileRef = useRef();
    const [modalShow, setModalShow] = useState(false);

    function ModalForm(props) {
        return (
            <Modal size="lg" {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Unité d'apprentissage 1
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <form className="row g-3">
                            <div className="col-md-4">
                                <label  className="form-label">Heure de début matin</label>
                                <select className="form-select" aria-label="Default select example" id='actif'>
                                    <option selected>sélectinner dans la liste</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label  className="form-label">Heure de fin matin</label>
                                <select className="form-select" aria-label="Default select example" id='actif'>
                                    <option selected>sélectinner dans la liste</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label  className="form-label">Heure de début aprés-midi</label>
                                <select className="form-select" aria-label="Default select example" id='actif'>
                                    <option selected>sélectinner dans la liste</option>
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label  className="form-label">Heure de fin aprés-midi</label>
                                <select className="form-select" aria-label="Default select example" id='actif'>
                                    <option selected>sélectinner dans la liste</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">Capacité d'acccueil minimale</label>
                                <select className="form-select" aria-label="Default select example" id='actif'>
                                    <option selected>sélectinner dans la liste</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label  className="form-label">Capacité d'acccueil maxinale*</label>
                                <input type="text" className="form-control" id="capacity-max" placeholder="00" />
                            </div>

                            <div className="col-md-4">
                                <label  className="form-label">Capacité d'acccueil maxinale*</label>
                                <input type="text" className="form-control" id="capacity-max" placeholder="00" />
                            </div>
                            <div className="col-md-4">
                                <label  className="form-label">Formateur</label>
                                <select className="form-select" aria-label="Default select example" id='actif'>
                                    <option selected>sélectinner dans la liste</option>
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label  className="form-label">&nbsp;</label>
                                <select className="form-select" aria-label="Default select example" id='actif'>
                                    <option selected>sélectinner dans la liste</option>
                                </select>
                            </div>

                            <div className="col-12 mt-5">
                                <Button variant="outline-primary" onClick={props.onHide}>Annuller</Button>
                                <a href="#" type="submit" className="btn btn-primary float-end">
                                    Valider
                                </a>
                            </div>
                        </form>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }

    const handleChange = (e) => {
        const [file] = e.target.files;
        console.log(file);
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='container lists p-5'>
                    <nav aria-label="breadcrumb pb-3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Formations présentielles - Actions de formation</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Créer une action de formation</li>
                        </ol>
                    </nav>
                    <div className="h-100 p-5 text-white bg-dark rounded-3">
                        <Hn title="Créer une action de formation" level="h4" className="h4-size" plus={false} />
                        <Hn title="Etap 2 : Ajout de sessions de formation" level="p" className="py-2 fw-bold" plus={false} />

                        <div className='form-elements mt-2'>
                            <div className="form-aria p-4">
                                <form className="row g-3">
                                    <div className="col-md-4">
                                        <label  className="form-label">Référence*</label>
                                        <input type="text" className="form-control" id="ref" placeholder="Référence" />
                                    </div>
                                    <div className="col-md-4">
                                        <label  className="form-label">Date de début</label>
                                        <input type="date" className="form-control" id="date-d" placeholder="Date de début" />
                                    </div>
                                    <div className="col-md-4">
                                        <label  className="form-label">Dete de fin</label>
                                        <input type="date" className="form-control" id="date-f" placeholder="Intitulé" />
                                    </div>

                                    <div className="col-md-4">
                                        <label className="form-label">Lieu de formation*</label>
                                        <select className="form-select" aria-label="Default select example" id='actif'>
                                            <option selected>sélectinner dans la liste</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label  className="form-label">Capacité d'acccueil minimale</label>
                                        <input type="text" className="form-control" id="assoc" placeholder="00" />
                                    </div>
                                    <div className="col-md-4">
                                        <label  className="form-label">Capacité d'acccueil maxinale*</label>
                                        <input type="text" className="form-control" id="capacity-max" placeholder="00" />
                                    </div>

                                    <div className="col-md-4">
                                        <label  className="form-label">Formateur</label>
                                        <select className="form-select" aria-label="Default select example" id='actif'>
                                            <option selected>sélectinner dans la liste</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label  className="form-label">&nbsp;</label>
                                        <select className="form-select" aria-label="Default select example" id='actif'>
                                            <option selected>sélectinner dans la liste</option>
                                        </select>
                                    </div>
                                </form>

                                <ModalForm show={modalShow} onHide={() => setModalShow(false)} />

                                <div className="row pt-5">
                                    <div className="col-md-6">
                                        <div className="card text-white bg-dark mb-3 p-3" >
                                            <div className="card-body">
                                                <h5 className="card-title">Unité d'apprentissage 1</h5>
                                                <div className="mt-4">
                                                    <label  className="form-label">Nom de l'unité d'apprentissage</label>
                                                    <input type="text" className="form-control" id="ref" placeholder="Ajouter un nom" />
                                                </div>
                                                <div className="pt-3">
                                                    <Icon name="localisation" data="5 rue Monge, 75005 Paris" />
                                                    <ul className="list-inline ps-0">
                                                        <li className="list-inline-item">
                                                            <Icon name="Calendar" data="04/03/2022" />
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Icon name="Clock" data="15h30" />
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Icon name="profil" data="John Doe" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent">
                                                <Icon name="edit" onClick={() => setModalShow(true)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card text-white bg-dark mb-3 p-3" >
                                            <div className="card-body">
                                                <h5 className="card-title">Unité d'apprentissage 1</h5>
                                                <div className="mt-4">
                                                    <label  className="form-label">Nom de l'unité d'apprentissage</label>
                                                    <input type="text" className="form-control" id="ref" placeholder="Ajouter un nom" />
                                                </div>
                                                <div className="pt-3">
                                                    <Icon name="localisation" data="5 rue Monge, 75005 Paris" />
                                                    <ul className="list-inline ps-0">
                                                        <li className="list-inline-item">
                                                            <Icon name="Calendar" data="04/03/2022" />
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Icon name="Clock" data="15h30" />
                                                        </li>
                                                        <li className="list-inline-item text-end">
                                                            <Icon name="profil" data="John Doe" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent">
                                                <Icon name="edit" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='lists-filter pt-5'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Hn title="Ajouter des participants" level="h4" plus={false} />
                                        </div>
                                        <div className='col-md-6 pe-0'>
                                            <div className='row'>
                                                <div className='col-md-10 pe-0'>
                                                    <div className="input-group">
                                                        <input className="form-control" type="search" placeholder="Recherche" />
                                                        <span className="input-group-append">
                                                            <button className="btn btn-outline-dark" type="button">
                                                                <img src={require('../../../../../assets/icons/Search.svg').default} />
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'>
                                                        <div className='square'>
                                                            <button type="button" className="btn btn-link">
                                                                <img src={require('../../../../../assets/icons/filter.png')} />
                                                            </button>
                                                        </div>
                                                        <div className='square'>
                                                            <img src={require('../../../../../assets/icons/download.png')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* lists-filter */}

                                <div className='pt-5'>
                                    <table className="table table-dark table-hover">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="form-check">
                                                        <input className="form-check-input square-check" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </th>
                                                <th scope="col">Collaborateur</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Agent</th>
                                                <th scope="col">Email Agent</th>
                                                <th scope="col">Offre</th>
                                                <th scope="col">Manager</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input square-check" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </th>
                                                <td>Mouaouia</td>
                                                <td>mouaouia@gmail.com</td>
                                                <td>Perron Gauthier</td>
                                                <td>p.gautier@gmail.com</td>
                                                <td>oui</td>
                                                <td>oui</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input square-check" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </th>
                                                <td>Mouaouia</td>
                                                <td>mouaouia@gmail.com</td>
                                                <td>Perron Gauthier</td>
                                                <td>p.gautier@gmail.com</td>
                                                <td>oui</td>
                                                <td>oui</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input square-check" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </th>
                                                <td>Mouaouia</td>
                                                <td>mouaouia@gmail.com</td>
                                                <td>Perron Gauthier</td>
                                                <td>p.gautier@gmail.com</td>
                                                <td>oui</td>
                                                <td>oui</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input square-check" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </th>
                                                <td>Mouaouia</td>
                                                <td>mouaouia@gmail.com</td>
                                                <td>Perron Gauthier</td>
                                                <td>p.gautier@gmail.com</td>
                                                <td>oui</td>
                                                <td>oui</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input square-check" type="checkbox" value="" id="flexCheckDefault" />
                                                    </div>
                                                </th>
                                                <td>Mouaouia</td>
                                                <td>mouaouia@gmail.com</td>
                                                <td>Perron Gauthier</td>
                                                <td>p.gautier@gmail.com</td>
                                                <td>oui</td>
                                                <td>oui</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-end">
                                        <a href="/admin/create-formation-competence" type="submit" className="btn btn-outline-primary float-end">
                                            Valider
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mt-5">
                                <a href="/admin/create-session-formation-presentielle" className="btn btn-link resetBtn">
                                Annuler
                                </a>
                                <button type="submit" className="btn btn-primary float-end" disabled>Suivant</button>
                            </div>
                        </div>
                    </div>
                </div>{/* lists */}
            </div>
        </div>
    )
}

export default CreateFaceLearning;
