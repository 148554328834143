import { createBrowserHistory } from "history";
import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { NavLink } from "react-router-dom";
import "./sidebarAdmin.css";

import { ReactComponent as Elearning } from "../../../assets/icons/Elearning.svg";
import { ReactComponent as Home } from "../../../assets/icons/home.svg";
import { ReactComponent as Icon } from "../../../assets/icons/Icon.svg";

import { ReactComponent as Skills } from "../../../assets/icons/skills.svg";
import { ReactComponent as Thematic } from "../../../assets/icons/thematic.svg";
import { ReactComponent as Webinar } from "../../../assets/icons/webinar.svg";
import { ReactComponent as Gh } from "../../../assets/icons/Gh.svg";
import menuBar from "../../../assets/icons/liste.png";

const SideBar = () => {
  const [openElearning, setOpenElearning] = useState(false);
  const [open, setOpen] = useState(false);
  const [mobetat, setMobetat] = useState(false);
  const [leftspace, setLeftspace] = useState(false);
  let history = createBrowserHistory({ forceRefresh: true });

  const [openel, setOpenel] = useState(false);
  const [openpr, setOpenpr] = useState(false);

  //déconnexion
  const logout = () => {
    localStorage.removeItem("user"); //supprimer le nom de la ressource(élément du localStorage) en fonction de son clé
    localStorage.clear(); // supprimer tous les éléments du stockage local
    history.push("/login"); //se rediriger vers l'url /login Où le composant de la connexion
  };

  // gérer le clic sur icon menu dans le mobile screen pour faire show(apparaitre)/hide(disparaitre) du sidebar
  const mob = () => {
    setMobetat(!mobetat); //setMobetat est un useState dans le cas du mobile boolean si true prend le className "l-navbar shows" sinon "l-navbar"}
    setLeftspace(!leftspace); //setLeftspace est un useState dans le cas du mobile boolean si true prend le className "leftspace et header_toggle" sinon "header_toggle"
  };
 //si on clique sur le lien (NavLink) les useStats reviennent à l'état initial et le sidebar disparait
  const initialisesidebar = () => {
    setOpenel(false); // n'est pas utilisé on l'a placé par setOpenElearning
    setOpenpr(false);//dans l'état initial le sidebar est disparait et la sous liste de la formation presentielle dans le menu est cachée
    setOpen(false);//dans l'état initial le sidebar est disparait et la sous liste de tout le menu est cachée
    setOpenElearning(false);//dans l'état initial le sidebar est disparait et la sous liste de la formation elearning est cachée
    setMobetat(false);//dans l'état initial le useState mobetat prend false  (voir ligne 36)
    setLeftspace(false);//dans l'état initial le useState leftspace prend false (voir ligne 37)
  };

  return (
    <div>
      <header className="header" id="header">
        <div
          className={leftspace ? "header_toggle leftspace" : "header_toggle"}
        >
          <img
             src={menuBar}
            className="menu_mobile"
            alt="img"
            width="5px"
            onClick={mob}
          />
        </div>
      </header>
      {localStorage.getItem("role") === "Admin" && (
        <div
          className={mobetat ? "l-navbar shows" : "l-navbar"}
          id="nav-bar"
          onMouseLeave={initialisesidebar}
        >
          <nav className="nav">
            <div>
              <NavLink
                to="/admin/apprenant"
                className="nav_logo"
                activeClassName=""
              >
                <Gh />
              </NavLink>
              <div className="nav_list">
                <ul className="ps-0">
                  <li>
                    <NavLink
                      to="/admin/apprenant"
                      activeClassName="sub-active"
                      className="nav_link"
                      onClick={initialisesidebar}
                    >
                      <img
                    src={require("../../../assets/icons/group.png")}
                    className="nav_icon"
                  />
                      <span className="nav_name">Apprenants</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#"
                      activeClassName="active"
                      className="nav_link"
                      onClick={initialisesidebar}
                    >
                      <img
                        src={
                          require("../../../assets/icons/Deconnexion.svg")
                            .default
                        }
                        className="nav_icon"
                        alt="deconnexion"
                      />
                      <span className="nav_name" onClick={logout}>
                        Déconnexion
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      )}

      {localStorage.getItem("role") === "Formateur" ? (
        <div
          className={mobetat ? "l-navbar shows" : "l-navbar"}
          id="nav-bar"
          onMouseLeave={initialisesidebar}
        >
          <nav className="nav">
            <div>
              <NavLink
                to="/admin/formation-e-learning"
                className="nav_logo"
                activeClassName=""
              >
                <Gh />
              </NavLink>
              <div className="nav_list">
                <ul className="ps-0">
                  <li className="nav-item">
                          <NavLink
                            to="/admin/formation-e-learning"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                             <Elearning />
                            <span className="nav_name">
                              Formation
                            </span>
                          </NavLink>
                        </li>
                    <li>
                    <NavLink
                      to="#"
                      activeClassName="active"
                      className="nav_link"
                      onClick={initialisesidebar}
                    >
                      <img
                        src={
                          require("../../../assets/icons/Deconnexion.svg")
                            .default
                        }
                        className="nav_icon"
                        alt="deconnexion"
                      />
                      <span className="nav_name" onClick={logout}>
                        Déconnexion
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      ) : ("")}

      {localStorage.getItem("role") !== "Admin" && localStorage.getItem("role") !== "Formateur" ? (
        <div
          className={mobetat ? "l-navbar shows" : "l-navbar"}
          id="nav-bar"
          onMouseLeave={initialisesidebar}
        >
          <nav className="nav">
            <div>
              <NavLink
                to="/admin/dashboard"
                className="nav_logo"
                activeClassName=""
              >
                <Gh />
              </NavLink>
              <div className="nav_list">
                <ul className="ps-0">
                  <li>
                    <NavLink
                      to="/admin/dashboard"
                      activeClassName="active"
                      className="nav_link"
                      onClick={() => setOpen(false)}
                    >
                      <Home />
                      <span className="nav_name">Dashboard</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="#"
                      activeClassName="disable"
                      className="nav_link "

                      onClick={() => setOpenElearning(!openElearning)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openElearning}
                    >
                      <Elearning />
                      <span className="nav_name">Formations e-learning</span>
                    </NavLink>

                    <Collapse in={openElearning}>
                      <ul className="sub-nav ps-0">
                        <li className="nav-item">
                          <NavLink
                            to="/admin/formation-e-learning"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                            <span className="nav_name">
                              Actions de formation
                            </span>
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink
                            to="/admin/apprenant"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                            <span className="nav_name">Apprenants</span>
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="#"
                      activeClassName="disable"
                      className="nav_link "
                      onClick={() => setOpenpr(!openpr)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openpr}
                    >
                      <Icon />
                      <span className="nav_name disable">
                        Formations présentielles
                      </span>
                    </NavLink>
                    <Collapse in={openpr}>
                      <ul className="sub-nav ps-0">
                        <li className="nav-item">
                          <NavLink
                            // to="/admin/create-formation-presentielle"
                            to="/admin/formation-presentielle"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                            <span className="nav_name">
                              Actions de formation
                            </span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/admin/Sessions"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                            <span className="nav_name">
                              Sessions de formation
                            </span>
                          </NavLink>
                        </li>

                        <li className="nav-item">
                          <NavLink
                            to="/admin/participants"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                            <span className="nav_name">Participants</span>
                          </NavLink>
                        </li>

                        <hr className="divider" />
                        <li className="nav-item">
                          <NavLink
                            to="/admin/liste-formateurs"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                            <span className="nav_name">Formateurs</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/admin/prestataires"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                            <span className="nav_name">Prestataires</span>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            to="/admin/liste-lieu-formation"
                            activeClassName="sub-active"
                            className="nav_link"
                            onClick={initialisesidebar}
                          >
                            <span className="nav_name">Lieux de formation</span>
                          </NavLink>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/thematique"
                      activeClassName="active"
                      className="nav_link"
                      onClick={initialisesidebar}
                    >
                      <Thematic />
                      <span className="nav_name">Thématiques</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/competence"
                      activeClassName="active"
                      className="nav_link"
                      onClick={initialisesidebar}
                    >
                      <Skills />
                      <span className="nav_name">Compétences</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/webinair"
                      activeClassName="active"
                      className="nav_link"
                      onClick={initialisesidebar}
                    >
                      <div type="button" className="position-relative">

                        <Webinar />

                      </div>
                      <span className="nav_name">Webinaires</span>
                    </NavLink>
                  </li>
                  <li>
                    <div style={{ paddingTop: "100px" }}>
                      <NavLink
                        to={ localStorage.getItem("role") === "Super-admin" ? '/Formations-e-learning' : '/admin/dashboard'}
                        activeClassName="active"
                        className="nav_link"
                        onClick={() => {
                            initialisesidebar();
                            if (localStorage.getItem("role") === "Super-admin") {
                              localStorage.setItem("role2", "Apprenants") // sérieux ?
                              history.push("/Formations-e-learning");
                            } else {
                              localStorage.setItem("role2", "");  // sérieux ?
                              history.push("/admin/dashboard");
                            }
                          }
                        }
                      >
                        <img
                          src={ require("../../../assets/icons/switch_account.svg").default }
                          className="nav_icon"
                          alt="deconnexion"
                        />
                          <span className="nav_name">
                            { localStorage.getItem("role") === "Super-admin" ? 'Plateforme' : 'Administrateur'}
                        </span>
                      </NavLink>
                    </div>
                  </li>
                  <li>
                    <NavLink
                      to="/login"
                      className="nav_link"
                      onClick={logout}
                    >
                      <img
                        src={require("../../../assets/icons/Deconnexion.svg").default}
                        className="nav_icon"
                        alt="deconnexion"
                      />
                      <span className="nav_name">
                        Déconnexion
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      ):''}
    </div>
  );
};

export default SideBar;
