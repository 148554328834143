import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import axios from "axios";
import Hn from "../../../../components/common/Hn";
import "./locationForm.css";
const countryList = require("../prestataire/data.json");

function LocationUpdate() {

  let history = useHistory();
  const params = useParams();

  const [dbvalue, setDbvalue] = useState('');


  useEffect(() => {
    getlocationByID(params.id);

  }, []);

  const validationSchema = Yup.object().shape({
    reference: Yup.string().required("Champ obligatoire."),
    nom: Yup.string().required("Champ obligatoire."),
    // capacity: Yup.number().required("Champ obligatoire."),
    // denomination: Yup.string().required("Champ obligatoire."),
    // adress: Yup.string().required("Champ obligatoire."),
    // code: Yup.string().required("Champ obligatoire."),
    // ville: Yup.string().required("Champ obligatoire."),
    // pays: Yup.string().required("Champ obligatoire."),
    // cout: Yup.number().required("Champ obligatoire."),
    // interlocuteur: Yup.string().required("Champ obligatoire."),
    // telephone: Yup.string().required("Champ obligatoire."),
    // email: Yup.string().required("Champ obligatoire."),
    // commentaire: Yup.string().required("Champ obligatoire."),
  });

  const handleSubmit = (values) => {
    const datasend = {
      ref: values.reference,
      town: values.ville,
      address: values.adress,
      name: values.nom,
      capacity: parseInt(values.capacity),
      company_name: values.denomination,
      zip: values.code,
      country: values.pays,
      cost: values.cout,
      interlocutor_name: values.interlocuteur,
      tel: values.telephone,
      email: values.email,
      comment: values.commentaire,
      rating: values.rating
    };
    editLocation(datasend);
  };

  //récupérer un lieu par son id
  async function getlocationByID(id) {

    const uri = process.env.REACT_APP_BASE_URL;

    let headers = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    await axios.get(uri+"/location/"+id, headers)
    .then((response) => {
      setDbvalue(response.data);
    })
    .catch((error) => {
      return error.response;
    });


  }
//modifier un lieu de formation
  async function editLocation(data) {

    const uri = process.env.REACT_APP_BASE_URL;

    let headers = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    await axios.put(uri+"/location/edit/"+params.id,data, headers)
    .then((response) => {
      history.push("/admin/liste-lieu-formation/");
    })
    .catch((error) => {
      console.log(error.response.data);

      return error.response;
    });

  }




  return (
    <div className="container-fluid">
      <div className="row">
        <div className="container lists p-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Formations présentielles - Lieux de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Modifier un lieu de formation
              </li>
            </ol>
          </nav>
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <Hn
              title="Modifier un lieu de formation"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
            <Formik
                initialValues={{
                  reference: dbvalue.ref,
                  nom: dbvalue.name,
                  capacity: dbvalue?.capacity? dbvalue?.capacity: "",

                  denomination: dbvalue.company_name,
                  adress: dbvalue.address,
                  code: dbvalue.zip,
                  ville : dbvalue.zip,
                  pays : dbvalue.town,
                  cout : dbvalue.cost,
                  interlocuteur : dbvalue.interlocutor_name,
                  telephone : dbvalue.tel,
                  email : dbvalue.email,
                  commentaire : dbvalue.comment
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit} className="row g-3">
                    <div className="col-md-4">
                      <label htmlFor="ref" className="form-label">
                        Référence
                      </label>
                      <input
                        style={{color:"#808080",fontWeight:"600"}}
                        type="text"
                        name="reference"
                        className="form-control"
                        id="reference"
                        placeholder="Référence"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reference || dbvalue.ref}
                        disabled
                      />
                      <ErrorMessage name="reference">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="init" className="form-label">
                        Nom*
                      </label>
                      <input
                        type="text"
                        name="nom"
                        className="form-control"
                        id="init"
                        placeholder="Nom"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nom || dbvalue.name}
                      />
                      <ErrorMessage name="nom">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="capac" className="form-label">
                        Capacité d'accueil
                      </label>
                      <input
                        type="number"
                        name="capacity"
                        className="form-control"
                        id="init"
                        placeholder="OO"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.capacity}
                      />
                      <ErrorMessage name="capacity">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="denom" className="form-label">
                        Dénomination sociale
                      </label>
                      <input
                        type="text"
                        name="denomination"
                        className="form-control"
                        id="init"
                        placeholder="Dénomination sociale"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.denomination}
                      />
                      <ErrorMessage name="denomination">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="addr" className="form-label">
                        Adresse
                      </label>
                      <input
                        type="text"
                        name="adress"
                        className="form-control"
                        id="init"
                        placeholder="Numéro et nom de voie"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.adress}
                      />
                      <ErrorMessage name="adress">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="codep" className="form-label">
                        Code postal
                      </label>
                      <input
                        type="number"
                        name="code"
                        className="form-control"
                        id="assoc"
                        placeholder="00 000"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.code}
                      />
                      <ErrorMessage name="code">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="ville" className="form-label">
                        Ville
                      </label>
                      <input
                        type="text"
                        name="ville"
                        className="form-control"
                        id="init"
                        placeholder="Ville"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ville}
                      />
                      <ErrorMessage name="ville">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="pays" className="form-label">
                        Pays
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="pays"
                        id="pays"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={dbvalue?.country}
                        value={values.pays}
                      >
                        <option>pays</option>
                        {Object.keys(countryList).map((element) => (
                          <option
                            key={element}
                            value={countryList[element].label}
                          >
                            {countryList[element].label}
                          </option>
                        ))}
                      </select>
                      <ErrorMessage name="pays">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="coutj" className="form-label">
                        Coût par jour
                      </label>
                      <input
                        type="number"
                        name="cout"
                        className="cost form-control"
                        id="assoc"
                        placeholder="00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cout}
                      />
                      <ErrorMessage name="cout">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="note" className="form-label">
                        Note sur 5
                      </label>
                      <input
                        type="number"
                        name="rating"
                        className="form-control"
                        id="rating"
                        placeholder="0"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.rating}
                        min={1}
                        max={5}
                      />
                      <ErrorMessage name="rating">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="interloc" className="form-label">
                        Nom de l'interlocuteur
                      </label>
                      <input
                        type="text"
                        name="interlocuteur"
                        className="form-control"
                        id="init"
                        placeholder="Nom de l'interlocuteur"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.interlocuteur}
                      />
                      <ErrorMessage name="interlocuteur">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="phone" className="form-label">
                        Téléphone
                      </label>
                      <input
                        type="number"
                        name="telephone"
                        className="form-control"
                        id="init"
                        placeholder="00 00 00 00"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.telephone}
                      />
                      <ErrorMessage name="telephone">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="assoc"
                        placeholder="Email@gmail.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12">
                      <label htmlFor="comm" className="form-label">
                        Commentaire
                      </label>
                      <textarea
                        className="form-control"
                        name="commentaire"
                        id="pre"
                        rows="3"
                        placeholder="Ajouter un commentaire"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.commentaire}
                      ></textarea>
                      <ErrorMessage name="commentaire">
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="col-12 pt-5">
                      <a  href="/admin/liste-lieu-formation" className="btn btn-link resetBtn">
                        Annuler
                      </a>
                      <button
                        type="submit"
                        className="btn btn-primary float-end"
                        //disabled={isSubmitting}
                        //onClick={handleClick}
                      >
                        Valider
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        {/* lists */}
      </div>
    </div>
  );
}

export default LocationUpdate;
