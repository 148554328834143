import axios from "axios";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import dateFormat from "dateformat";
import SessionDatatable from "./SessionDatatable";
import { useHistory, useParams } from "react-router-dom";
import Hn from "../../components/common/Hn";
import "./showactionpres.css";
import moment from "moment";
import { Interweave } from "interweave";
function ShowAction() {
  const uri = process.env.REACT_APP_BASE_URL;
  let history = useHistory();
  const params = useParams();
  const [training, setTraining] = useState([]);
  const [session, setSession] = useState([]);
  const [planifer, setPlanifer] = useState([]);
  const [encours, setEncours] = useState([]);
  const [st, setSt] = useState([]);
  const [show, setShow] = useState(false);
  // const [contact, setContact] = useState([]);
  const [nbcontact, setNbontact] = useState([]);
  let headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  function getStatistics(sessions) {
    let p = 0;
    let c = 0;
    let nbContact = 0;
    let todayDate = moment().format("DD-MM-YYYY hh:mm:ss");

    sessions.forEach(function (d) {
      nbContact = nbContact + d.contactSessions?.length;
      if (new Date(todayDate) < new Date(d.dateStart)) {
        p = p + 1;
      } else {
        c = c + 1;
      }

    });
    setPlanifer(p);
    setEncours(c);
    setSt(parseInt(c) + parseInt(p));
    setNbontact(nbContact);
  }
//afficher les détails d'une formation présentielle récupérée par son id
  function getdetailsformation() {
    axios
      .get(uri + "/training/" + params.id)
      .then((result) => {

        setTraining(result?.data);
        setSession(result?.data?.sessions)
        getStatistics(result?.data?.sessions);
      })
      .catch((err) => {
        console.log("err training", err);
      });
  }

  //modifier une action de formation présentielle par son statut
  function updateStatus(id, checked) {
    let status = {
      status: checked
    }

    axios
      .put(`${uri}/training/edit/status/` + id, status, headers)
      .then((response) => {
        console.log(response?.data)
      })
      .catch((error) => {
        console.log(error);
      });

  }
  useEffect(() => {
    getdetailsformation();
  }, []);

  return (
    <div className="container-fluid pe-4">
      <div className="row  pe-2 ">
        <div className="container lists py-5">
          <nav aria-label="breadcrumb pb-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/formation-presentielle">Formations présentielles - Actions de formation</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {training?.title}
              </li>
            </ol>
            <a href="/admin/formation-presentielle" type="submit" className="btn btn-link resetBtn">
              Retour
            </a>
            <br></br>
            <div className="row">
              <div className="col-md-6">
                <Hn
                  title={training?.title}
                  level="h4"
                  className="h4-size pb-5"
                  plus={true}
                />
              </div>
              <div className="col-md-6">
                <a href={"/admin/update-formation-presentielle/" + training?.id} type="button" className="btn btn-primary float-end">
                  modifier
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-xs-12 ">
                <div className="cardSession">
                  <h5 className="card-title title16">Sessions planifiées</h5>
                  <p className="card-text title23">
                    {planifer}
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 ">
                <div className="cardSession">
                  <h5 className="card-title title16">Sessions en cours</h5>
                  <p className="card-text title23">
                    {encours}
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 ">
                <div className="cardSession">
                  <h5 className="card-title title16">Total des sessions</h5>
                  <p className="card-text title23">
                    {st}
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-xs-12 ">
                <div className="cardSession">
                  <h5 className="card-title title16">Participants inscrits</h5>
                  <p className="card-text title23">{nbcontact}</p>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="py-3">
          <div className=" p-5 text-white bg-dark rounded-3">
            <Hn
              title="Informations générales"
              level="h4"
              className="h4-size pb-5"
              plus={false}
            />

            <div className="form-elements">
              <form className="row g-3">
                <div className="col-md-4">
                  <label htmlFor="ref" className="form-label">
                    Référence
                  </label>
                  <p> {training?.ref}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="nom" className="form-label">
                    Intitulé*
                  </label>
                  <p>{training?.title}</p>
                </div>
                <div className="col-md-4"></div>

                <div className="col-md-4">
                  <label htmlFor="denomi" className="form-label">
                    Image
                  </label>
                  <p>{training?.image}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="adre" className="form-label">
                    Thème
                  </label>
                  <p>{training?.theme?.name}</p>
                </div>
                {training?.associationRoute?.id && (
                  <div className="col-md-4">
                    <label htmlFor="codepos" className="form-label">
                      Parcours associé
                    </label>
                    <p>{training?.associationRoute?.title}</p>
                  </div>
                )}

                <div className="col-md-12">
                  <label htmlFor="ville" className="form-label d-block">
                    Pré-requis et public visé
                  </label>
                  {training.audience ? <Interweave content={training.audience} /> : 'Aucun pre-requis'}
                </div>
                <div className="col-md-12">
                  <label htmlFor="pays" className="form-label d-block">
                    Objectifs
                  </label>
                  {training.objective ? <Interweave content={training.objective} /> : 'Aucun objectif'}
                </div>
                <div className="col-12">
                  <label htmlFor="comm" className="form-label d-block">
                    Programme de formation
                  </label>
                  {training.training_program ? <Interweave content={training.training_program} /> : 'Aucun programme'}
                </div>

                <div className="col-md-4">
                  <label htmlFor="interloc" className="form-label">
                    Durée thèorique
                  </label>
                  <p>{training?.duration}h</p>
                </div>


                <div className="col-md-4">
                  <label htmlFor="phone" className="form-switch form-label">
                    Actif
                  </label>
                  <p>

                    <Form className=" form-switch">
                      <Form.Check
                        type="switch"
                        id={params.id}
                        onClick={(e) => {
                          updateStatus(params.id, e.target.checked)
                        }}
                        defaultChecked={training?.status}
                        // checked={training?.status}
                      />
                      {/* <Form.Check
                        checked={training?.status}
                        type="switch"
                        id="custom-switch"

                      /> */}
                    </Form>

                  </p>
                </div>



                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Mise en avant dans le catalogue
                  </label>
                  <p>{training?.forward_catalog ? "Oui" : "Non"} </p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Spécifique au manager
                  </label>
                  <p>{training?.manager_spec ? "Oui" : "Non"}</p>
                </div>
                <div className="col-md-4">
                  <label htmlFor="email" className="form-label">
                    Participe à l'obligation de formation
                  </label>
                  <p>{training?.obligation ? "Oui" : "Non"}</p>
                </div>
                <Hn
                  title="Compétences associées"
                  level="h4"
                  className="h4-size pt-5"
                  plus={false}
                />
                <div className="col-md-12">
                  <label htmlFor="email" className="form-label">
                    {training?.skills?.title}
                  </label>
                </div>
                <div className="col-md-12">
                  {training?.skills?.subskills?.map((subskills, index) => {
                    return (
                      <p key={index}>{subskills?.name}</p>
                    )
                  }

                  )}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* lists */}

      <SessionDatatable
        setShow={setShow}
        show={show}
        sessions={session}
        actionName={training?.title}
        getSessions={getdetailsformation}
        setSessions={setSession}
      />
    </div>
  );
}

export default ShowAction;
